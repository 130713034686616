import React from 'react';
import {Spin} from "antd"
import {FormattedMessage} from "react-intl";
import {connect, useSelector} from "react-redux";

import StyledPhotoSectionCol from './styled/StyledPhotoSectionCol';
import StyledPhotoSection from './styled/StyledPhotoSection';
import StyledPhotoSectionImgContent from './styled/StyledPhotoSectionImgContent';
import {isFileUploadingSelector} from "services/filesUploader/filesUploader.selector";
import {selectFile} from "services/file/file.selector";
import PhotoUploader from "./PhotoUploader";
import UserIcon from '../../assets/img/emptyavatar.png';

const PhotoSection = ({name, formName, isEditable = true}) => {
  const isUploading = useSelector(state => isFileUploadingSelector(state, formName, name));
  const photo = useSelector(state => selectFile(state, name, formName));

  return (
    <Spin spinning={!!isUploading}>
      <StyledPhotoSection>
          <StyledPhotoSectionImgContent>
            <img src={photo?.[0]?.url || UserIcon} alt=""/>
          </StyledPhotoSectionImgContent>

        {isEditable &&
            <PhotoUploader formName={formName} name={name} description={"MarriageNOCDetails.PhotoInfo"}>
              <FormattedMessage id="MarriageNOCDetails.UploadPhoto"/>
            </PhotoUploader>
        }
      </StyledPhotoSection>
    </Spin>
  )
};


export default PhotoSection
