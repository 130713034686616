import {urlLocations} from "routes/urlLocations";

// export const API_PATH = process.env.BASE_URL || "http://54.228.176.110:1339";
export const API_PATH = process.env.REACT_APP_BASE_URL || "https://api2.uaecopts.com";
// export const API_PATH = "http://localhost:1338"

export const MODALS_ID = {
    LITURGY_DELETE_MODAL: "LITURGY_DELETE_MODAL",
    CONTRIBUTION_MODAL: "CONTRIBUTION_MODAL",
    OTHER_NOCS: "OTHER_NOCS",
    NOC_WARNING: "NOC_WARNING",
};

export const STORAGE_TYPE = {
    LOCAL_STORAGE: "localStorage",
    SESSION_STORAGE: "sessionStorage"
};

export const NAVIGATION_ITEMS = [
    {dataKey: "Members", to: urlLocations.membersTable, accessKey: 'members'},
    {dataKey: "Liturgies", to: urlLocations.liturgies, accessKey: 'liturgies'},
    {dataKey: "Meetings", to: urlLocations.services, accessKey: 'services', access: true},
    {dataKey: "Booking", to: ""},
    {dataKey: "Reports", to: ""}
];

export const DEACON_RADIO = [
    {value: true, label: "Member.Form.Deacon.Yes"},
    {value: false, label: "Member.Form.Deacon.No"}
];

export const IS_COPTIC_ORTHODOX_RADIO = [
    {value: true, label: "MarriageNOCDetails.IsCopticOrthodox.Yes"},
    {value: false, label: "MarriageNOCDetails.IsCopticOrthodox.No"}
];

export const PREVIOUS_ENGAGEMENT_RADIO = [
    {value: true, label: "MarriageNOCDetails.PreviousEngagement.Yes"},
    {value: false, label: "MarriageNOCDetails.PreviousEngagement.No"}
];

export const PREVIOUS_MARRIAGE_RADIO = [
    {value: true, label: "MarriageNOCDetails.PreviousMarriage.Yes"},
    {value: false, label: "MarriageNOCDetails.PreviousMarriage.No"}
];

export const ANOTHER_MARRIAGE_RADIO = [
    {value: true, label: "MarriageNOCDetails.AnotherMarriageBeforeWidowed.Yes"},
    {value: false, label: "MarriageNOCDetails.AnotherMarriageBeforeWidowed.No"}
];

export const IS_DIVORCED_RADIO = [
    {value: true, label: "MarriageNOCDetails.IsDivorced.Yes"},
    {value: false, label: "MarriageNOCDetails.IsDivorced.No"}
];


export const CURRENT_STATUS = [
    {value: "WIDOW", label: "MarriageNOCDetails.CurrentStatus.Widow"},
    {value: "DIVORCED", label: "MarriageNOCDetails.CurrentStatus.Divorced"},
];

export const PREV_MARRIAGE_LOCATION = [
    {value: "INSIDE_EGYPT", label: "MarriageNOCDetails.PrevMarriageLocation.Inside"},
    {value: "OUTSIDE_EGYPT", label: "MarriageNOCDetails.PrevMarriageLocation.Outside"},
];

export const FREQUENCY = [
    {value: "COMMITTED", label: "MarriageNOCDetails.Frequency.Committed"},
    {value: "NORMAL", label: "MarriageNOCDetails.Frequency.Normal"},
    {value: "UN_COMMITTED", label: "MarriageNOCDetails.Frequency.Un_committed"},
    {value: "UNKNOWN", label: "MarriageNOCDetails.Frequency.Unknown"},
];

export const MILITARY_STATUS = [
    {
        value: "NA",
        label: "MarriageNOCDetails.MilitaryStatus.NA",
    },
    {
        value: "NOT_NEEDED",
        label: "MarriageNOCDetails.MilitaryStatus.NotNeeded",
    },
    {
        value: "DONE",
        label: "MarriageNOCDetails.MilitaryStatus.Done",
    },
    {
        value: "TEMP_EXEMPTION",
        label: "MarriageNOCDetails.MilitaryStatus.TempExemption",
    },
];


export const FAMILY_STATUSES = [
    {value: "single", label: "SocialStatus.Single"},
    {value: "married", label: "SocialStatus.Married"},
    {value: "widow", label: "SocialStatus.Widow"},
    {value: "other", label: "SocialStatus.Other"}
];

export const NATIONALITIES = [
    {value: "other", label: "Nationality.Other"},
    {value: "egyptian", label: "Nationality.Egyptian"}
];

export const GENDER_OPTIONS = [
    {value: "male", label: "General.Male"},
    {value: "female", label: "General.Female"}
];

export const DEACON_SELECT = [
    {value: true, label: "Deacon.Yes"},
    {value: false, label: "Deacon.No"}
];

export const VISA_TYPES = [
    {
        value: "residence",
        label: "VisaType.Residence"
    },
    {
        value: "visit",
        label: "VisaType.Visit"
    },
    {
        value: "other",
        label: "VisaType.Other"
    }
];

export const RULE_TYPES = ['open', 'days', 'weeks', 'group'];

export const EVENT_TYPES = [
    {value: "liturgy", title: "Liturgy"},
    {value: "specialLiturgy", title: "SpecialLiturgy"},
    {value: "vespers", title: "Vespers"},
    {value: "youthMeeting", title: "Meeting"},
    {value: "feastLiturgy", title: "FeastLiturgy"},
    {value: "baskha", title: "Baskha"},
    {value: "sundaySchools", title: "SundaySchools"}
];

export const GOOGLE_API_KEY = "AIzaSyCO - YjS0S2oU5kLousmy4306yNhrDGeEW8";

export const COLUMN_ORDER = {
    ascend: "ASC",
    descend: "DESC"
};

export const FORMS_NAMES = {
    MemberPersonalForm: "MemberPersonalForm",
    MemberAdditionalForm: "MemberAdditionalForm",
    MemberContactForm: "MemberContactForm",
    MemberAddressForm: "MemberAddressForm",
    MARRIAGE_NOC: "marriageNOC",
}

export const FILES_TYPE = {
    MARRIAGE_NOC_DOCUMENTS: "documents",
    MARRIAGE_NOC_HARD_COPY: "hardCopy",
    MARRIAGE_NOC_CANCELLATION_PROOF: "cancellationProof",
    MARRIAGE_NOC_PHOTO: "photo",
}

export const LOADERS = {
    MARRIAGE_NOC: "marriageNOC",
    MARRIAGE_NOC_STATUS: "marriageNOCStatus",
}
