import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Spin} from 'antd';

import ContributionsForm from "containers/Contributions/Contributions.Modal/Contributions.Form";
import {getContributionSelector} from "services/contributions/contributions.selectors";
import {addFilesAction} from "services/file/file.action";
import {getLoaderByName} from "services/loader/loader.selector";
import Styled from './styled';

const ContributionsContent = ({contributionId}) => {
    const contribution = useSelector(state => getContributionSelector(state, contributionId)) || {};
    const isLoading = useSelector(state => getLoaderByName(state, 'contributions'));

    const dispatch = useDispatch();

    useEffect(() => {
        if (contribution && contributionId) {
            dispatch(addFilesAction({
                files: contribution?.attachments || [],
                formName: 'contributions',
                name: 'attachments'
            }))
        }
    }, [contributionId]);

    return (
        <Spin spinning={!!isLoading}>
            <Styled.ModalContent>
                <Styled.ModalTitle>
                    <FormattedMessage id={"Contributions.ModalTitle"}/>
                </Styled.ModalTitle>
                <ContributionsForm
                    contributionId={contributionId}
                    initialValues={{
                        ...(contributionId ? {
                            ...contribution,
                            father: contribution?.father?.id,
                            church: contribution?.church?.id,
                        } : {})
                    }}/>
            </Styled.ModalContent>
        </Spin>
    );
};

export default ContributionsContent;