import React, {useEffect} from 'react';
import {history, urlLocations} from "routes/urlLocations";
import Header from './Header/Header';

const BasicLayout = ({ children }) => {
    useEffect(() => {
        const storage = localStorage?.getItem("storageTyp");
        const jwt = window[storage]?.getItem('jwt');

        !jwt && history.push(urlLocations.login)
    }, []);
    return (
        <>
            <Header />
            <div>{children}</div>
        </>
    )
};


export default BasicLayout;