import {all} from "redux-saga/effects";

import loginSaga from "./login/login.saga";
import logoutSaga from "./logout/logout.saga";
import errorsSaga from "./errors/errors.saga";
import fileUploadSaga from "./file/file.saga";
import resendEmailSaga from "./resendEmail/resendEmail.saga";
import passwordSaga from "./password/password.saga";
import appInfoSaga from "./appInfo/appInfo.saga";
import membersSaga from "./members/members.saga";
import familySaga from "./family/family.saga";
import areaSaga from "./area/area.saga";
import churchesSaga from "./churches/churches.saga";
import liturgiesSaga from "./liturgy/liturgy.saga";
import filtersSaga from "./filters/filters.saga";
import profileSaga from "./profile/profile.saga";
import marriageNocSaga from './marriageNoc/marriageNoc.saga';
import contributionsSaga from './contributions/сontributions.saga';

export default function* rootSaga() {
    yield all([
        passwordSaga(),
        churchesSaga(),
        resendEmailSaga(),
        profileSaga(),
        loginSaga(),
        logoutSaga(),
        errorsSaga(),
        fileUploadSaga(),
        appInfoSaga(),
        membersSaga(),
        familySaga(),
        areaSaga(),
        liturgiesSaga(),
        filtersSaga(),
        marriageNocSaga(),
        contributionsSaga()
    ]);
}
