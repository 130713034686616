import React from 'react';

import MarriageNocDetailsSection from './MarriageNOCDetails.Section';
import Styled from "containers/MarriageNOCDetails/styled";
import InputField from "components/InputField/InputField";

const MarriageNocDetailsWitnesses = ({disabled} = {disabled: false}) => {
    return (
        <MarriageNocDetailsSection title={"MarriageNOCDetails.Witnesses"}>
            <Styled.FieldsWrap>
                <Styled.Field>
                    <InputField
                        label={"MarriageNOCDetails.FirstWitnessName"}
                        placeholder={"MarriageNOCDetails.FirstWitnessName"}
                        name={"firstWitnessName"}
                        disabled={disabled}
                    />
                </Styled.Field>
                <Styled.Field>
                    <InputField
                        type={'number'}
                        label={"MarriageNOCDetails.FirstWitnessNationalID"}
                        placeholder={"MarriageNOCDetails.FirstWitnessNationalID"}
                        name={"firstWitnessNationalID"}
                        disabled={disabled}
                    />
                </Styled.Field>
                <Styled.Field/>
                <Styled.Field>
                    <InputField
                        label={"MarriageNOCDetails.SecondWitnessName"}
                        placeholder={"MarriageNOCDetails.SecondWitnessName"}
                        name={"secondWitnessName"}
                        disabled={disabled}
                    />
                </Styled.Field>
                <Styled.Field>
                    <InputField
                        type={'number'}
                        label={"MarriageNOCDetails.SecondWitnessNationalID"}
                        placeholder={"MarriageNOCDetails.SecondWitnessNationalID"}
                        name={"secondWitnessNationalID"}
                        disabled={disabled}
                    />
                </Styled.Field>
            </Styled.FieldsWrap>
        </MarriageNocDetailsSection>
    );
};

export default MarriageNocDetailsWitnesses;
