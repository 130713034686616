import instance from "../root.api";

export const getLiturgiesRequest = params =>
  instance
    .get("/apis-admin/events?_sort=id:DESC", { params })
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const getLiturgiesCountRequest = params =>
    instance
        .get("/apis-admin/events/count", { params })
        .then(response => ({ response }))
        .catch(response => ({ ...response }));

export const getLiturgyRequest = id =>
  instance
    .get(`/apis-admin/events/${id}`)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const getLiturgyBookingsRequest = id =>
  instance
    .get(`/apis-admin/events/${id}/bookings`)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const createLiturgiesRequest = data =>
  instance
    .post("/apis-admin/events", data)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const updateLiturgiesRequest = ({ id, ...data }) =>
  instance
    .put(`/apis-admin/events/${id}`, data)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));

export const deleteLiturgiesRequest = id =>
  instance
    .delete(`/apis-admin/events/${id}`)
    .then(response => ({ response }))
    .catch(response => ({ ...response }));
