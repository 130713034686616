import React from 'react';

import StyledContainer from "components/styled/StyledContainer";
import Styled from './styled';
import {SERVICES} from './Services.constants';

import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {profileSelector} from "services/profile/profile.selectors";

const Services = () => {
    const profile = useSelector(profileSelector);

    return (
        <Styled.Page>
            <StyledContainer>
                <Styled.Title>
                    <FormattedMessage id="Services.Title"/>
                </Styled.Title>
                <Styled.List>
                    {
                        SERVICES.map(service => <Styled.Card
                            disabled={!profile?.access?.[service.dataKey]}
                            to={service.path}>
                            <Styled.CardImg>
                                <img src={service.img} alt=""/>
                            </Styled.CardImg>
                            <Styled.CardTitle>
                                <FormattedMessage id={service.key}/>
                            </Styled.CardTitle>
                        </Styled.Card>)
                    }
                </Styled.List>
            </StyledContainer>
        </Styled.Page>
    );
};

export default Services;