import React from 'react';
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { urlLocations } from "routes/urlLocations";
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import isEmpty from "lodash/isEmpty";

import { familySelector } from "services/family/family.selector";
import { prepareFamily } from 'services/family/family.utils'
import Styled from "containers/Member/Member.Forms/styled/StyledMember.Form";
import Person from './styled/StyledMember.Family';

const MemberFamily = ({ memberId }) => {
    const family = useSelector(familySelector);
    const preparedMembers = prepareFamily(family).filter(member => member?.id !== parseInt(memberId));
    const members = preparedMembers.length > 5 ? preparedMembers.slice(0, 4): preparedMembers;
    return (
        <Styled.ColLeft>
        { !isEmpty(family) &&
        <Styled.FormBlock>
                <Styled.FormHeader>
                    <Styled.HeaderTitle>
                        <FormattedMessage id="Member.FamilyInfo" />
                    </Styled.HeaderTitle>
                </Styled.FormHeader>

                <Person.PersonPreview>
                    <Person.PersonList>
                        { members?.map(member => (
                            <Person.PersonItem to={`${urlLocations.member}/${member.id}/${member.familyID}`}>
                                <Person.UserPic>
                                    { member?.profilePic?.url ?
                                        <img width={50} src={member?.profilePic?.url} alt=""/> :
                                        <Avatar shape="square" size={64} icon={<UserOutlined />} />
                                    }
                                </Person.UserPic>
                                <Person.Name>{ member?.firstName }</Person.Name>
                                <Person.Status> <FormattedMessage id={member?.role} /></Person.Status>
                            </Person.PersonItem>
                        )) }
                        {  preparedMembers.length > 5 &&
                            <Person.PersonItem>
                                <Person.MoreBtn>
                                    <Person.MoreTitle>
                                        + { preparedMembers.length - 4 }
                                    </Person.MoreTitle>
                                </Person.MoreBtn>
                                <Person.StatusMore> <FormattedMessage id="Family.MembersCount" values={{ count: preparedMembers.length - 4 }} /></Person.StatusMore>
                            </Person.PersonItem>
                        }
                    </Person.PersonList>

                    <Person.ButtonRow>
                        <Person.ExploreBtn to={`${urlLocations.family}/${family.id}`} >
                            <FormattedMessage id="Family.ExploreFamily" />
                        </Person.ExploreBtn>
                    </Person.ButtonRow>
                </Person.PersonPreview>
            </Styled.FormBlock> }
        </Styled.ColLeft>
    );
};

export default MemberFamily;