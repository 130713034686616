import { put, takeEvery, takeLatest, select, call } from "redux-saga/effects";
import { createMatchSelector, LOCATION_CHANGE } from "connected-react-router";
import pickBy from "lodash/pickBy";
import get from "lodash/get";

import {
  GET_MEMBERS_FAILURE,
  GET_MEMBERS,
  GET_MEMBERS_COUNT,
  GET_MEMBERS_COUNT_FAILURE,
  GET_MEMBERS_COUNT_SUCCESS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBER,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  DELETE_MEMBER
} from "./members.action";
import { urlLocations, history } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";
import {
  getMembersRequest,
  getMembersCountRequest,
  getMemberRequest,
  updateMemberRequest,
  deleteMemberRequest
} from "./members.api";
import { SET_FILTERS } from "services/filters/filters.action";
import {
  FINISH_LOADER,
  SET_LOADER_STATUS,
  START_LOADER
} from "services/loader/loader.action";

function* getMembersOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.members)) {
      yield put({ type: GET_MEMBERS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getMemberOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.memberInfo)) {
      const getMatch = createMatchSelector(urlLocations.memberInfo);
      const state = yield select(state => state);
      const id = get(getMatch(state), "params.id");
      yield !!id && put({ type: GET_MEMBER, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchMembers({ payload }) {
  try {
    const params = payload
      ? payload
      : yield select(state => state.filters?.members);
    const filteredParams = params;

    yield put({
      type: START_LOADER
    });
    const { data, status } = yield getMembersRequest({
      ...filteredParams,
      ...(!filteredParams?._limit && { _limit: 50 }),
      ...(!filteredParams?._start && { _start: 0 })
    });
    if (status === 200) {
      yield put({
        type: SET_FILTERS,
        payload: {
          filters: {
            ...filteredParams,
            ...(!filteredParams?._limit && { _limit: 50 }),
            ...(!filteredParams?._start && { _start: 0 })
          },
          name: "members"
        }
      });
      yield put({
        type: GET_MEMBERS_COUNT,
        payload: filteredParams
      });

      yield put({
        type: GET_MEMBERS_SUCCESS,
        payload: { ...data }
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERS_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: FINISH_LOADER
    });
  }
}

function* fetchMember({ payload: id }) {
  try {
    yield put({
      type: START_LOADER
    });
    const { data, status } = yield getMemberRequest(id);
    if (status === 200) {
      yield put({
        type: GET_MEMBER_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBER_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: FINISH_LOADER
    });
  }
}

function* getMemberCount({ payload }) {
  try {
    yield put({
      type: START_LOADER
    });
    const params = payload
      ? payload
      : yield select(state => state.filters?.members);
    const { _limit, _start, ...filteredParams } = pickBy(params);
    const { status, data } = yield getMembersCountRequest({
      ...filteredParams
    });
    if (status === 200) {
      yield put({
        type: GET_MEMBERS_COUNT_SUCCESS,
        payload: data
      });
    } else {
      yield put({ type: GET_MEMBERS_COUNT_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERS_COUNT_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: FINISH_LOADER
    });
  }
}

function* deleteMember({ payload: id }) {
  const { status } = yield deleteMemberRequest({ id });
  try {
    if (status === 200) {
      yield put({
        type: DELETE_MEMBER_SUCCESS
      });
      yield put({ type: GET_MEMBERS });
    } else {
      yield put({
        type: DELETE_MEMBER_FAILURE
      });
    }
  } catch ({ response }) {
    yield put({
      type: DELETE_MEMBER_FAILURE
    });
    console.log(response);
  }
}

function* updateMember({ payload }) {
  try {
    const { formName, ...res } = payload;
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: formName,
        isLoading: true
      }
    });
    const { status, data } = yield updateMemberRequest(res);

    if (status === 200) {
      yield put({
        type: UPDATE_MEMBER_SUCCESS
      });
      yield put({
        type: GET_MEMBER_SUCCESS,
        payload: data
      });
    } else {
      yield put({ type: UPDATE_MEMBER_FAILURE });
    }
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: formName,
        isLoading: false
      }
    });
  } catch ({ response }) {
    yield put({ type: UPDATE_MEMBER_FAILURE });
    console.log(response);
  } finally {
  }
}

export default function* membersSaga() {
  yield takeEvery(LOCATION_CHANGE, getMembersOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getMemberOnLocationChange);
  yield takeEvery(GET_MEMBERS, fetchMembers);
  yield takeEvery(GET_MEMBER, fetchMember);
  yield takeEvery(GET_MEMBERS_COUNT, getMemberCount);
  yield takeEvery(UPDATE_MEMBER, updateMember);
  yield takeEvery(DELETE_MEMBER, deleteMember);
}
