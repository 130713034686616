import React from 'react';
import { Select } from 'antd';
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import InputField from "components/InputField/InputField";
import SelectField from "components/SelectField/SelectField";
import DatePickerField from "components/DatePickerField/DatePickerField";
import { GENDER_OPTIONS, FAMILY_STATUSES, NATIONALITIES, DEACON_SELECT, VISA_TYPES } from 'constants/constants';
import { appInfoSelector } from "services/appInfo/appInfo.selector";
import { getLocalizedKey } from "utils/utils";
import Styled from './styled/StyledMember.Filters';

const { Option } = Select;

const renderValueField = ({ name, filterName, appInfo }) => {
    const { fathers, workingFields } = appInfo;
    return filterName &&  {
        firstName: <InputField size="small" name={name} />,
        secondName: <InputField size="small" name={name} />,
        lastName: <InputField size="small" name={name} />,
        gender: <SelectField size="small" name={name} >{
            GENDER_OPTIONS.map(gender => (
                <Option value={gender.value} key={gender.value}>
                    <FormattedMessage id={gender.label} />
                </Option>
            ))
        }</SelectField>,
        email: <InputField size="small" name={name} />,
        mobile: <InputField size="small" name={name} />,
        birthday: <DatePickerField size="small" name={name} />,
        age: <InputField size="small" name={name} />,
        status: <SelectField size="small" name={name} >{
            FAMILY_STATUSES.map(status => (
                <Option value={status.value} key={status.value}>
                    <FormattedMessage id={status.label} />
                </Option>
            ))
        }</SelectField>,
        nationality: <SelectField size="small" name={name} >{
            NATIONALITIES.map(nationality => (
                <Option value={nationality.value} key={nationality.value}>
                    <FormattedMessage id={nationality.label} />
                </Option>
            ))
        }</SelectField>,
        fatherOfConfession: <SelectField size="small" name={name} >{
            fathers.map(father => (
                <Option value={father.id} key={father.id}>
                    { father[getLocalizedKey('name')] }
                </Option>
            ))
        }</SelectField>,
        workingField: <SelectField size="small" name={name} >{
            workingFields.map(workingField => (
                <Option value={workingField.id} key={workingField.id}>
                    { workingField[getLocalizedKey('name')] }
                </Option>
            ))
        }</SelectField>,
        area: <SelectField size="small" name={name} >{
            [].map(area => (
                <Option value={area.id} key={area.id}>
                    { area[getLocalizedKey('name')] }
                </Option>
            ))
        }</SelectField>,
        deacon: <SelectField size="small" name={name} >{
            DEACON_SELECT.map(deacon => (
                <Option value={deacon.value} key={deacon.value}>
                    <FormattedMessage id={deacon.label} />
                </Option>
            ))
        }</SelectField>,
        visaType: <SelectField size="small" name={name} >{
            VISA_TYPES.map(visa => (
                <Option value={visa.value} key={visa.value}>
                    <FormattedMessage id={visa.label} />
                </Option>
            ))
        }</SelectField>,
    }[filterName];
};

const MembersFiltersValueField = ({ name, filterName }) => {
    const appInfo = useSelector(appInfoSelector);
    return (
        <Styled.FilterCol>
            { renderValueField({ name, filterName, appInfo }) }
        </Styled.FilterCol>
    );
};

export default MembersFiltersValueField;