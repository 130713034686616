import { GET_FAMILY_SUCCESS } from "./family.action";
import initialState from "store/initialState";

export default (state = initialState.family, action) => {
  switch (action.type) {
    case GET_FAMILY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
