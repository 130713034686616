import React, {useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {SearchOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import debounce from 'lodash/debounce';

import Styled from './styled';
import StyledContainer from "components/styled/StyledContainer";
import Input from "components/Input/Input";
import {searchContributionsAction} from "services/contributions/contributions.actions";
import ContributionsTable from "containers/Contributions/Contributions.Table";
import ContributionsModal from "containers/Contributions/Contributions.Modal/Contributions.Modal";
import Button from "components/Button/Button";
import {MODALS_ID} from "constants/constants";
import {setModalStatusAction} from "services/modals/modals.action";

const Contributions = () => {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const [contributionId, setContributionID] = useState(null);
    const debouncedSearch = debounce(value => dispatch(searchContributionsAction(value)), 500);

    const setContributionIDCallback = id => {
        setContributionID(id);
    }

    return (
        <Styled.Page>
            <StyledContainer>
                <Styled.Title>
                    <FormattedMessage id="Contributions.Title"/>
                </Styled.Title>
                <Styled.SearchField>
                    <Input
                        onChange={({target}) => debouncedSearch(target?.value)}
                        prefix={
                            <SearchOutlined style={{fontSize: 16}}/>
                        }
                        placeholder={formatMessage({id: "Contributions.SearchPlaceholder"})}
                    />
                </Styled.SearchField>
                <Styled.ActionRow>
                    <Styled.Subtitle>
                        <FormattedMessage id={"Contributions.Subtitle"}/>
                    </Styled.Subtitle>
                    <Button
                        onClick={() => {
                            dispatch(setModalStatusAction(MODALS_ID.CONTRIBUTION_MODAL));
                            setContributionID(null)
                        }}
                        uitype={'secondary'}
                    >
                        <FormattedMessage id={"Contributions.AddNew"}/>
                    </Button>
                </Styled.ActionRow>
                <ContributionsTable
                    setContributionIDCallback={setContributionIDCallback}
                />
                <ContributionsModal contributionId={contributionId}/>
            </StyledContainer>
        </Styled.Page>
    );
};

export default Contributions;
