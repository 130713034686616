import React from 'react';
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {urlLocations} from "routes/urlLocations";
import {Select} from 'antd';
import moment from 'moment';

import InputField from "components/InputField/InputField";
import DatePickerField from "components/DatePickerField/DatePickerField";
import SelectField from "components/SelectField/SelectField";
import {createLiturgyAction, updateLiturgyAction} from "services/liturgy/liturgy.action";
import time from 'constants/time';
import {RULE_TYPES, EVENT_TYPES} from 'constants/constants';
import Styled from './styled/StyledLiturgyForm';
import StyledCancelLink from "components/Button/styled/StyledCancelLink";
import StyledButtonSubmit from "components/Button/styled/StyledButtonSubmit";
import CheckboxField from "components/CheckboxField/CheckboxField";

const LiturgyForm = ({handleSubmit, id, isCopy}) => {
  const {Option} = Select;

  const isNew = !id || isCopy
  return (

    <form onSubmit={handleSubmit}>
      <Styled.LiturgyForm>
        <Styled.FieldsWrap>
          <Styled.Field50>
            <InputField
              name='title'
              placeholder="Liturgies.Form.Title"
              label="Liturgies.Form.Title"
            />
          </Styled.Field50>
          <Styled.Field50>
            <InputField
              name='titleAr'
              placeholder="Liturgies.Form.TitleAr"
              label="Liturgies.Form.TitleAr"
            />
          </Styled.Field50>
          <Styled.Field25>
            <DatePickerField
              name="date"
              placeholder="Liturgies.Form.LiturgyDate"
              label="Liturgies.Form.LiturgyDate"
            />
          </Styled.Field25>
          <Styled.Field25>
            <SelectField
              name="time"
              placeholder="Liturgies.Form.Time"
              label="Liturgies.Form.Time"
              showSearch
            >
              {
                time.map(item => (
                  <Option
                    value={item}
                    key={item}
                    children={item}
                  />
                ))
              }
            </SelectField>
          </Styled.Field25>
          <Styled.Field25>
            <DatePickerField
              name="publishDate"
              placeholder="Liturgies.Form.PublishDate"
              label="Liturgies.Form.PublishDate"/>
          </Styled.Field25>
          <Styled.Field25>
            <SelectField
              name="publishTime"
              placeholder="Liturgies.Form.Time"
              label="Liturgies.Form.Time"
            >
              {
                time.map(item => (
                  <Option
                    value={item}
                    key={item}
                    children={item}
                  />
                ))
              }
            </SelectField>
          </Styled.Field25>
          <Styled.Field25>
            <InputField
              type="number"
              name="maxCapacity"
              placeholder="Liturgies.Form.MaxCapacity"
              label="Liturgies.Form.MaxCapacity"
            />
          </Styled.Field25>
          <Styled.Field25>
            <InputField
              type="number"
              name="bookedCount"
              placeholder="Liturgies.Form.BookedCapacity"
              label="Liturgies.Form.BookedCapacity"
            />
          </Styled.Field25>
          <Styled.Field50>
            <SelectField
              name='eventType'
              placeholder="Liturgies.Form.PrayerType"
              label="Liturgies.Form.PrayerType"
            >
              {
                EVENT_TYPES.map(eventType => (
                  <Option
                    value={eventType.value}
                    key={eventType.value}
                    children={
                      <FormattedMessage
                        id={`Liturgy.EventType.${eventType.title}`}
                      />
                    }
                  />
                ))
              }
            </SelectField>
          </Styled.Field50>
          <Styled.Field25>
            <SelectField
              name="ruleType"
              placeholder="Liturgies.Form.RuleType"
              label="Liturgies.Form.RuleType"
            >
              {RULE_TYPES.map(ruleType => (
                <Option
                  value={ruleType}
                  children={ruleType}
                  key={ruleType}
                />
              ))}
            </SelectField>
          </Styled.Field25>
          <Styled.Field25>
            <InputField
              name="ruleWindow"
              placeholder="Liturgies.Form.RuleWindow"
              label="Liturgies.Form.RuleWindow"
              type="number"
            />
          </Styled.Field25>
          <Styled.Field25>
            <InputField
              type="number"
              name='minAllowedAge'
              placeholder="Liturgies.Form.MinAllowedAge"
              label="Liturgies.Form.MinAllowedAge"
            />
          </Styled.Field25>
          <Styled.Field25>
            <InputField
              type="number"
              name='maxAllowedAge'
              placeholder="Liturgies.Form.MaxAllowedAge"
              label="Liturgies.Form.MaxAllowedAge"
            />
          </Styled.Field25>
          <Styled.Field50>
            <InputField
              name='ruleKey'
              placeholder="Liturgies.Form.RuleKey"
              label="Liturgies.Form.RuleKey"
            />
          </Styled.Field50>
          <Styled.Field50>
            <div className="flex justify-between items-end h-full px-8">
              <CheckboxField name="exclusive">
                <FormattedMessage id="Liturgies.Form.Exclusive"/>
              </CheckboxField>
              <CheckboxField name="isPrivate">
                <FormattedMessage id="Liturgies.Form.IsPrivate"/>
              </CheckboxField>
            </div>
          </Styled.Field50>
        </Styled.FieldsWrap>
        <Styled.TopMenu>
          <StyledButtonSubmit htmlType="submit" marginRight="32px" width="126px">
            <FormattedMessage id={isNew ? "Liturgies.Form.SaveLiturgy" : "Liturgies.Form.UpdateLiturgy"}/>
          </StyledButtonSubmit>

          <StyledCancelLink width="126px" to={urlLocations.liturgies}>
            <FormattedMessage id="Liturgies.Form.Cancel"/>
          </StyledCancelLink>
        </Styled.TopMenu>
      </Styled.LiturgyForm>
    </form>
  );
};

export default reduxForm({
  form: 'liturgyForm',
  enableReinitialize: true,
  onSubmit: (v, dispatch, {id, isCopy}) => {
    const {date, time, publishDate, publishTime, ...values} = v;

    const isoDate = date && time &&
      moment(`${date} ${time}`, "YYYY-MM-DD hh:mm a").format();
    const isoPublishDate = publishDate && publishTime &&
      moment(`${publishDate} ${publishTime}`, "YYYY-MM-DD hh:mm a").format();

    !!id && !isCopy ?
      dispatch(updateLiturgyAction({
        date: isoDate,
        publishDate: isoPublishDate,
        ...values
      })) :
      dispatch(createLiturgyAction({
        date: isoDate,
        publishDate: isoPublishDate,
        ...values
      }))
  }
})(LiturgyForm);
