import React, { useEffect } from 'react';
import { FormattedMessage } from "react-intl";

import { history, urlLocations } from "routes/urlLocations";
import Styled from './styled/StyledAutorizeLayout.js';

export default ({ children }) => {
    useEffect(() => {
        const storage = localStorage.getItem("storageTyp");
        const jwt = window[storage]?.getItem('jwt');
        jwt && history.push(urlLocations.dashboard)
    }, []);

    return (
        <Styled.Layout>
            <Styled.LeftBlock>
                <Styled.Img></Styled.Img>
                <Styled.Title>
                    <FormattedMessage id="MainPage.Title" />
                </Styled.Title>
                <Styled.IntoBlock>
                    <Styled.IntoTxt>
                        <FormattedMessage id="MainPage.Description" />
                    </Styled.IntoTxt>
                </Styled.IntoBlock>

            </Styled.LeftBlock>

            <Styled.RightBlock>
                { children }
            </Styled.RightBlock>

        </Styled.Layout>
    )
};

