import {isUrlMatch} from "services/router/router.utils";
import {urlLocations} from "routes/urlLocations";
import {call, put, select, takeEvery} from "redux-saga/effects";
import * as contributionsActions from "./contributions.actions";
import {LOCATION_CHANGE} from "connected-react-router";

import {
    createContributionRequest,
    getContributionsRequest,
    searchContributionsRequest,
    updateContributionRequest
} from "services/contributions/contributions.api";
import {SET_LOADER_STATUS} from "services/loader/loader.action";
import {SET_MODAL_STATUS} from "services/modals/modals.action";
import openNotification from "utils/notifications";
import {CLEAR_FILES} from "services/file/file.action";
import {GET_CONTRIBUTIONS} from "./contributions.actions";
import {selectFile} from "services/file/file.selector";


function* getContributionsOnLocationChange({payload}) {
    try {
        if (isUrlMatch(payload, urlLocations.contributions)) {
            yield put({
                type: contributionsActions.GET_CONTRIBUTIONS
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchContributions() {
    try {
        const {response} = yield call(getContributionsRequest);

        yield put({
            type: contributionsActions.GET_CONTRIBUTIONS_SUCCESS,
            payload: response.data
        });


    } catch (error) {
        yield put({type: contributionsActions.GET_CONTRIBUTIONS_FAILURE});
        console.log(error);
    }
}

function* searchContributions({payload}) {
    try {
        const {response} = yield call(searchContributionsRequest, payload);

        yield put({
            type: contributionsActions.SEARCH_CONTRIBUTIONS_SUCCESS,
            payload: response.data
        });

    } catch (error) {
        yield put({
            type: contributionsActions.SEARCH_CONTRIBUTIONS_FAILURE
        });
        console.log(error);
    }
}

function* updateContributions({payload}) {
    try {

        yield put({
            type: SET_LOADER_STATUS,
            payload: {
                name: 'contributions',
                isLoading: true
            }
        });

        const attachments = yield select(state => selectFile(state, 'attachments', 'contributions'))
        const {response} = yield call(updateContributionRequest, {
            ...payload,
            attachments
        });


        yield call(openNotification, {
            type: "success",
            message: "Contributions.Success",
            description: "Contributions.SuccessCreateMessage"
        });

        yield put({
            type: contributionsActions.EDIT_CONTRIBUTION_SUCCESS,
            payload: {
                ...response.data,
                attachments
            }
        });

        yield put({
            type: SET_MODAL_STATUS,
            payload: null
        })

        yield put({
            type: CLEAR_FILES,
            payload: {
                formName: 'contributions'
            }
        });

        yield put({
            type: GET_CONTRIBUTIONS
        });

    } catch (error) {
        yield put({
            type: contributionsActions.EDIT_CONTRIBUTION_FAILURE
        });
        console.log(error);
    } finally {
        yield put({
            type: SET_LOADER_STATUS,
            payload: {
                name: 'contributions',
                isLoading: false
            }
        });
    }
}

function* createContributions({payload}) {
    try {
        const attachments = yield select(state => selectFile(state, 'attachments', 'contributions'))

        const {response} = yield call(createContributionRequest, {
            ...payload,
            attachments
        });

        yield put({
            type: SET_LOADER_STATUS,
            payload: {
                name: 'contributions',
                isLoading: true
            }
        });

        yield call(openNotification, {
            type: "success",
            message: "Success",
            description: "Success"
        });

        yield put({
            type: contributionsActions.CREATE_CONTRIBUTION_SUCCESS,
            payload: response.data
        });

        yield put({
            type: SET_MODAL_STATUS,
            payload: null
        })

        yield put({
            type: CLEAR_FILES,
            payload: {
                formName: 'contributions'
            }
        });

        yield put({
            type: GET_CONTRIBUTIONS
        });

    } catch (error) {
        yield put({
            type: contributionsActions.CREATE_CONTRIBUTION_FAILURE
        });
        console.log(error);
    } finally {
        yield put({
            type: SET_LOADER_STATUS,
            payload: {
                name: 'contributions',
                isLoading: false
            }
        });
    }
};

export default function* contributionsSaga() {
    yield takeEvery(LOCATION_CHANGE, getContributionsOnLocationChange);
    yield takeEvery(contributionsActions.GET_CONTRIBUTIONS, fetchContributions);
    yield takeEvery(contributionsActions.EDIT_CONTRIBUTION, updateContributions);
    yield takeEvery(contributionsActions.CREATE_CONTRIBUTION, createContributions);
    yield takeEvery(contributionsActions.SEARCH_CONTRIBUTIONS, searchContributions);

}
