export const FILTERS = {
  firstName: {
    type: "String",
    name: "Filters.FirstName"
  },
  secondName: {
    type: "String",
    name: "Filters.SecondName"
  },
  lastName: {
    type: "String",
    name: "Filters.LastName"
  },
  gender: {
    type: "Selector",
    name: "Filters.Gender"
  },
  email: {
    type: "String",
    name: "Filters.Email"
  },
  mobile: {
    type: "String",
    name: "Filters.Mobile"
  },
  // birthday: {
  //   type: "Date",
  //   name: "Filters.Birthday"
  // },
  // age: {
  //   type: "Number",
  //   name: "Filters.Age"
  // },
  status: {
    type: "Selector",
    name: "Filters.Status"
  },
  nationality: {
    type: "Selector",
    name: "Filters.Nationality"
  },
  fatherOfConfession: {
    type: "Selector",
    name: "Filters.FatherOfConfession"
  },
  workingField: {
    type: "Selector",
    name: "Filters.WorkingField"
  },
  // area: {
  //   type: "Selector",
  //   name: "Filters.Area"
  // },
  deacon: {
    type: "Boolean",
    name: "Filters.Deacon"
  },
  visaType: {
    type: "Selector",
    name: "Filters.VisaType"
  }
};
export const FILTERS_TYPES = {
  String: [
    {
      name: "Operators.Is",
      value: "="
    },
    {
      name: "Operators.Contains",
      value: "_contains"
    }
  ],
  Number: [
    {
      name: "Operators.Is",
      value: "="
    },
    {
      name: "Operators.LTE",
      value: "_lte"
    },
    {
      name: "Operators.GTE",
      value: "_gte"
    }
  ],
  Date: [{ name: "is", value: "=" }],
  Selector: [{ name: "is", value: "=" }],
  Boolean: [{ name: "is", value: "=" }]
};
export const operatorsToText = {
  "=": "Operators.Is",
  _gte: "Operators.GTE",
  _lte: "Operators.LTE",
  _contains: "Operators.Contains"
};
