import styled from "styled-components";
import { Select } from "antd";
import Arrow from "assets/img/Icon/ArrowSelect.svg";

const StyledSelect = styled(Select)`
  width: 100%;
  background-color: #ffffff;
  height: 40px;
  border-radius: 8px;
  border: none;
  font-family: var(--fontProximaSemi);

  &.ant-select-selector .ant-select-selection-search-input {
    height: 40px;
    font-family: var(--fontProximaSemi);
  }
  &.ant-select-disabled {
    background-color: var(--disableBg);
  }

  &.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
    border-radius: 8px;
    background: transparent;
  }
  &.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    color: var(--dark);
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 15px;
    height: 40px;
    border-radius: 8px;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border-radius: 8px;
    background: transparent;
  }
  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 40px;
    color: var(--dark);
    border: 1px solid #d6e1e9;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d6e1e9;
  }
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #d6e1e9;
  }
  &.ant-select-selector {
    height: 40px;
    border-radius: 8px;
    font-family: var(--fontProximaSemi);
    //border: solid 1px #D6E1E9;
  }
  &:hover {
    .ant-select-selector {
      border: solid 1px #d6e1e9;
    }
  }
  .ant-select-selector {
    border: solid 1px #d6e1e9;
    border-radius: 8px;
    height: 40px;
    color: var(--dark);
    padding-left: 16px;
    font-size: 13px;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-search-input {
    line-height: 40px;
    font-family: var(--fontProximaSemi);
  }

  .ant-select {
    color: var(--dark);
    font-size: 13px;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #d6e1e9;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
    padding: 0 15px;
    font-family: var(--fontProximaSemi);
  }
  &.ant-select-focused {
    .ant-select-arrow {
      color: var(--lightGrey);
    }
  }

  .ant-select-arrow .anticon {
    display: none;
  }

  .ant-select-arrow {
    top: 22px;
    right: 10px;
    width: 14px;
    height: 8px;
    background: url(${Arrow}) center center no-repeat;
    background-size: contain;
    transition: all 0.1s ease-in;
  }
  &.ant-select-open .ant-select-arrow {
    transform: rotate(180deg);
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px !important;
    font-family: var(--fontProximaSemi);
    color: var(--dark) !important;
  }

  &.ant-select-sm {
    height: 32px;
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }
    &.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 32px;
    }
    &.ant-select-single .ant-select-selector .ant-select-selection-item,
    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 32px !important;
      font-family: var(--fontProximaSemi);
    }
    &.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
      .ant-select-selector {
      padding: 0 15px;
    }
    .ant-select-arrow {
      top: 19px;
    }
  }
`;

export default StyledSelect;
