import React from "react";
import styled, { css } from "styled-components";
import { Button } from "antd";

const StyledCancel = styled(({ ...props }) => <Button {...props} />)`
  && {
    display: block;
    height: 40px;
    padding: 4px 35px;
    background: none;
    font-family: var(--fontProximaRegular), sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4265da;
    border: none;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    &:disabled {
    }
  }
`;
export default StyledCancel;
