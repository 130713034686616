import styled from "styled-components";

const LoginBlock = styled.div`
  width: 100%;
`;

const Remember = styled.div`
  margin-top: 30px;
  width: 100%;
  margin-bottom: 30px;
`;
export default {
  LoginBlock,
  Remember
};
