import styled from 'styled-components'
import {Upload} from "antd";

const StyledUpload = styled(Upload)`
  display: flex;

  &&& .ant-upload {
    display: block;
  }
`

export default StyledUpload;


