import React from 'react';

import {FormattedMessage} from "react-intl";
import LoginForm from "./Login.Form";
import Styled from './styled/StyledLoginPage.js'
import LoginFormLogo from "assets/img/logoLightBG.svg";

const Login = () => {
  return (
    <div>
      <img src={LoginFormLogo} alt="logo" className={"w-2/3 mx-auto mb-8 md:hidden"} />
      <Styled.LoginBlock>

        <Styled.LoginSubtitle>
          <FormattedMessage id="Login.Welcome"/>
        </Styled.LoginSubtitle>
        <Styled.LoginTitle>
          <FormattedMessage id="Login.Title"/>
        </Styled.LoginTitle>

        <LoginForm/>
        {/*<Styled.ForgotPassword>*/}
        {/*    <Styled.ForgotPasswordLink>*/}
        {/*        <FormattedMessage id="Login.ForgotPassword" />*/}
        {/*    </Styled.ForgotPasswordLink>*/}
        {/*</Styled.ForgotPassword>*/}

      </Styled.LoginBlock>
    </div>
  );
};

export default Login;
