import styled, { css } from "styled-components";
import Dash1 from "assets/img/dash1.svg";
import Dash2 from "assets/img/dash2.svg";
import Dash3 from "assets/img/dash3.svg";
import Dash4 from "assets/img/dash4.svg";
import Dash5 from "assets/img/dash5.svg";
import { Link } from "react-router-dom";
import {DEVICE} from "constants/media";

const Block = styled.div`
  width: 100%;
  height: 471px;
  margin-bottom: 140px;
`;

const Logo = styled.div`
  width: 104px;
  height: 104px;
  box-shadow: 0px 5px 10px #6627282a;
  border-radius: 50%;
  border: white solid 5px;
  margin: 0 auto;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  height: 471px;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 4px;
  padding: 0;
`;
const Subtitle = styled.h2`
  font-family: var(--fontProximaRegular);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  margin-bottom: 10px;
`;

const List = styled.div`
  display: flex;
  position: relative;
    justify-content: center;
  top: -60px;
    @media ${DEVICE.mobileDevices} {
        flex-wrap: wrap;
    }
`;

const Item = styled(Link)`
  display: inline-flex;
  cursor: pointer;
  flex-direction: column;
  width: 208px;
  height: 275px;
  background: #ffffff;
  border: 1px solid #d6e1e9;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  transition: all 0.25s ease-in;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        * {
          opacity: 0.58;
        }
      `;
    }
  }};

  &:hover {
    box-shadow: 0 0 15px rgba(139, 155, 163, 0.35);
  }

  &:nth-child(1) {
    i {
      background: #eaedf4 url(${Dash1}) center center no-repeat;
    }
  }
  &:nth-child(2) {
    i {
      background: #eaedf4 url(${Dash2}) center center no-repeat;
    }
  }
  &:nth-child(3) {
    i {
      background: #eaedf4 url(${Dash3}) center center no-repeat;
    }
  }
  &:nth-child(4) {
    i {
      background: #eaedf4 url(${Dash4}) center center no-repeat;
    }
  }
  &:nth-child(5) {
    i {
      background: #eaedf4 url(${Dash5}) center center no-repeat;
    }
  }

    @media ${DEVICE.mobileDevices} {
        width: 180px;
        margin: 7px;
        
    }
`;
const ItemIcon = styled.i`
  display: inline-flex;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #eaedf4;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
`;
const ItemTitle = styled.h3`
  font-family: var(--fontProximaBold);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
  padding: 0;
  margin-top: 0;
`;

const ItemButton = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e9edf0;
  padding: 16px;
  font-family: var(--fontProximaSemi);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #5478f1;
  transition: all 0.3s ease-in;
  border-radius: 0 0 10px 10px;
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return css`
         {
          opacity: 0.7;
          cursor: auto;
        }
      `;
    }
  }};

  a {
    color: #5478f1;
    font-size: 13px;
    cursor: pointer;
    font-family: var(--fontProximaSemi);
    &:hover {
      color: #3967ef;
    }
  }
`;

const ItemDescription = styled.p`
  font-family: var(--fontProximaSemi);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  max-height: 55px;
  color: #9a9fb0;
  padding: 0 14px;
`;
const Bg = styled.div`
  background: ${({ bodyBg }) =>
    bodyBg && `url(${bodyBg}) center center no-repeat`};
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
`;
export default {
  Block,
  Header,
  Title,
  Subtitle,
  List,
  Item,
  ItemDescription,
  ItemTitle,
  ItemButton,
  ItemIcon,
  Logo,
  Bg,
  Content
};
