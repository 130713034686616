import React from 'react';

import StyledDatePicker from './styled/StyledDatePicker.js';

const DatePicker = ({children, ...props}) => (
    <StyledDatePicker {...props} format={"YYYY-MM-DD"} >
		{children}
    </StyledDatePicker>
);

export default DatePicker;