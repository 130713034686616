import isBoolean from "lodash/isBoolean";

export const filtersValidation = (values = {}) => {
  const errors = [];
  if (!!values.filter?.length) {
    values.filter.forEach((filter, index) => {
      const filterErrors = {};

      if (!filter || !filter.name) {
        filterErrors.name = "Filters.Errors.Name";
        errors[index] = filterErrors;
      }
      if (!filter || !filter.operator) {
        filterErrors.operator = "Filters.Errors.Operator";
        errors[index] = filterErrors;
      }
      if (!filter || (!isBoolean(filter.value) && !filter.value)) {
        filterErrors.value = "Filters.Errors.Value";
        errors[index] = filterErrors;
      }
    });
  }
  return { filter: errors };
};

export const prepareFilters = (filters = []) => {
  return filters.reduce(
    (acc, filter) => ({
      ...acc,
      [`${filter.name}${
        filter.operator === "=" ? "" : filter.operator
      }`]: filter.value
    }),
    {}
  );
};

export const decodeFilters = filters => {
  const entries = Object.entries(filters);
  return entries.map(filter => {
    const name = filter[0].split("_");
    return {
      name: name[0],
      operator: name[1] ? `_${name[1]}` : "=",
      value: filter[1]
    };
  });
};
