import styled from "styled-components";
import { Link } from "react-router-dom";
import Add from "assets/img/Icon/Plus.svg";

const LiturgyPage = styled.div`
  padding: 80px 0;
  position: relative;
`;

const TopMenu = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
`
const TitlePage = styled.h1`
    font-family: var(--fontProximaBold);
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: var(--dark);
    padding: 0 0 40px 0;
`

export default {
  LiturgyPage,
  TitlePage,
};
