import styled from "styled-components";
import { Menu } from "antd";

const ViewFamilyLink = styled.div`
  a {
    color: #587cf4;
    border-bottom: 1px dashed #587cf4;
  }
`;

const ImgCol = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;

  .ant-avatar.ant-avatar-icon {
    border-radius: 50%;
  }

`;

const StyledImg = styled.div`
  width: 100%;
  height: 100%; 
  transition: all 0.25s ease-in;
  cursor: pointer;
  img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.25s ease-in;
  }
`;

const ZoomImg = styled.div`
  position: absolute;
  top: -200px;
  left: -70px;
  display: none;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transition: all 0.25s ease-in;
  cursor: pointer;
  border: 1px solid var(--lightGrey);
  img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: all 0.25s ease-in;
  } 
`;


const ImgBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  &:hover ${ZoomImg}{
    display: block;
  }
   &:hover ${StyledImg}{
    opacity: 0.5;
    background-color: rgba(255,255,255,0.5);
  }
`;

const MenuItem = styled(Menu.Item)`
  &&& {
    font-size: 14px;
    a {
      font-size: 14px;
    }
  }
`;

export default {
  ViewFamilyLink,
  MenuItem,
  ImgCol,
  StyledImg,
  ImgBlock,
  ZoomImg
};
