export default {
    //General
    "General.Male": "Male",
    "General.Female": "Female",

    "Notification.Success": "Success",
    "Notification.Error": "Error",

    //Header
    "Header.Logo": "Corner Stone",
    "Header.Logout": "Logout",
    "Header.Blue.Title": "St. George and St. Anthony",
    "Header.Blue.SubTitle": "17th St - Al MushrifW24-02 - Abu Dhabi - UAE",

    //Main page
    "MainPage.Title": "Corner Stone Portal",
    "MainPage.Subtitle": "UAE Copts Admin portal",
    "MainPage.Description":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit," +
        " sed do eiusmod tempor incididunt ut labore et dolore" +
        " magna aliqua. Ut enim ad minim veniam, quis nostrud " +
        "exercitation ullamco laboris nisi ut aliquip ex" +
        " ea commodo consequat.",

    //Login
    "Login.Welcome": "Welcome to",
    "Login.Title": "Corner Stone",
    "Login.Email": "Email",
    "Login.Password": "Password",
    "Login.Remember": "Remember me",
    "Login.Button": "Login",
    "Login.ForgotPassword": "Forgot Password?",

    //Dashboard
    "Dashboard.Title": "St. George and St. Anthony",
    "Dashboard.Description": "17th St - Al MushrifW24-02 - Abu Dhabi - UAE",

    "Dashboard.Members.Title": "Members",
    "Dashboard.Members.Description":
        "The section where you can see all the church members and families and their information",
    "Dashboard.Members.Button": "Explore more",
    "Dashboard.Members.ButtonSoon": "Coming soon",

    "Dashboard.Services.Title": "Liturgies",
    "Dashboard.Services.Description":
        "The Section where you can manage the liturgies schedule for your church",
    "Dashboard.Services.Button": "Explore more",
    "Dashboard.Services.ButtonSoon": "Coming soon",

    "Dashboard.Liturgies.Title": "Events",
    "Dashboard.Liturgies.Description":
        "This Section to Add, Remove, or Edit any event that will be visible on the UAECopts portal",
    "Dashboard.Liturgies.Button": "Explore more",
    "Dashboard.Liturgies.ButtonSoon": "Coming soon",

    "Dashboard.Meetings.Title": "Services",
    "Dashboard.Meetings.Description":
        "Here you can manage church services classes data to help servants manage their classes",
    "Dashboard.Meetings.Button": "Explore more",
    "Dashboard.Meetings.ButtonSoon": "Coming soon",

    "Dashboard.Booking.Title": "Meetings & Attendance",
    "Dashboard.Booking.Description":
        "Here you can create meetings and take attendance for your service groups",
    "Dashboard.Booking.Button": "Explore more",
    "Dashboard.Booking.ButtonSoon": "Coming soon",

    "Dashboard.Reports.Title": "Reports",
    "Dashboard.Reports.Description":
        "Here you find general reports for your data",
    "Dashboard.Reports.Button": "Explore more",
    "Dashboard.Reports.ButtonSoon": "Coming soon",

    //Members
    "Members.Title": "Members",
    "Members.MembersCount":
        "{ count } { count, plural, one { member } other { members }  } found",
    "Members.TableView": "Table view",
    "Members.MapView": "Map view",
    "Members.Table.ID": "ID",
    "Members.Table.Picture": "Picture",
    "Members.Table.FullName": "Full Name",
    "Members.Table.Email": "Email",
    "Members.Table.Mobile": "Mobile",
    "Members.Table.ViewFamily": "View Family",
    "Members.BackButton": "Back to members",
    "Members.EditInfo": "Edit information",
    "Members.Remove": "Remove",
    "Members.AddFilter": "Add Filter",

    //Member
    "Member.YearsOld":
        "{ count } { count, plural, one { year } other { years }  } old",
    "Member.Form.Personal": "Personal information",
    "Member.Form.FirstName": "First name",
    "Member.Form.SecondName": "Second name",
    "Member.Form.LastName": "Last name",
    "Member.Form.Gender": "Gender",
    "Member.Form.DateOfBirth": "Date of birth",

    "Member.Form.Contact": "Contact information",
    "Member.Form.Email": "Email",
    "Member.Form.Phone": "Phone",
    "Member.Form.Whatsapp": "Whatsapp",

    "Member.Form.Additional": "Additional information",
    "Member.Form.SocialStatus": "Social status",
    "Member.Form.WorkingField": "Working field",
    "Member.Form.FatherOfConfession": "Father of confession",
    "Member.Form.Services": "Services",
    "Member.Form.Deacon": "Deacon",

    "Member.Form.AddressInfo": "Address",
    "Member.Form.Emirate": "Emirate",
    "Member.Form.Area": "Area",
    "Member.Form.MapLocation": "Map location",
    "Member.Form.Address": "Address",

    "Member.Form.LocationNotAvailable": "Location Not Available",

    "Member.FamilyInfo": "Family information",

    "Family.Wife": "Wife",
    "Family.Husband": "Husband",
    "Family.Mother": "Mother",
    "Family.Father": "Father",
    "Family.Daughter": "Daughter",
    "Family.Son": "Son",
    "Family.GrandMother": "Grand mother",
    "Family.GrandFather": "Grand father",

    "Family.MembersCount":
        "Family { count, plural, one { member } other { members }  }",

    "Family.ExploreFamily": "Explore all family",

    Family: "Family",
    "Family.MainMembers": "Main Members",
    "Family.Kids": "Kids",
    "Family.GrandParents": "Grand parents",

    "Family.Address": "Address",
    "Family.AddMember": "Add member",
    "Family.AddChild": "Add child",
    "Family.AddParent": "Add parent",

    "Member.Form.Save": "Save",
    "Member.Form.Cancel": "Cancel",
    "Member.Form.Deacon.Yes": "Yes",
    "Member.Form.Deacon.No": "No",

    "SocialStatus.Single": "Single",
    "SocialStatus.Married": "Married",
    "SocialStatus.Widow": "Widow",
    "SocialStatus.Other": "Other",

    "Filters.FirstName": "First Name",
    "Filters.SecondName": "Second Name",
    "Filters.LastName": "Last Name",
    "Filters.Gender": "Gender",
    "Filters.Email": "Email",
    "Filters.Mobile": "Mobile",
    "Filters.Birthday": "Birthday",
    "Filters.Age": "Age",
    "Filters.Status": "Martial Status",
    "Filters.Nationality": "Nationality",
    "Filters.FatherOfConfession": "Father of Confession",
    "Filters.WorkingField": "Working Field",
    "Filters.Area": "Area",
    "Filters.Deacon": "Deacon",
    "Filters.VisaType": "Visa Type",
    "Filters.Apply": "Apply",

    "Filters.Errors.Name": "Name is required",
    "Filters.Errors.Operator": "Operator is required",
    "Filters.Errors.Value": "Value is required",
    "Filters.SearchPlaceholder": "Search for an entry...",

    "Nationality.Other": "Other",
    "Nationality.Egyptian": "Egyptian",
    "VisaType.Residence": "Residence",
    "VisaType.Visit": "Visit",
    "VisaType.Other": "Other",
    "Deacon.Yes": "Yes",
    "Deacon.No": "No",

    "Operators.GTE": "greater than or equal",
    "Operators.LTE": "less than or equal",
    "Operators.Is": "is",
    "Operators.Contains": "contains",

    //Liturgies

    "Liturgies.Title": "Events",
    "Liturgies.AddNewPrayer": "Add New Event",
    "Liturgies.UpdateExisting": "Update Event",
    "Liturgies.Form.Title": "Title",
    "Liturgies.Form.TitleAr": "Title Arabic",
    "Liturgies.Form.LiturgyDate": "Event Date",
    "Liturgies.Form.Time": "Time",
    "Liturgies.Form.MaxCapacity": "Max Capacity",
    "Liturgies.Form.BookedCapacity": "Booked Capacity",
    "Liturgies.Form.RuleType": "Rule Type",
    "Liturgies.Form.RuleWindow": "Rule Window",
    "Liturgies.Form.PublishDate": "Publish Date",
    "Liturgies.Form.PrayerType": "Prayer Type",
    "Liturgies.Form.MinAllowedAge": "Minimum Allowed Age",
    "Liturgies.Form.MaxAllowedAge": "Max Allowed Age",
    "Liturgies.Form.Exclusive": "Exclusive",
    "Liturgies.Form.ApplyPenalty": "Apply Penalty",
    "Liturgies.Form.IsPrivate": "Hidden",
    "Liturgies.Form.RuleKey": "Group Rule Name",
    "Liturgies.Form.SaveLiturgy": "Save New Event",
    "Liturgies.Form.UpdateLiturgy": "Update Event",
    "Liturgies.Form.DownloadBookings": "Download Bookings",
    "Liturgies.Form.Cancel": "Cancel",

    "Liturgies.Table.Title": "Title",
    "Liturgies.Table.Date": "Date",
    "Liturgies.Table.MaxCapacity": "Max Capacity",
    "Liturgies.Table.Booked": "Booked",
    "Liturgies.Table.Rule": "Rule",
    "Liturgies.Table.Actions": "Actions",
    "Liturgies.Table.RuleTypeOpen": "Open Booking",
    "Liturgies.Table.RuleTypeDay": "Once every {ruleWindow} { ruleWindow, plural, one { day } other { days }}",
    "Liturgies.Table.RuleTypeWeek": "Once every {ruleWindow} { ruleWindow, plural, one { week } other { weeks }}",
    "Liturgies.Table.RuleTypeGroup": "Group ({ruleKey})",
    "Liturgies.Delete.Title": "Confirm",
    "Liturgies.Delete.Desc": "Are you sure you want to delete this Liturgy",
    "Liturgies.Delete.Yes": "Yes",
    "Liturgies.Delete.Cancel": "Cancel",

    "Liturgy.EventType.Liturgy": "Liturgy",
    "Liturgy.EventType.SpecialLiturgy": "Special Liturgy",
    "Liturgy.EventType.Vespers": "Vespers",
    "Liturgy.EventType.Meeting": "Meeting",
    "Liturgy.EventType.FeastLiturgy": "Feast Liturgy",
    "Liturgy.EventType.SundaySchools": "Sunday Schools & Activities",
    "Liturgy.EventType.Baskha": "Baskha",

    "Services.Title": "Services",
    "Services.MarriageNOC": "Marriage NOC",
    "Services.Contributions": "Contributions",

    "MarriageNOC.Title": "Marriage NOC",
    "MarriageNOC.SearchPlaceholder": "Enter name, email, mobile, ID",

    "MarriageNOC.PendingSection": "Pending",
    "MarriageNOC.Previous": "Previous",


    "Contributions.Title": "Contributions",
    "Contributions.SearchPlaceholder": "Enter name, email, mobile, ID",
    "Contributions.Church": "Church",
    "Contributions.Father": "Father",
    "Contributions.Reason": "Reason",
    "Contributions.Amount": "Amount",

    "Contributions.ModalTitle": "Contribution",
    "Contributions.Subtitle": "History",
    "Contributions.AddNew": "Add New Giving",

    "Contributions.AddButton": "Add",
    "Contributions.EditButton": "Edit",
    "Contributions.SuccessCreateMessage": "Contributions successfully created",
    "Contributions.SuccessEditMessage": "Contributions successfully edit",
    "Contributions.Success": "Success",


    "Contributions.Form.FullName": "Full Name",
    "Contributions.Form.Mobile": "Mobile Number",
    "Contributions.Form.Reason": "Reason",
    "Contributions.Form.Amount": "Amount",
    "Contributions.Form.Father": "Father",
    "Contributions.Form.Church": "Church",
    "Contributions.Form.EmiratesID": "Emirates ID",
    "Contributions.Form.AddFile": "Add file",


    "MarriageNOCDetails.Title": "Marriage NOC Details",
    "MarriageNOCDetails.OldNOCs": "{count} other NOCs found",
    "MarriageNOCDetails.UpdateStatus": "Save New Status",
    "MarriageNOCDetails.Print": "Print",
    "MarriageNOCDetails.Warning.Title": "Other NOCs Detected",
    "MarriageNOCDetails.Warning.Content": "Looks this User has one or more Active NOCs, please check them first and make sure to cancel them first",
    "MarriageNOCDetails.Warning.Print": "Print Anyways",
    "MarriageNOCDetails.Warning.CheckOthers": "Check Other NOCs",
    "MarriageNOCDetails.Church": "Church",
    "MarriageNOCDetails.Status": "Status",
    "MarriageNOCDetails.UploadPhoto": "Upload new photo",
    "MarriageNOCDetails.PhotoInfo": "The maximum size is 2 MB. JPG or PNG file",
    "MarriageNOCDetails.PersonalInfo": "Personal info",
    "MarriageNOCDetails.FullName": "Full name",
    "MarriageNOCDetails.DateOfBirth": "Date Of Birth",
    "MarriageNOCDetails.IsCopticOrthodox": "Is Coptic Orthodox",
    "MarriageNOCDetails.IsCopticOrthodox.Yes": "Yes",
    "MarriageNOCDetails.IsCopticOrthodox.No": "No",
    "MarriageNOCDetails.PlaceOfBirth": "Place Of Birth",
    "MarriageNOCDetails.DateOfBaptism": "Date of Baptism",
    "MarriageNOCDetails.PlaceOfBaptism": "Place Of Baptism",
    "MarriageNOCDetails.EgyptNationalID": "Egypt National ID",
    "MarriageNOCDetails.EmiratesID": "Emirates ID",
    "MarriageNOCDetails.MobileNumber": "Mobile Number",
    "MarriageNOCDetails.EmailAddress": "Email Address",
    "MarriageNOCDetails.PassportNumber": "Passport Number",
    "MarriageNOCDetails.EducationDegree": "Education Degree",
    "MarriageNOCDetails.AddressInEgypt": "Address in Egypt",
    "MarriageNOCDetails.AddressInUAE": "Address in UAE",
    "MarriageNOCDetails.CurrentJob": "Current Job and Employer",
    "MarriageNOCDetails.ChurchInEgypt": "Church in Egypt",
    "MarriageNOCDetails.FatherOfConfession": "Father of Confession",
    "MarriageNOCDetails.FatherOfConfessionChurch": "Father of Confession Church",
    "MarriageNOCDetails.ConfessionFrequency": "Confession Frequency",
    "MarriageNOCDetails.CommunionFrequency": "Communion Frequency",
    "MarriageNOCDetails.UAEArrivalDate": "UAE Arrival Date",
    "MarriageNOCDetails.UAEDepartDate": "UAE Departure Date",

    "MarriageNOCDetails.PreviousEngagement": "Previous Engagement",
    "MarriageNOCDetails.HowManyTimes": "How Many Times",
    "MarriageNOCDetails.PreviousEngagement.Yes": "Yes",
    "MarriageNOCDetails.PreviousEngagement.No": "No",

    "MarriageNOCDetails.PreviousMarriage": "Previous Marriage",
    "MarriageNOCDetails.PreviousMarriage.Yes": "Yes",
    "MarriageNOCDetails.PreviousMarriage.No": "No",
    "MarriageNOCDetails.PreviousMarriageField": "Previous Marriage",
    "MarriageNOCDetails.PreviousMarriageLocation": "Previous Marriage Location",
    "MarriageNOCDetails.PreviousMarriageDate": "Previous Marriage Date",
    "MarriageNOCDetails.PreviousMarriageChurch": "Previous Marriage Church",
    "MarriageNOCDetails.PreviousMarriageFather": "Previous Marriage Father",
    "MarriageNOCDetails.CurrentStatus": "Current Status",
    "MarriageNOCDetails.WidowedDate": "Widowed Date",
    "MarriageNOCDetails.AnotherMarriageBeforeWidowed": "Another Marriage Before Widowed",
    "MarriageNOCDetails.AnotherMarriageBeforeWidowed.Yes": "Yes",
    "MarriageNOCDetails.AnotherMarriageBeforeWidowed.No": "No",
    "MarriageNOCDetails.PreviousSpouseName": "Previous Spouse Name, Date, Location",
    "MarriageNOCDetails.Date": "Date",
    "MarriageNOCDetails.Location": "Location",

    "MarriageNOCDetails.Divorcement": "Divorcement",
    "MarriageNOCDetails.IsDivorced": "Is Divorced?",
    "MarriageNOCDetails.IsDivorced.Yes": "Yes",
    "MarriageNOCDetails.IsDivorced.No": "No",
    "MarriageNOCDetails.Divorcement.Court": "Court Name",
    "MarriageNOCDetails.Divorcement.CaseNumber": "Case Number",
    "MarriageNOCDetails.Divorcement.Date": "Date",
    "MarriageNOCDetails.Divorcement.ReMarriageCertificate": "Re-Marriage Certificate Issuer & Date",
    "MarriageNOCDetails.Divorcement.NumberOfChildren": "Number of Children",
    "MarriageNOCDetails.Divorcement.NameAgeEtc": "Names, ages, is Baptized",
    "MarriageNOCDetails.Divorcement.RelevantKindToChildren": "Relevant Kind to Children",


    "MarriageNOCDetails.Witnesses": "Witnesses",
    "MarriageNOCDetails.FirstWitnessNationalID": "First Witness National ID",
    "MarriageNOCDetails.FirstWitnessName": "First Witness Name",
    "MarriageNOCDetails.SecondWitnessName": "Second Witness Name",
    "MarriageNOCDetails.SecondWitnessNationalID": "Second Witness National ID",

    "MarriageNOCDetails.HardCopy": "Hard copy",
    "MarriageNOCDetails.CancellationProof": "Cancellation Proof",
    "MarriageNOCDetails.Documents": "Documents",
    "MarriageNOCDetails.Photo": "Photo",

    "MarriageNOCDetails.CurrentStatus.Single": "Single",
    "MarriageNOCDetails.CurrentStatus.Divorced": "مطلق",
    "MarriageNOCDetails.CurrentStatus.Widow": "أرمل",

    "MarriageNOCDetails.UpdateInformation": "Update information",

    "MarriageNOCDetails.StatusUpdateSuccess": "Status was successfully updated",
    "MarriageNOCDetails.DetailsUpdateSuccess": "Details was successfully updated",

    "MarriageNOCDetails.CreatSuccess": "New NOC created successfully",
    "MarriageNOCDetails.CreatFailed": "Error on creating new NOC",

    "MarriageNOCDetails.PrevMarriageLocation.Inside": "داخل مصر",
    "MarriageNOCDetails.PrevMarriageLocation.Outside": "خارج مصر",

    "MarriageNOCDetails.Frequency.Committed": "منتظم",
    "MarriageNOCDetails.Frequency.Normal": "عادي",
    "MarriageNOCDetails.Frequency.Un_committed": "غير منتظم",
    "MarriageNOCDetails.Frequency.Unknown": "غير معروف",

    "MarriageNOCDetails.MilitaryStatus": "Military Status",
    "MarriageNOCDetails.MilitaryStatus.NA": "أنثى",
    "MarriageNOCDetails.MilitaryStatus.NotNeeded": "غير مطلوب للتجنيد",
    "MarriageNOCDetails.MilitaryStatus.Done": "أتم الخدمة",
    "MarriageNOCDetails.MilitaryStatus.TempExemption": "إعفاء مؤقت",

    //Status
    "Status.Pending": "Pending",
    "Status.Draft": "Draft",
    "Status.NeedMoreInfo": "Need more info",
    "Status.Rejected": "Rejected",
    "Status.Ready": "Ready",
    "Status.HeadedOver": "Headed over",
    "Status.Canceled": "Canceled",

    //NOTIFICATIONS
    "Login.Notification.SuccessMessage": "Login successfully",
    "Login.Notification.Success": "Success",
    "Login.Notification.ErrorMessage":
        "There is something wrong with the data you have entered, please check all fields in red",
    "Login.Notification.Error": "Error",
    "Auth.form.error.invalid": "Identifier or password invalid"
};
