import styled from 'styled-components';
import {Link} from "react-router-dom";
import {DEVICE} from "constants/media";

const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  margin: 24px 0 16px;
    gap: 16px;

    @media ${DEVICE.mobileDevices} {
        flex-wrap: wrap;
    }
`;

const StatusBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
    flex-wrap: wrap;
  font-size: 10px;
  margin: 24px 0 16px;
    gap: 16px;
`;

const Status = styled.div`
  font-weight: bold;
  color: #ffffff;
  padding: 4px 8px;
  background: linear-gradient(70.51deg, #4164DA 16.8%, #587CF4 93.54%);
  border-radius: 5px;
  line-height: 1;
  font-size: 12px;
  margin-right: 10px;
  text-transform: uppercase;
`;

const Date = styled.div`
    color: rgba(39, 40, 42, .5);
    font-weight: bold;
    margin-right: 14px;
    font-size: 12px;

    .anticon {
        margin-right: 4px;
    }
`;

const RelatedNOCs = styled(Link)`
    color: var(--red);
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
`;

const Id = styled.div`
  color: rgba(39, 40, 42, .5);
  font-weight: bold;
  padding: 4px 8px;
  background: #F1F2F3;
  border-radius: 5px;
  line-height: 1;
    font-size: 12px;
`;

const StatusSelect = styled.div`
  width: 200px;
`;

const StatusSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

    @media ${DEVICE.mobileDevices} {
        flex-wrap: wrap;
    }
`;

const Avatar = styled.div`
  width: 100px;
  height: auto;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default {
    StatusRow,
    Status,
    Date,
    Id,
    StatusSelect,
    StatusSection,
    Avatar,
    RelatedNOCs,
    StatusBottomRow
}
