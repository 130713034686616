import styled from "styled-components";
import TitleHead from "assets/img/Icon/title.svg";

const Item = styled.div`
  width: 192px;
  height: 170px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  margin-right: 24px;
  background: #fff;
  box-shadow: 0 0 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  margin-top: 30px;
  &:after {
    content: "";
    position: absolute;
    width: 192px;
    height: 75px;
    top: 0;
    left: 0;
    background: url(${TitleHead}) center center no-repeat;
    background-size: contain;
  }
`;

const ItemContent = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
`;

const UserPic = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  margin: 25px auto 10px auto;

  .ant-avatar.ant-avatar-icon {
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const MemberName = styled.div`
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark);
  text-align: center;
  padding: 0 10px;
  margin: 0;
`;

const MemberRole = styled.p`
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: var(--gray);
  text-align: center;
  padding: 0 10px;
  margin: 0;
`;

export default {
  Item,
  ItemContent,
  UserPic,
  MemberName,
  MemberRole
};
