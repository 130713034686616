import styled from "styled-components";
import { Upload } from "antd";
import {DEVICE} from "../../constants/media";

const Page = styled.div`
    padding: 30px 0;
`;

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px 160px;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  color: var(--dark);
  padding: 0 40px 0 0;
`;

const ArabicOnlyHint = styled.div`
    background-color: var(--red);
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-weight: bold;
    width: 350px;
    margin-top: 8px;
`;

const Label = styled.div`
  display: flex;
  text-transform: uppercase;
  margin-bottom: 14px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

    span {
        margin-right: 14px;
        font-size: 11px;
        color: #9a9fb0;
        font-weight: bold;

        &:last-child {
            height: 1px;
            background: #d6e1e9;
            flex-grow: 1;
        }
    }
`;

const Section = styled.div`
  margin: 25px 0;
`;

const DocsHint = styled.div`
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color:var(--gray);
  padding-top: 12px;
  padding-bottom: 5px;
`;

const FieldsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px 32px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(32% - 16px);

  @media ${DEVICE.mobileDevices} {
    width: calc(100% - 16px);
  }

  &&& {
    & > * {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  &&& > * {
    margin-right: 16px;
  }
`;

export default {
    Page,
    Title,
    Section,
    Label,
    FieldsWrap,
    Field,
    Container,
    ButtonsRow,
    ArabicOnlyHint,
    DocsHint,
};
