import React from 'react';
import {reduxForm} from 'redux-form';
import {Select} from 'antd';
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import InputField from "components/InputField/InputField";
import InputPhoneField from "components/InputPhoneField/InputPhoneField";
import SelectField from "components/SelectField/SelectField";
import Button from "components/Button/Button";
import {appInfoChurchesSelector, appInfoFathersSelector} from "services/appInfo/appInfo.selector";
import {getLocalizedKey} from "utils/utils";
import {createContributionsAction, editContributionsAction} from "services/contributions/contributions.actions";
import {profileSelector} from "services/profile/profile.selectors";
import FileUploader from "components/FileUploader/FileUploader";
import Styled from './styled';

const {Option} = Select;

const ContributionsForm = ({handleSubmit, contributionId, initialValues}) => {
    const churches = useSelector(appInfoChurchesSelector);
    const fathers = useSelector(appInfoFathersSelector);
    const churchId = useSelector(profileSelector)?.church?.id;
    const isFormDisabled = !!initialValues?.church && churchId !== initialValues?.church;

    return (
        <form onSubmit={handleSubmit}>
            <Styled.ModalForm>
                <Styled.ModalField>
                    <InputField
                        disabled={isFormDisabled}
                        name={'fullName'}
                        placeholder={"Contributions.Form.FullName"}
                        label={"Contributions.Form.FullName"}
                    />
                </Styled.ModalField>
                <Styled.ModalField>
                    <InputPhoneField
                        disabled={isFormDisabled}
                        name={'mobileNumber'}
                        placeholder={"Contributions.Form.Mobile"}
                        label={"Contributions.Form.Mobile"}
                    />
                </Styled.ModalField>
                <Styled.ModalField>
                    <InputField
                        disabled={isFormDisabled}
                        name={'reason'}
                        placeholder={"Contributions.Form.Reason"}
                        label={"Contributions.Form.Reason"}
                    />
                </Styled.ModalField>
                <Styled.ModalField>
                    <InputField
                        disabled={isFormDisabled}
                        name={'amount'}
                        placeholder={"Contributions.Form.Amount"}
                        label={"Contributions.Form.Amount"}
                    />
                </Styled.ModalField>
                <Styled.ModalField>
                    <SelectField
                        disabled={isFormDisabled}
                        name={'church'}
                        placeholder={"Contributions.Form.Church"}
                        label={"Contributions.Form.Church"}
                    >
                        {churches?.map(church => (
                            <Option value={church?.id}>
                                {church[getLocalizedKey('name')]}
                            </Option>
                        ))}
                    </SelectField>
                </Styled.ModalField>
                <Styled.ModalField>
                    <SelectField
                        disabled={isFormDisabled}
                        name={'father'}
                        placeholder={"Contributions.Form.Father"}
                        label={"Contributions.Form.Father"}
                    >
                        {fathers?.map(father => (
                            <Option value={father?.id}>
                                {father[getLocalizedKey('name')]}
                            </Option>
                        ))}
                    </SelectField>
                </Styled.ModalField>
                <Styled.ModalField>
                    <InputField
                        disabled={isFormDisabled}
                        name={'emiratesID'}
                        placeholder={"Contributions.Form.EmiratesID"}
                        label={"Contributions.Form.EmiratesID"}
                    />
                </Styled.ModalField>
            </Styled.ModalForm>
            <FileUploader
                formName={'contributions'}
                name={'attachments'}
                isFormDisabled={isFormDisabled}
            />
            <Styled.ButtonWrapper>
                <Button
                    disabled={isFormDisabled}
                    htmlType={'submit'}
                >
                    <FormattedMessage
                        id={
                            contributionId ?
                                "Contributions.EditButton" :
                                "Contributions.AddButton"
                        }
                    />
                </Button>
            </Styled.ButtonWrapper>
        </form>
    );
};

export default reduxForm({
    form: "contributionForm",
    onSubmit: (value, dispatch) => {
        value?.id ? dispatch(editContributionsAction({
            ...value,
        })) : dispatch(createContributionsAction({
            ...value,
        }))
    },
    enableReinitialize: true,
    destroyOnUnmount: true
})(ContributionsForm);