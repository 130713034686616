import { put, takeEvery } from "redux-saga/effects";
import { notification } from "antd";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS
} from "./password.action";
import { SET_MODAL_STATUS } from "../modals/modals.action";
import { forgotPasswordRequest, resetPasswordRequest } from "./password.api";
import { urlLocations, history } from "../../routes/urlLocations";
import openNotification from "../../utils/notifications";

function* forgotPassword({ payload }) {
  try {
    const { response } = yield forgotPasswordRequest({
      ...payload
    });
    if (response.status === 200) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS
      });
      yield openNotification({
        type: "success",
        message: "ForgotPassword.Notification.Success",
        description: "ForgotPassword.Notification.SuccessMessage"
      });

      yield put({ type: SET_MODAL_STATUS, payload: null });
    } else {
      yield openNotification({
        type: "error",
        message: "ForgotPassword.Notification.Error",
        description:
          (response.data.data.email &&
            response.data.data.email.length > 0 &&
            response.data.data.email[0]) ||
          "ForgotPassword.Notification.ErrorMessage"
      });
      yield put({ type: FORGOT_PASSWORD_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

function* resetPassword({ payload }) {
  try {
    const { response } = yield resetPasswordRequest({
      ...payload
    });
    if (response.status === 200) {
      yield put({
        type: RESET_PASSWORD_SUCCESS
      });
      yield openNotification({
        type: "success",
        message: "ResetPassword.Notification.Success",
        description: "ResetPassword.Notification.SuccessMessage"
      });
      history.push(urlLocations.login);
    } else {
      yield openNotification({
        type: "error",
        message: "ResetPassword.Notification.Error",
        description:
          (response.data.data.email &&
            response.data.data.email.length > 0 &&
            response.data.data.email[0]) ||
          "ResetPassword.Notification.ErrorMessage"
      });
      yield put({ type: RESET_PASSWORD_FAILURE });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* passwordSaga() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(RESET_PASSWORD, resetPassword);
}
