import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import {DEVICE} from "constants/media";

const Header = styled.div`
  width: 100%;
  height: 80px;
  padding: 0;
  display: flex;
  background: linear-gradient(90deg, #19407d 0%, #1250af 100%);
  box-shadow: 5px 9px 10px rgba(139, 155, 163, 0.1);
  justify-content: space-between;
`;
const Logo = styled.div`
  display: inline-flex;
  width: 360px;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
`;
const Img = styled.div`
  display: inline-flex;
  width: 50px;
  height: 50px;
  background: #fbfcfd;
  border-radius: 50%;
  margin-right: 10px;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoTxt = styled.div`
  width: calc(100% - 42px);
`;

const LogoTitle = styled.p`
  font-size: 15px;
  font-family: var(--fontProximaBold);
  color: #fff;
  margin-bottom: 0;
`;

const LogoSubTitle = styled.p`
  font-family: var(--fontProximaRegular);
  font-size: 13px;
  margin-bottom: 0;
  color: #ffffff;
  opacity: 0.5;
`;

const Nav = styled.div`
  display: inline-flex;
    @media ${DEVICE.mobileDevices} {
        display: none;
    }
`;

const NavItem = styled(Link)`
  &&& {
    display: inline-flex;
    height: 80px;
    padding: 0 17px;
    font-family: var(--fontProximaRegular);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.25s ease-in;
  }

  ${({disabled, access}) => {
    if (disabled && !access) {
      return css`
        opacity: 0.65;
      `;
    }
  }};

  &&& > a {
    color: #fff;

    &:focus {
      outline: none;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: #fff;

    a {
      color: #fff;
    }
  }

  ${({isActive}) => {
    if (isActive) {
      return css`
        background: #083375;
        position: relative;
        font-family: var(--fontProximaSemi);
        opacity: 1;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(100% - 2px);
          width: 100%;
          height: 2px;
          background: #25b1ff;
        }
      `;
    }
  }};
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
    height: 80px;
`;

const User = styled.div`
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 24px;
`;

export default {
    Header,
    Logo,
    LogoSubTitle,
    LogoTitle,
    LogoTxt,
    Img,
    Nav,
    NavItem,
    HeaderContent,
    User
};
