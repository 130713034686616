import React, { useState, useEffect } from 'react';
import DatePicker  from '../../components/DatePicker/DatePicker';
import { Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from 'moment';

import ControlWrapper from "../ControlWrapper/ControlWrapper";

const CustomDatePicker = ({
    input: { value, name, ...restInput },
    formatMessage,
    setTouched,
    disabledDate,
    disabled,
    customTouched,
    label,
    placeholder,
    ...meta
}) => {
    return <ControlWrapper
        label={label}
        name={name}
        customTouched={customTouched}
    >
        <DatePicker
            { ...restInput }
            {...meta}
            onFocus={setTouched.bind(null, true)}
            placeholder={
                placeholder && formatMessage({ id: placeholder })
            }
            inputReadOnly
            selected={value ? moment(value, "YYYY-MM-DD") : undefined}
            value={value ? moment(value, "YYYY-MM-DD") : undefined}
            disabledDate={disabledDate}
            disabled={disabled}
        />

    </ControlWrapper>
};

const DatePickerField = ({
    name,
    errors,
    intl: { formatMessage },
    placeholder,
    label,
    disabled,
    disabledDate,
    ...props
}) => {
    const [ customTouched, setTouched ] = useState(false);
    useEffect(() => {
        setTouched(false)
    }, [errors]);
    return (
        <Field
            component={CustomDatePicker}
            name={name}
            formatMessage={formatMessage}
            placeholder={placeholder}
            label={label}
            disabledDate={disabledDate}
            disabled={disabled}
            di={disabledDate}
            customTouched={customTouched}
            setTouched={setTouched}
            {...props}
        />
    )};

export default compose(
    connect(
        ({ errors }) => ({ errors }),
        {}),
    injectIntl
)(DatePickerField);
