import styled from 'styled-components';

const StyledPhotoSectionImgContent = styled.div`
  width: 110px;
  height: auto;
  max-height: 150px;
  border-radius: 10px;
  border: 1px solid  #d6e1e9;
 
  img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  border-radius: 10px;
  }
`

export default StyledPhotoSectionImgContent;


