import React from "react";
import {Switch} from "react-router";

import {urlLocations} from "./urlLocations";
import RouteWrapper from "./RouterWrapper";

import MainLayout from "layouts/MainLayout/MainLayout";
import Containers from 'containers/index';
import AuthorizeLayout from "layouts/AuthorizeLayouts/AuthorizeLayout";
import BasicLayout from "layouts/BasicLayout/BasicLayout";


export default () => (
    <Switch>
        <RouteWrapper
            exact
            path={urlLocations.dashboard}
            layout={BasicLayout}
            component={Containers.Dashboard}
        />
        <RouteWrapper
            exact
            path={urlLocations.membersPath}
            layout={AuthorizeLayout}
            component={Containers.Members}
        />
        <RouteWrapper
            exact
            path={urlLocations.liturgies}
            layout={AuthorizeLayout}
            component={Containers.Liturgies}
        />
        <RouteWrapper
            exact
            path={urlLocations.liturgyInfo}
            layout={AuthorizeLayout}
            component={Containers.Liturgy}
        />
        <RouteWrapper
            exact
            path={urlLocations.familyInfo}
            layout={AuthorizeLayout}
            component={Containers.Family}
        />
        <RouteWrapper
            exact
            path={urlLocations.marriageNOCDetails}
            layout={AuthorizeLayout}
            component={Containers.MarriageNOCDetails}
        />
        <RouteWrapper
            exact
            path={urlLocations.contributions}
            layout={AuthorizeLayout}
            component={Containers.Contributions}
        />
        <RouteWrapper
            exact
            path={urlLocations.marriageNOC}
            layout={AuthorizeLayout}
            component={Containers.MarriageNoc}
        />
        <RouteWrapper
            exact
            path={urlLocations.services}
            layout={AuthorizeLayout}
            component={Containers.Services}
        />
        <RouteWrapper
            exact
            path={urlLocations.memberInfo}
            layout={AuthorizeLayout}
            component={Containers.Member}
        />
        <RouteWrapper
            exact
            path={urlLocations.login}
            layout={MainLayout}
            component={Containers.Login}
        />
    </Switch>
);
