import React, { useState } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';


import { GOOGLE_API_KEY } from 'constants/constants';
import Styled from './styled';
import {useSelector} from "react-redux";
import {membersSelector} from "services/members/members.selector";



const MembersMap = ({ google }) => {
    const members = useSelector(membersSelector);
    const [ activeMarker, setActiveMarker ] = useState(null);
    const [ activeMember, setActiveMember ] = useState(null);
    const [ isInfoVisible, setInfoVisible ] = useState(false);

    const onMarkerClick = (marker, member) => {
        setActiveMarker(marker);
        setInfoVisible(true);
        setActiveMember(member)

    };

    const onMarkerClose = () => {
        setActiveMarker(null);
        setInfoVisible(false);
        setActiveMember(null)
    };

    return (
        <Styled.MapWrapper>
            <Map
                onMapClicked={onMarkerClose}
                className="map"
                initialCenter={{
                    lat: 25.0874817,
                    lng: 55.1786497
                }}
                google={google}
                zoom={5}
            >
            { members.map(({ familyID: { geoLocation, id } = {}, ...member }) => {
                return (
                    <Marker
                        onClick={m => onMarkerClick(m, member)}
                        id={id}
                        key={id}
                        position={{
                            lat: geoLocation?.latitude,
                            lng: geoLocation?.longitude,
                        }}
                    />
                )}) }
                { activeMarker && isInfoVisible && <InfoWindow
                            onClose={onMarkerClose}
                            visible={isInfoVisible}
                            {...activeMarker}
                        >
                            <h1>{activeMember?.firstName}</h1>
                        </InfoWindow>
                    }
            </Map>
        </Styled.MapWrapper>
    )
};


export default GoogleApiWrapper({
    apiKey: (GOOGLE_API_KEY)
})(MembersMap)