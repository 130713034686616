import styled from "styled-components";
import LocationImg from "assets/img/Icon/location.svg";
const MemberForm = styled.div``;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const Col33 = styled.div`
  width: 33%;
  max-width: 160px;
  display: inline-block;
`;
const Col60 = styled.div`
  width: 60%;
  max-width: 322px;
  display: inline-block;
`;
const Col40 = styled.div`
  width: 60%;
  max-width: 166px;
  display: inline-block;
`;
const Col45 = styled.div`
  width: 45%;
  max-width: 208px;
  display: inline-block;
`;
const Col100 = styled.div`
  width: 100%;
`;

const ColLeft = styled.div`
  width: 49%;
  display: inline-block;
`;

const ButtonsRow = styled.div`
  border-top: 1px solid #e9edf0;
  padding: 16px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  &&& .ant-btn {
    margin-right: 10px;
  }
`;

const ColRight = styled.div`
  width: 49%;
  display: inline-block;
`;

const FormHeader = styled.div`
  width: 100%;
  height: 47px;
  padding: 7px 15px 8px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(105.76deg, #4164da 11.01%, #587cf4 88.99%);
`;

const HeaderTitle = styled.h4`
  width: calc(100% - 50px);
  font-family: var(--fontProximaBold);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  padding: 0;
  margin: 0;
  color: #ffffff;
`;

const FormBlock = styled.div`
  width: 100%;
  //height: 100%;
  background: #ffffff;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  min-height: ${({ height }) => (height ? height : "224px")};
`;

const EditBtn = styled.div`
  color: #fff;
`;

const FormContent = styled.div`
  padding: 14px 24px 10px 24px;
`;

const Field = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

const LocationBtn = styled.a`
  height: 40px;
  background: #ffffff;
  border: 1px solid #4265da;
  box-shadow: 0px 10px 20px rgba(66, 101, 218, 0.1);
  border-radius: 8px;
  color: #4265da;
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    left: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url(${LocationImg}) center center no-repeat;
    background-size: contain;
  }
`;

export default {
  MemberForm,
  Row,
  Field,
  ColRight,
  EditBtn,
  ColLeft,
  ButtonsRow,
  FormContent,
  FormHeader,
  HeaderTitle,
  LocationBtn,
  Col33,
  Col100,
  Col60,
  Col40,
  Col45,
  FormBlock
};
