import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";

import Styled from './styled';
import StyledContainer from "components/styled/StyledContainer";
import Input from "components/Input/Input";
import {useIntl} from "react-intl";
import {SearchOutlined} from "@ant-design/icons"
import debounce from 'lodash/debounce'
import {useDispatch, useSelector} from "react-redux";
import {getMarriageNOCsSelector} from "services/marriageNoc/marriageNoc.selector";
import MarriageNOCCard from "containers/MarriageNOC/MarriageNOC.Card";
import {creatNewNOCAction, searchMarriageNocAction} from "services/marriageNoc/marriageNoc.actions";
import partition from 'lodash/partition';
import {STATUS} from "constants/status";
import MarriageNocDetailsSection from "containers/MarriageNOCDetails/MarriageNOCDetails.Section";
import orderBy from 'lodash/orderBy';
import Table from "components/Table/Table";
import {history, pathBuilder, urlLocations} from "routes/urlLocations";
import AppTable from "containers/styled/StyledTable";
import NOCsColumns from "containers/MarriageNOC/NOCs.Columns";


const MarriageNoc = () => {
  const {formatMessage} = useIntl();
  const marriageNOCs = useSelector(getMarriageNOCsSelector) || [];
  const dispatch = useDispatch();
  const debouncedSearch = debounce(value => dispatch(searchMarriageNocAction(value)), 500);

  const nocs = partition(marriageNOCs, (item) => item.status === STATUS.PENDING || item.status === STATUS.READY || item.status === STATUS.NEED_MORE_INFO)
  const prevNOCs = orderBy(nocs[1], 'handedover_at', 'desc')

  const onCreatNewClicked = () => {
    dispatch(creatNewNOCAction())
  }

  return (
    <Styled.Page>
      <StyledContainer>
        <Styled.Title>
          <FormattedMessage id={"MarriageNOC.Title"}/>
        </Styled.Title>
        <div className={"flex justify-between mt-8"}>
        <Styled.SearchField>
          <Input
            onChange={({target}) => debouncedSearch(target?.value)}
            prefix={
              <SearchOutlined style={{fontSize: 16}}/>
            }
            placeholder={formatMessage({id: "MarriageNOC.SearchPlaceholder"})}
          />
        </Styled.SearchField>
        {/*<Button onClick={onCreatNewClicked}>Create New NOC</Button>*/}
        </div>
        {nocs[0] && nocs[0].length > 0 &&
          <MarriageNocDetailsSection title={"MarriageNOC.PendingSection"}>
            <Styled.List>
              {orderBy(nocs[0], 'submitted_at', 'desc')?.map((marriageNoc) => (
                <MarriageNOCCard key={marriageNoc?.uuid}  {...marriageNoc} />
              ))}
            </Styled.List>
          </MarriageNocDetailsSection>
        }
        {nocs[1] && nocs[1].length > 0 &&
          <MarriageNocDetailsSection title={"MarriageNOC.Previous"}>
            <AppTable>
              <Table
                dataSource={prevNOCs}
                columns={NOCsColumns()}
                onRow={({ uuid }) => ({
                  onClick: () => { history.push(pathBuilder.marriageNOCDetails(uuid)) }
                }) }
              />
            </AppTable>
          </MarriageNocDetailsSection>
        }
      </StyledContainer>
    </Styled.Page>
  );
};

export default MarriageNoc;
