import React from 'react';
import {FormattedMessage} from "react-intl";
import Styled from './styled/StyledDashboard'

const DashboardItem = ({dataKey, to, disabled, access}) => {
    return (
        <Styled.Item to={to} disabled={disabled && !access}>
            <Styled.ItemIcon></Styled.ItemIcon>
            <Styled.ItemTitle>
                <FormattedMessage id={`Dashboard.${dataKey}.Title`}/>
            </Styled.ItemTitle>
            <Styled.ItemDescription>
                <FormattedMessage id={`Dashboard.${dataKey}.Description`}/>
            </Styled.ItemDescription>
            <Styled.ItemButton disabled={!to}>
                {
                    to ? <FormattedMessage id={`Dashboard.${dataKey}.Button`}/> :
                        <FormattedMessage id={`Dashboard.${dataKey}.ButtonSoon`}/>
                }
            </Styled.ItemButton>

        </Styled.Item>
    );
};

export default DashboardItem;