import styled from 'styled-components'

const StyledPhotoSection = styled.div`
  display: flex;
  gap: 16px;
`

export default StyledPhotoSection;


