import React from 'react';
import StyledButton from "components/Button/styled/StyledButton.js";
const Button = ({children, ...props}) => (
	<StyledButton {...props}>
		{children}
	</StyledButton>
);

export default Button;


