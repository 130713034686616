import axios from "axios";
import { API_PATH } from "../constants/constants";

const instance = axios.create({ baseURL: API_PATH });
const storage = localStorage.getItem("storageTyp");

export const token = window[storage]?.getItem("jwt")
  ? `Bearer ${window[storage].getItem("jwt")}`
  : "";

instance.defaults.headers.common["Authorization"] = token;

export const setupAuthorizationInterceptor = (onUnAuth) => {
  instance.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      onUnAuth();
    }
    return error;
  });
}


export default instance;
