import React from 'react';
import { FormattedMessage } from "react-intl";

import ModalWrapper from "hoc/withModal";
import { MODALS_ID } from "constants/constants";
import Button from "components/Button/Button";
import StyledButtonSubmit from "components/Button/styled/StyledButtonSubmit";
import StyledCancelButton from "components/Button/styled/StyledCanceButton";
import Styled from './styled/StyledLiturgies.Modal'
import StyledButtonDanger from "components/Button/styled/StyledDangerSubmit";


const LiturgiesModalContent = ({ onConfirm, setPopupStatus }) => {
    return (
        <Styled.Modal>
            <Styled.Title>
                <FormattedMessage id="Liturgies.Delete.Title" />
            </Styled.Title>
            <Styled.Txt>
                <FormattedMessage id="Liturgies.Delete.Desc" />
            </Styled.Txt>
            <Styled.Buttons>
                <StyledButtonDanger onClick={() => onConfirm()}>
                    <FormattedMessage id="Liturgies.Delete.Yes" />
                </StyledButtonDanger>

                <StyledCancelButton onClick={setPopupStatus.bind(null, null)}>
                    <FormattedMessage id="Liturgies.Delete.Cancel" />
                </StyledCancelButton>

            </Styled.Buttons>


        </Styled.Modal>
    )
};

const LiturgiesModal = ({ onConfirm }) => {
    return (
        <ModalWrapper
            modalId={MODALS_ID.LITURGY_DELETE_MODAL}
            component={LiturgiesModalContent}
            onConfirm={onConfirm}
        />
    );
};

export default LiturgiesModal;
