import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import {Avatar} from 'antd';
import { Menu } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";

import { urlLocations } from "routes/urlLocations";
import Styled from './styled/StyledMembers.Columns';
import { deleteMemberAction } from "services/members/members.action";


const DropDownMenu = ({ id }) => {
    const dispatch = useDispatch();
    return (
        <Menu>
            <Styled.MenuItem>
               <Link to={`${urlLocations.member}/${id}`}>
                   <FormattedMessage id="Members.EditInfo" />
               </Link>
            </Styled.MenuItem>
            <Styled.MenuItem>
                <span onClick={(event) => {
                    event.stopPropagation();
                    dispatch(deleteMemberAction(id))
                }} >
                    <FormattedMessage id="Members.Remove" />
                </span>
            </Styled.MenuItem>
        </Menu>
    )
};

const MembersColumns = () => [
    {
        dataIndex: 'id',
        title: <FormattedMessage id="Members.Table.ID" />,
        key: 'id',
        sorter: true,
    },
    {
        dataIndex: 'picture',
        title: <FormattedMessage id="Members.Table.Picture" />,
        key: 'picture',
        render: (_, { profilePic}) => profilePic ?
            <Styled.ImgCol>
                <Styled.ImgBlock>
                    <Styled.StyledImg><img src={profilePic?.url} width={50} alt=""/></Styled.StyledImg>
                    <Styled.ZoomImg>
                        <img src={profilePic?.url} width={50} alt=""/>
                    </Styled.ZoomImg>
                </Styled.ImgBlock>
            </Styled.ImgCol>
                :
            <Styled.ImgCol>
                <Avatar size={40} shape="square"  icon={<UserOutlined />}/>
            </Styled.ImgCol>
    },
    {
        dataIndex: 'firstName',
        title: <FormattedMessage id="Members.Table.FullName" />,
        key: 'firstName',
        sorter: true,
        render: (_, { firstName, secondName, lastName }) => <span>{ firstName } { secondName } { lastName }</span>
    },
    {
        dataIndex: 'email',
        sorter: true,
        title: <FormattedMessage id="Members.Table.Email" />,
        key: 'email'
    },
    {
        dataIndex: 'mobile',
        sorter: true,
        title: <FormattedMessage id="Members.Table.Mobile" />,
        key: 'mobile'
    },
    {
        dataIndex: 'family',
        key: 'family',
        render: (_, { familyID: { id } = {} }) => (
            <Styled.ViewFamilyLink>
                <Link onClick={event => event.stopPropagation()} to={`${urlLocations.family}/${id}`} >
                    <FormattedMessage id="Members.Table.ViewFamily"/>
                </Link>
            </Styled.ViewFamilyLink>
        )
    },
    // {
    //     dataIndex: 'action',
    //     key: 'action',
    //     render: (_, { id }) => (
    //         <Dropdown
    //             arrow={true}
    //             overlay={<DropDownMenu id={id} />}
    //             placement="bottomRight"
    //         >
    //             <SmallDashOutlined />
    //         </Dropdown>
    //     )
    // },
];

export default MembersColumns;