import styled from "styled-components";
import {DEVICE} from "constants/media";

const Filter = styled.div`
  width: 448px;
  && {
    .ant-picker {
       max-width: 448px;
    }
  }
    
    @media ${DEVICE.mobileDevices} {
        width: 100%;
    }
`;

export default {
  Filter,
};
