import { put, takeEvery, call } from "redux-saga/effects";

import openNotification from "../../utils/notifications";
import { SET_ERRORS } from "../errors/errors.action";
import { resendEmailRequest } from "./resendEmail.api";
import {
  RESEND_CONFIRMATION_EMAIL,
  RESEND_CONFIRMATION_EMAIL_FAILURE,
  RESEND_CONFIRMATION_EMAIL_SUCCESS
} from "./resendEmail.action";
import { SET_MODAL_STATUS } from "../modals/modals.action";
import { MODALS_ID } from "../../constants/constants";

function* resendEmail({ payload: { email } }) {
  try {
    const lowerCaseEmail = email.trim().toLowerCase();
    const { response } = yield call(resendEmailRequest, {
      email: lowerCaseEmail
    });

    if (response.status === 200) {
      yield put({
        type: RESEND_CONFIRMATION_EMAIL_SUCCESS
      });
      yield openNotification({
        type: "success",
        message: "ResendEmail.Notification.Success",
        description: "ResendEmail.Notification.SuccessMessage"
      });
      yield put({
        type: SET_MODAL_STATUS,
        payload: null
      });
    } else {
      yield put({
        type: RESEND_CONFIRMATION_EMAIL_FAILURE
      });
      yield openNotification({
        type: "error",
        message: "ResendEmail.Notification.Error",
        description: "ResendEmail.Notification.ErrorMessage"
      });

      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
    }
  } catch (error) {
    yield put({ type: RESEND_CONFIRMATION_EMAIL_FAILURE });
    console.log(error);
  }
}

export default function* resendEmailSaga() {
  yield takeEvery(RESEND_CONFIRMATION_EMAIL, resendEmail);
}
