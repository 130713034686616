import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 32px;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: var(--dark);
`;

const MembersCount = styled.p`
  font-family: var(--fontProximaRegular);
  padding: 0;
  margin: 8px 0 0 2px;
  font-size: 16px;
  line-height: 24px;
  color: #a4abc4;
`;

const ViewType = styled.div`
  position: absolute;
  width: 245px;
  height: 40px;
  top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  right: 0;
  border: 1px solid #d6e1e9;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 5px;
`;

const View = styled(Link)`
  padding: 4px 7px;

  border-radius: 8px;
  font-family: var(--fontProximaSemi);
  font-size: 13px;
  height: 32px;
  line-height: 16px;
  color: var(--dark);
  width: 100%;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: var(--dark);
  }

  svg g {
    stroke: var(--dark);
  }
  svg path {
    fill: var(--dark);
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        color: #fff;
        height: 32px;
        background: linear-gradient(70.51deg, #4164da 16.8%, #587cf4 93.54%);
        box-shadow: 0px 10px 20px rgba(66, 101, 218, 0.2);
        svg g {
          stroke: #fff;
        }
        svg path {
          fill: #fff;
        }

        &:hover {
          color: #fff;
        }
      `;
    }
  }}} i {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

const HeaderTitleCol = styled.div`
  width: 30%;
  max-width: 250px;
`;

const HeaderSearchCol = styled.div`
  width: calc(100% - 30%);
`;

export default {
  Header,
  HeaderTitleCol,
  HeaderSearchCol,
  Title,
  ViewType,
  View,
  MembersCount
};
