import { createAction } from "redux-actions";

export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAILURE = "GET_MEMBERS_FAILURE";

export const GET_MEMBER = "GET_MEMBER";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_FAILURE = "GET_MEMBER_FAILURE";

export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILURE = "DELETE_MEMBER_FAILURE";

export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "UPDATE_MEMBER_FAILURE";

export const GET_MEMBERS_COUNT = "GET_MEMBERS_COUNT";
export const GET_MEMBERS_COUNT_SUCCESS = "GET_MEMBERS_COUNT_SUCCESS";
export const GET_MEMBERS_COUNT_FAILURE = "GET_MEMBERS_COUNT_FAILURE";

export const getMembersAction = createAction(GET_MEMBERS);
export const updateMembersAction = createAction(UPDATE_MEMBER);
export const deleteMemberAction = createAction(DELETE_MEMBER);
