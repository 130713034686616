import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

const Page = styled.div`
  padding: 80px 0;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  color: var(--dark);
  padding: 0 40px 0 0;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 40px 0;
`;

const Card = styled(Link)`
  max-width: 210px;
  border: 1px solid #D6E1E9;
  box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  overflow: hidden;
  ${({disabled}) => disabled && css`
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
  `}
`;

const CardImg = styled.div`
  height: 210px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardTitle = styled.div`
  font-size: 16px;
  color: #19407D;
  padding: 24px;
  font-weight: bold;
`;


export default {
    Page,
    Title,
    List,
    Card,
    CardImg,
    CardTitle
}