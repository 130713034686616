import instance from "../root.api";

export const getContributionsRequest = () =>
    instance
        .get("/apis-admin/contributions")
        .then(response => ({response}))

export const searchContributionsRequest = (params) =>
    instance
        .get(`/apis-admin/contributions${params ? `/search?_q=${params}` : ''}`)
        .then(response => ({response}))


export const createContributionRequest = (data) =>
    instance
        .post(`/apis-admin/contributions`, data)
        .then(response => ({response}))

export const updateContributionRequest = ({id, ...data}) =>
    instance
        .put(`/apis-admin/contributions/${id}`, data)
        .then(response => ({response}))