import React from 'react';
import { useSelector } from "react-redux";
import {FormattedMessage} from "react-intl";
import moment from 'moment';
import {Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { memberSelector } from "services/members/members.selector";
import { urlLocations } from "routes/urlLocations";
import MemberForms from "./Member.Forms/Member.Forms";
import MemberFamily from "./Member.Family/Member.Family";
import Styled from './styled/StyledMember';
import StyledContainer from "components/styled/StyledContainer";
import GoBack from 'containers/styled/StyledGoBack'

const Member = ({ match: { params } = {} }) => {

    const member = useSelector(memberSelector);
    const { firstName, secondName, lastName, profilePic, dateOfBirth } = member;
    const years = moment().diff(dateOfBirth, 'years');
    return (
        <Styled.MemberPage>
            <StyledContainer>
                <GoBack.Block>
                    <GoBack.Button to={urlLocations.membersTable} >
                        <FormattedMessage id="Members.BackButton" />
                    </GoBack.Button>
                </GoBack.Block>

                <Styled.MemberBlock>
                    <Styled.MemberPicture>
                        { (profilePic) ?
                            <img src={profilePic?.url} width={88} alt=""/> :
                            <Avatar shape="square" size={88} icon={<UserOutlined />} />
                        }
                    </Styled.MemberPicture>

                    <Styled.MemberTitle>
                        <Styled.MemberName>
                            { firstName } { secondName } { lastName }
                        </Styled.MemberName>
                        <Styled.MemberYearsOld>
                            { dateOfBirth && <FormattedMessage id="Member.YearsOld" values={{ count: years }} /> }
                        </Styled.MemberYearsOld>
                    </Styled.MemberTitle>
                </Styled.MemberBlock>

                <MemberForms member={member} />
                <MemberFamily memberId={params?.id} />
            </StyledContainer>
        </Styled.MemberPage>
    );
};

export default Member;