import {STATUS} from "constants/status";
import {API_PATH} from "constants/constants";

const lang = localStorage.getItem("lang");

export const getLocalizedKey = filedName =>
  lang === "en" || !lang ? filedName : `${filedName}Ar`;

export const isNOCEditable = (marriageNOC) => {
  return marriageNOC?.status === STATUS.PENDING || marriageNOC?.status === STATUS.NEED_MORE_INFO;
}

export const openNOCtoPrint = (marriageNOC) => {
  window.open(`${API_PATH}/marriage-nocs/${marriageNOC?.uuid}/pdf`, "_blank") //to open new page
  // http://54.228.176.110:1339/marriage-nocs/ae4fa3bb-ddc5-46a6-ae18-bf57f2e41ed5/print
  // http://54.228.176.110:1339/marriage-nocs/ae4fa3bb-ddc5-46a6-ae18-bf57f2e41ed5/pdf
}

export const disableFutureDate = current =>
  current && current.valueOf() > Date.now();
