import { put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import { CLEAR_ERRORS } from "./errors.action";

function* clearErrorsOnLocationChangeData() {
  try {
    const errors = yield select(state => state.errors);
    if (!isEmpty(errors)) yield put({ type: CLEAR_ERRORS });
  } catch (error) {
    console.log(error);
  }
}

export default function* errorsSaga() {
  yield takeEvery(LOCATION_CHANGE, clearErrorsOnLocationChangeData);
}
