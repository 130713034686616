export default {
    errors: {},
    files: {},
    modals: null,
    fileUploader: {},
    appInfo: {},
    areas: {},
    filters: {},
    loader: {},
    profile: {},
    marriageNoc: {
        details: {},
        related:{}
    },
    contributions: null,
    liturgies: {
        list: [],
        liturgy: null,
        count: null,
        bookings: [],
    },
    members: {
        list: [],
        count: null,
        member: {}
    },
    churches: {
        current: null
    },
    family: {}
};
