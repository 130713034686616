import { call, put, takeEvery, select } from "redux-saga/effects";
import {createMatchSelector, LOCATION_CHANGE} from "connected-react-router";
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import get from 'lodash/get';

import {
  GET_LITURGIES,
  GET_LITURGIES_SUCCESS,
  GET_LITURGIES_FAILURE,
  GET_LITURGY,
  GET_LITURGY_FAILURE,
  GET_LITURGY_SUCCESS,
  GET_LITURGY_BOOKINGS,
  GET_LITURGY_BOOKINGS_FAILURE,
  GET_LITURGY_BOOKINGS_SUCCESS,
  CREATE_LITURGY_SUCCESS,
  CREATE_LITURGY_FAILURE,
  CREATE_LITURGY,
  UPDATE_LITURGY_SUCCESS,
  UPDATE_LITURGY,
  UPDATE_LITURGY_FAILURE,
  DELETE_LITURGY,
  DELETE_LITURGY_FAILURE,
  DELETE_LITURGY_SUCCESS,
  GET_LITURGIES_COUNT,
  GET_LITURGIES_COUNT_FAILURE,
  GET_LITURGIES_COUNT_SUCCESS
} from "./liturgy.action";

import {
  getLiturgyRequest,
  getLiturgiesRequest,
  createLiturgiesRequest,
  updateLiturgiesRequest,
  deleteLiturgiesRequest,
  getLiturgiesCountRequest,
  getLiturgyBookingsRequest
} from "./liturgy.api";
import { isUrlMatch } from "../router/router.utils";
import { history, urlLocations } from "routes/urlLocations";
import {SET_FILTERS} from "services/filters/filters.action";
import {SET_MODAL_STATUS} from "services/modals/modals.action";

function* getLiturgiesOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.liturgies)) {
      yield put({
        type: GET_LITURGIES
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLiturgiesCountOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.liturgies)) {
      yield put({
        type: GET_LITURGIES_COUNT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLiturgyOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.liturgyInfo)) {
      const getMatch = createMatchSelector(urlLocations.liturgyInfo);
      const state = yield select(state => state);
      const id = get(getMatch(state), "params.id");

      yield !!id && put({
        type: GET_LITURGY,
        payload: id
      });

      yield !!id && put({
        type: GET_LITURGY_BOOKINGS,
        payload: id
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* clearLiturgyOnLocationChange() {
  try {
    yield put({
      type: GET_LITURGY_SUCCESS,
      payload: null
    });
  } catch (error) {
    console.log(error);
  }
}

function* fetchLiturgies({ payload }) {
  try {
    const params = payload
        ? payload
        : yield select(state => state.filters.liturgies);
    const filteredParams = pickBy(params);

    const { response } = yield call(getLiturgiesRequest, filteredParams);

    if (response.status === 200) {
      yield put({
        type: SET_FILTERS,
        payload: {
          filters: {
            ...filteredParams
          },
          name: "liturgies"
        }
      });
      yield put({
        type: GET_LITURGIES_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: GET_LITURGIES_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_LITURGIES_FAILURE });
    console.log(error);
  }
}

function* fetchLiturgiesCount({ payload }) {
  try {
    const params = payload
        ? payload
        : yield select(state => state.filters.liturgies);
    const filteredParams = pickBy(params);

    const { response } = yield call(getLiturgiesCountRequest, {
      ...filteredParams,
      ...(!filteredParams?._limit && { _limit: 50 }),
      ...(!filteredParams?._start && { _start: 0 })
    });

    if (response.status === 200) {
      yield put({
        type: SET_FILTERS,
        payload: {
          filters: {
            ...filteredParams,
            ...(!filteredParams?._limit && { _limit: 50 }),
            ...(!filteredParams?._start && { _start: 0 })
          },
          name: "liturgies"
        }
      });
      yield put({
        type: GET_LITURGIES_COUNT_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: GET_LITURGIES_COUNT_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_LITURGIES_COUNT_FAILURE });
    console.log(error);
  }
}

function* fetchLiturgy({ payload }) {
  try {
    const { response } = yield call(getLiturgyRequest, payload);

    if (response.status === 200) {
      yield put({
        type: GET_LITURGY_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: GET_LITURGY_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_LITURGY_FAILURE });
    console.log(error);
  }
}

function* fetchLiturgyBookings({ payload }) {
  try {
    const { response } = yield call(getLiturgyBookingsRequest, payload);

    if (response.status === 200) {
      yield put({
        type: GET_LITURGY_BOOKINGS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: GET_LITURGY_BOOKINGS_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_LITURGY_BOOKINGS_FAILURE });
    console.log(error);
  }
}

function* createLiturgy({ payload }) {
  try {
    const { response } = yield call(createLiturgiesRequest, payload);

    if (response.status === 200) {
      yield put({
        type: CREATE_LITURGY_SUCCESS
      });
      history.push(urlLocations.liturgies);
    } else {
      yield put({
        type: CREATE_LITURGY_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: CREATE_LITURGY_FAILURE });
    console.log(error);
  }
}

function* updateLiturgy({ payload }) {
  try {
    const savePayload = pick(payload,  ['id', 'title', 'titleAr', 'date', 'maxCapacity', 'bookedCount', 'eventType', 'publishDate', 'minAllowedAge', 'maxAllowedAge', 'ruleType', 'ruleWindow', 'ruleKey', 'applyCanceledPenalty', 'exclusive'])
    const { response } = yield call(updateLiturgiesRequest, savePayload);

    if (response.status === 200) {
      yield put({
        type: UPDATE_LITURGY_SUCCESS
      });
      history.push(urlLocations.liturgies);
    } else {
      yield put({
        type: UPDATE_LITURGY_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_LITURGY_FAILURE });
    console.log(error);
  }
}

function* deleteLiturgy({ payload }) {
  try {
    const { response } = yield call(deleteLiturgiesRequest, payload);

    if (response.status === 200) {
      yield put({
        type: DELETE_LITURGY_SUCCESS
      });
      yield put({
        type: GET_LITURGIES
      });
      yield put({
        type: SET_MODAL_STATUS,
        payload: null
      });
    } else {
      yield put({
        type: DELETE_LITURGY_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: DELETE_LITURGY_FAILURE });
    console.log(error);
  }
}

export default function* liturgiesSaga() {
  yield takeEvery(LOCATION_CHANGE, getLiturgiesOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getLiturgyOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getLiturgiesCountOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, clearLiturgyOnLocationChange);
  yield takeEvery(GET_LITURGIES, fetchLiturgies);
  yield takeEvery(GET_LITURGIES_COUNT, fetchLiturgiesCount);
  yield takeEvery(GET_LITURGY, fetchLiturgy);
  yield takeEvery(GET_LITURGY_BOOKINGS, fetchLiturgyBookings);
  yield takeEvery(CREATE_LITURGY, createLiturgy);
  yield takeEvery(UPDATE_LITURGY, updateLiturgy);
  yield takeEvery(DELETE_LITURGY, deleteLiturgy);
}
