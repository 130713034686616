import React from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {Modal} from 'antd';

import {isModalVisibleSelector} from '../services/modals/modals.selectors';
import {setModalStatusAction} from '../services/modals/modals.action';


const ModalWrapper = ({
                          setModalStatus,
                          component: Component,
                          modalId,
                          footer = null,
                          onCancel,
                          ...props
                      }) => {
    const isModalVisible = useSelector(state => isModalVisibleSelector(state, modalId));
    return (
        <Modal
            visible={isModalVisible}
            footer={footer}
            onCancel={() => {
                setModalStatus(null)
                onCancel && onCancel()
            }}
            {...props}
        >
            <Component
                {...props}
                setPopupStatus={setModalStatus}
            />
        </Modal>
    )
};

ModalWrapper.propTypes = {
    modalId: PropTypes.string.isRequired,
    component: PropTypes.elementType.isRequired
};

export default connect(
    null,
    {
        setModalStatus: setModalStatusAction,
    })(ModalWrapper);
