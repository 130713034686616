import instance from "../root.api";

export const getMembersRequest = params => instance.get(`/people`, { params });
export const getMemberRequest = id => instance.get(`/people/${id}`);
export const deleteMemberRequest = ({ id }) => instance.delete(`/people/${id}`);

export const getMembersCountRequest = params =>
  instance.get(`/people/count`, { params });

export const updateMemberRequest = ({ id, ...data }) =>
  instance.put(`/people/${id}`, data);
