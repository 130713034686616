import { GET_CURRENT_CHURCH_SUCCESS } from "./churches.actions";
import initialState from "../../store/initialState";

export default (state = initialState.churches, action) => {
  switch (action.type) {
    case GET_CURRENT_CHURCH_SUCCESS:
      return {
        ...state,
        current: action.payload
      };
    default:
      return state;
  }
};
