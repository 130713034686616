import React from 'react';
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import omit from 'lodash/omit';
import isBoolean from 'lodash/isBoolean';

import { getFilersSelector } from 'services/filters/filters.selector';
import { decodeFilters } from "./Members.Filters.utils";
import { FILTERS, operatorsToText } from 'constants/filters';
import Styled from './styled/StyledMember.Filters';
import {appInfoFathersSelector} from "services/appInfo/appInfo.selector";
import {getLocalizedKey} from "utils/utils";

const MembersFiltersView = ({ setFilters }) => {
    const appliedFilters = useSelector(state => getFilersSelector(state, 'members')) || {} ;
    const fathers = useSelector(appInfoFathersSelector);
    const { _q, _limit, _start, _sort, ...filters } = appliedFilters;
    return (
        <Styled.MembersFiltersView>

            {
                decodeFilters(filters).map(filter =>

                    <Styled.FilterView key={filter.name} >
                        <FormattedMessage id={FILTERS[filter.name]?.name} />{" "}
                        <FormattedMessage id={operatorsToText[filter.operator]} />
                        <>{
                            isBoolean(filter.value) ?
                                <>{` \n`}<FormattedMessage id={filter.value ? "Deacon.Yes" : "Deacon.No"} /></> :
                                <>{` `}{
                                    filter.name === 'fatherOfConfession' ?
                                        fathers.find( father => father?.id === filter.value)[getLocalizedKey('name')] :
                                        filter.value
                            }</>
                        }</>

                        <Styled.CloseView onClick={() => {

                            const filterName = filter.operator === "=" ? filter.name : `${filter.name}${filter.operator}`;
                            const newFilters =  omit( appliedFilters, filterName );
                            setFilters(newFilters);
                        }}>

                        </Styled.CloseView>
                </Styled.FilterView>)
            }
        </Styled.MembersFiltersView>
    );
};

export default MembersFiltersView;