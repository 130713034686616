import styled from "styled-components";

const Block = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  min-height: 224px;
`;

const EditBtn = styled.div`
  color: #fff;
`;

const BlockContent = styled.div`
  padding: 14px 24px;
`;

const BlockHeader = styled.div`
  width: 100%;
  height: 47px;
  padding: 7px 15px 8px 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  background: linear-gradient(105.76deg, #4164da, #587cf4);
`;
const BlockTitle = styled.h4`
  width: calc(100% - 50px);
  font-family: var(--fontProximaBold);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  padding: 0;
  margin: 0;
  color: #ffffff;
`;

export default {
  BlockContent,
  EditBtn,
  Block,
  BlockTitle,
  BlockHeader
};
