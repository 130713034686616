import React from 'react';
import {FormattedMessage} from "react-intl";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from 'moment';

import LiturgyForm from "./Liturgy.Form";
import StyledContainer from "components/styled/StyledContainer";
import Styled from './styled/StyledLiturgy'
import {liturgySelector, liturgyBookingsSelector} from "services/liturgy/liturgy.selector";
import StyledButtonSubmit from "components/Button/styled/StyledButtonSubmit";
import CsvDownloader from 'react-csv-downloader';
import {FaRegCopy} from "react-icons/fa6";
import {notification} from "antd";

const columns = [
  {id: 'QRCode', displayName: 'QRCode'},
  {id: 'Church', displayName: 'Church'},
  {id: 'Date', displayName: 'Date'},
  {id: 'Count', displayName: 'Count'},
  {id: 'Names', displayName: 'Names'},
  {id: 'IsAttended', displayName: 'IsAttended'},
  {id: 'MainEmail', displayName: 'MainEmail'},
  {id: 'MainMobile', displayName: 'MainMobile'},
]

const Liturgy = () => {
  const {id, action} = useParams();
  /** @type {IEvent} **/
  const liturgy = useSelector(liturgySelector);
  const bookings = useSelector(liturgyBookingsSelector);

  const liturgyLink = liturgy ? `https://www.uaecopts.com/liturgy/private/${liturgy.uuid}` : ''
  const isCopy = !!action

  const [api, contextHolder] = notification.useNotification();

  const handleCopy = () => {
    navigator.clipboard.writeText(liturgyLink)
    api.success({
      message: 'URL Successfully copied to clipboard',
      placement: 'bottomRight'
    });
  }

  console.log(bookings)
  return (
    <Styled.LiturgyPage>
      {contextHolder}
      <StyledContainer>
        {(!id || action) ?
          <Styled.TitlePage>
            <FormattedMessage id="Liturgies.AddNewPrayer"/>
          </Styled.TitlePage> :
          <Styled.TitlePage>
            <FormattedMessage id="Liturgies.UpdateExisting"/>
          </Styled.TitlePage>}
        <LiturgyForm
          initialValues={{
            ...(!!id && {
              time: liturgy?.date && moment(liturgy.date).format('hh:mm A'),
              publishTime: liturgy?.date && moment(liturgy.publishDate).format('hh:mm A'),
              ...liturgy
            })
          }}
          id={id}
          isCopy={isCopy}
        />
        {!isCopy && liturgy?.uuid &&
          <div
            className="w-full lg:w-[70%] flex flex-row justify-between gap-4 items-center text-s text-slate-400 mt-8 bg-white px-4 py-2 rounded-lg border border-solid border-slate-300">
            <div className={"break-all"}>{liturgyLink}</div>
            <button className={"p-2 bg-blue-700 rounded-md hover:bg-blue-600"} onClick={handleCopy}>
              <FaRegCopy className={"text-white"}/>
            </button>

          </div>
        }
        {(id && !action) && <CsvDownloader
          className={'mt-8'}
          columns={columns}
          datas={bookings}
          separator=","
          filename={liturgy?.title}
          suffix={moment(liturgy?.date).format("DD-MM_hh:mmA")}
          wrapColumnChar=""
        >
          <StyledButtonSubmit width="160px">
            <FormattedMessage id="Liturgies.Form.DownloadBookings"/>
          </StyledButtonSubmit>
        </CsvDownloader>
        }
      </StyledContainer>
    </Styled.LiturgyPage>
  );
};

export default Liturgy;
