import styled from "styled-components";
import { Link } from "react-router-dom";
import ArrowImg from "assets/img/Icon/ArrowBlue.svg";

const PersonPreview = styled.div`
  padding: 0 40px 0 32px;
`;

const PersonList = styled.div`
  width: 100%;
  display: flex;
  margin: 24px 0;
`;
const PersonItem = styled(Link)`
  width: 80px;
  display: inline-flex;
  margin-left: 24px;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }
`;

const Name = styled.div`
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--dark);
`;

const UserPic = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 10px auto;
  border-radius: 10px;

  .ant-avatar.ant-avatar-icon {
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
    svg {
      position: relative;
      top: 8px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
  }
`;

const Status = styled.div`
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: var(--gray);
  padding-top: 3px;
`;

const StatusMore = styled.div`
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: var(--gray);
  padding-top: 18px;
`;

const MoreBtn = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0 auto;
  box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  border: 1px solid var(--gray);
`;

const MoreTitle = styled.div`
  text-align: center;
  font-family: var(--fontProximaRegular);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #929bb9;
`;
const ExploreBtn = styled(Link)`
  width: 160px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #4265da;
  box-shadow: 0px 10px 20px rgba(66, 101, 218, 0.1);
  border-radius: 8px;
  color: #4265da;
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 12px 16px 12px 16px;
  margin-bottom: 20px;
  margin-top: 10px;
  justify-content: space-around;
  &:hover {
    color: #4265da;
  }
  &:after {
    content: "";
    display: inline-block;
    position: relative;
    left: 0;
    width: 8px;
    height: 14px;
    margin-left: 17px;
    background: url(${ArrowImg}) center center no-repeat;
    background-size: contain;
  }
`;
const ButtonRow = styled.div`
  width: 100%;
`;

export default {
  PersonItem,
  ButtonRow,
  PersonList,
  MoreTitle,
  PersonPreview,
  Name,
  StatusMore,
  UserPic,
  Status,
  ExploreBtn,
  MoreBtn
};
