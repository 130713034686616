import React from 'react';
import {FormattedMessage} from "react-intl";
import {Link} from 'react-router-dom';
import moment from 'moment';

import {getLocalizedKey} from "utils/utils";
import {RULE_TYPES} from 'constants/constants';
import {urlLocations} from "routes/urlLocations";
import Styled from './styled/StyledLiturgies.Columns';

const getLiturgiesColumns = ({onDeleteClicked}) => {
    return [
        {
            title: () => <FormattedMessage id="Liturgies.Table.Title"/>,
            dataIndex: "title",
            key: "title",
            width: '30%',
            render: (_, record) => {
                return <Styled.TitleCol> {record[getLocalizedKey('title')]} </Styled.TitleCol>
            }
        },
        {
            title: () => <FormattedMessage id="Liturgies.Table.Date"/>,
            dataIndex: "date",
            key: "date",
            render: (_, {date}) => moment(date).format('ddd DD MMM YYYY - hh:mm A')
        },
        {
            title: () => <FormattedMessage id="Liturgies.Table.MaxCapacity"/>,
            dataIndex: "maxCapacity",
            key: "maxCapacity"
        },
        {
            title: () => <FormattedMessage id="Liturgies.Table.Booked"/>,
            dataIndex: "bookedCount",
            key: "bookedCount"
        },
        {
            title: () => <FormattedMessage id="Liturgies.Table.Rule"/>,
            dataIndex: "ruleType",
            key: "ruleType",
            render: (_, {ruleType, ruleWindow, ruleKey}) => {
                return ruleType === RULE_TYPES[0] ?
                    <FormattedMessage id="Liturgies.Table.RuleTypeOpen"/> :
                    ruleType === RULE_TYPES[1] ?
                        <FormattedMessage id="Liturgies.Table.RuleTypeDay" values={{ruleWindow}}/> :
                        ruleType === RULE_TYPES[2] ?
                            <FormattedMessage id="Liturgies.Table.RuleTypeWeek" values={{ruleWindow}}/> :
                            <FormattedMessage id="Liturgies.Table.RuleTypeGroup" values={{ruleKey}}/>
            }
        },
        {
            title: () => <FormattedMessage id="Liturgies.Table.Actions"/>,
            dataIndex: "actions",
            key: "actions",
            render: (_, {id}) => <>
                <Link
                    to={`${urlLocations.liturgy}/${id}/copy`}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <Styled.CopyIcon/>
                </Link>
                <Styled.DeleteIcon
                    onClick={(event) => {
                        event.stopPropagation();
                        onDeleteClicked(id)
                    }}
                />
            </>
        },
    ]
};

export default getLiturgiesColumns;
