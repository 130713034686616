import React, {useEffect, useState} from 'react';

import Header from './Members.Header';
import Filters from './Members.Filters/Members.Filters';
import Content from './Members.Content';
import Styled from './styled/StyledMembers'
import StyledContainer from "components/styled/StyledContainer";
import {useDispatch} from "react-redux";
import {getMembersAction as getMembers} from "services/members/members.action";

const Members = ({ location }) => {
    const [ filters, setFilters ] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMembers(filters))
    }, [filters]);

    return (
        <Styled.MembersPage>
            <StyledContainer>
                <Header location={location} setFilters={setFilters} filters={filters} />
                <Filters setFilters={setFilters} filters={filters} />
                <Content setFilters={setFilters} filters={filters} />
            </StyledContainer>
        </Styled.MembersPage>
    );
};

export default Members;