import React from 'react';
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import StyledError from "components/ControlWrapper/styled/StyledError";
import StyledLabel from "components/ControlWrapper/styled/StyledLabel";

const ControlWrapper = ({ label, children, name, errors, error, customTouched, touched }) =>  (
    <>
        { label && <StyledLabel><FormattedMessage id={label} /></StyledLabel> }
        { children }
        {
            errors?.[name] && !customTouched && errors?.[name].map(error =>
                <StyledError key={error}>
	                <FormattedMessage key={error} id={ error } />
                </StyledError>
            )

        }
        {
            error && touched &&
                <StyledError key={error}>
                    <FormattedMessage key={error} id={ error } />
                </StyledError>
        }
    </>
);

export default connect(
    ({ errors }) => ({ errors }),
    {}
)(ControlWrapper)