import React from 'react';
import {Link} from "react-router-dom";

import StyledContainer from "components/styled/StyledContainer";
import Styled from './styled/StyledHeader'
import {urlLocations} from "routes/urlLocations";
import UserBlock from "layouts/AuthorizeLayouts/UserBlock/UserBlock";
import {useSelector} from "react-redux";
import {getCurrentChurchSelector} from "services/churches/churches.selector";
import {getLocalizedKey} from "utils/utils";


const Header = () => {
  const church = useSelector(getCurrentChurchSelector);

  return (
    <StyledContainer type='header'>
      <Styled.Block>
        <Styled.HeaderContent>
          <Link to={urlLocations.dashboard}>
            <Styled.Logo>
              <Styled.Image>
                <img src={church?.logo?.formats?.thumbnail?.url} alt=""/>
              </Styled.Image>
              <Styled.LogoTxt>
                <Styled.LogoTitle>
                  {church?.[getLocalizedKey('name')]}
                </Styled.LogoTitle>
                <Styled.LogoSubTitle>
                  {church?.addressLine} - {church?.emirate?.[getLocalizedKey('name')]} - UAE
                </Styled.LogoSubTitle>
              </Styled.LogoTxt>
            </Styled.Logo>
          </Link>

          <UserBlock></UserBlock>
        </Styled.HeaderContent>
      </Styled.Block>
    </StyledContainer>
  );
};

export default Header;
