import React from 'react';
import { Spin } from 'antd';

import { useSelector } from "react-redux";
import { getLoaderByName } from "services/loader/loader.selector";

const Loader = ({ children, name = 'general' }) => {
    const isLoading = !!useSelector(state => getLoaderByName(state, name));
    return (
        <Spin spinning={isLoading} >
            { children }
        </Spin>
    );
};

export default Loader;