import initialState from "../../store/initialState";
import { GET_AREAS_SUCCESS } from "./area.action";

export default (state = initialState.areas, action) => {
  switch (action.type) {
    case GET_AREAS_SUCCESS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
