import {
  GET_MARRIAGE_NOC_DETAILS_SUCCESS,
  GET_MARRIAGE_NOC_SUCCESS, RELATED_MARRIAGE_NOC_SUCCESS,
  SEARCH_MARRIAGE_NOC_SUCCESS
} from "./marriageNoc.actions";
import initialState from "../../store/initialState";

export default (state = initialState.marriageNoc, action) => {
  switch (action.type) {
    case GET_MARRIAGE_NOC_SUCCESS:
      const details = {}
      action.payload.forEach(noc => details[noc.uuid] = noc)
      return {
        ...state,
        details
      }
    case SEARCH_MARRIAGE_NOC_SUCCESS:
      const results = {}
      action.payload.forEach(noc => results[noc.uuid] = noc)
      return {
        ...state,
        details: results
      }
    case GET_MARRIAGE_NOC_DETAILS_SUCCESS:
      const noc = action.payload
      return {
        ...state,
        details: {
          ... state.details,
          [noc.uuid]: noc
        }
      };
    case RELATED_MARRIAGE_NOC_SUCCESS:
      const {uuid, related} = action.payload
      return {
        ...state,
        related: {
          ... state.related,
          [uuid]: related
        }
      };
    default:
      return state;
  }
};
