import React, {useState,useEffect} from 'react';
import { FormOutlined } from '@ant-design/icons'
import { Select } from "antd";
import {FormattedMessage} from "react-intl";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import SelectField from "components/SelectField/SelectField";
import InputField from "components/InputField/InputField";
import {appInfoFathersSelector, appInfoWorkingFieldsSelector} from "services/appInfo/appInfo.selector";
import { getLocalizedKey } from 'utils/utils';
import {DEACON_RADIO, FAMILY_STATUSES} from 'constants/constants';
import RadioField from "components/RadioField/RadioField";
import Styled from "containers/Member/Member.Forms/styled/StyledMember.Form";
import {updateMembersAction} from "services/members/members.action";
import Loader from "components/Loader/Loader";
import Cancel from "components/Button/styled/StyledCancel";
import Save from "components/Button/styled/StyledSave";


const { Option } = Select;

const MemberFormsAdditional = ({ handleSubmit, formName, member }) => {

    const [ disabled, setDisableStatus ] = useState(true);
    const workingFields = useSelector(appInfoWorkingFieldsSelector);
    const fathersOfConfession = useSelector(appInfoFathersSelector);

    const toggleDisable = () => {
        setDisableStatus(!disabled)
    };

    useEffect(() => {
        setDisableStatus(true);
    }, [member]);

    return (
        <Styled.ColLeft>
            <Styled.FormBlock height='316px;'>
                <Loader name={formName} >
                    <form onSubmit={handleSubmit}>
                        <Styled.FormHeader>
                            <Styled.HeaderTitle>
                                <FormattedMessage id="Member.Form.Additional" />
                            </Styled.HeaderTitle>
                            <Styled.EditBtn>
                                <FormOutlined onClick={toggleDisable} />
                            </Styled.EditBtn>
                        </Styled.FormHeader>

                        <Styled.FormContent>
                            <Styled.Field>
                                <Styled.Col33>
                                    <SelectField
                                        disabled={disabled}
                                        name="status"
                                        label="Member.Form.SocialStatus"
                                        placeholder="Member.Form.SocialStatus"
                                    >
                                        {
                                            FAMILY_STATUSES.map(status => (
                                                <Option key={status.value} value={status.value} >
                                                    <FormattedMessage id={status.label} />
                                                </Option>
                                            ))
                                        }
                                    </SelectField>
                                </Styled.Col33>
                                <Styled.Col33>
                                    <SelectField
                                        disabled={disabled}
                                        name="workingField"
                                        label="Member.Form.WorkingField"
                                        placeholder="Member.Form.WorkingField"
                                    >
                                        {
                                            workingFields?.map(field => (
                                                <Option key={field.id} value={field.id} >
                                                    { field[getLocalizedKey('name')] }
                                                </Option>
                                            ))
                                        }
                                    </SelectField>
                                </Styled.Col33>
                                <Styled.Col33>
                                </Styled.Col33>
                            </Styled.Field>

                            <Styled.Field>
                                <Styled.Col33>
                                    <SelectField
                                        disabled={disabled}
                                        name="fatherOfConfession"
                                        label="Member.Form.FatherOfConfession"
                                        placeholder="Member.Form.FatherOfConfession"
                                    >
                                        {
                                            fathersOfConfession?.map(field => (
                                                <Option key={field.id} value={field.id} >
                                                    { field[getLocalizedKey('name')] }
                                                </Option>
                                            ))
                                        }
                                    </SelectField>
                                </Styled.Col33>
                                <Styled.Col33>
                                    <InputField
                                        disabled={disabled}
                                        name="churchService"
                                        label="Member.Form.Services"
                                        placeholder="Member.Form.Services"
                                    />
                                </Styled.Col33>
                                <Styled.Col33>
                                    <RadioField
                                        label="Member.Form.Deacon"
                                        name="deacon"
                                        radioSet={DEACON_RADIO}
                                    />
                                </Styled.Col33>
                            </Styled.Field>

                            { !disabled &&  <Styled.ButtonsRow>
                                <Cancel onClick={setDisableStatus.bind(null, true)} >
                                    <FormattedMessage id="Member.Form.Cancel" />
                                </Cancel>
                                <Save htmlType="submit">
                                    <FormattedMessage id="Member.Form.Save" />
                                </Save>
                            </Styled.ButtonsRow> }

                        </Styled.FormContent>
                    </form>
                </Loader>
            </Styled.FormBlock>
        </Styled.ColLeft>
    );
};

export default reduxForm({
    form: "additionalForm",
    enableReinitialize: true,
    onSubmit: (value, dispatch, { member, formName }) => {
        dispatch(
            updateMembersAction({
                ...value, formName, id: member?.id
            })
        )
    }
})(MemberFormsAdditional);