import React from 'react';
import  { useSelector } from "react-redux";
import { getCurrentChurchSelector } from "services/churches/churches.selector";

import Styled from './styled/StyledDashboard'
import DashboardList from "./Dashboard.List";
import {getLocalizedKey} from "utils/utils";

const Dashboard = () => {
    const church = useSelector(getCurrentChurchSelector);
    return (
        <Styled.Block >
            <Styled.Header>
                <Styled.Content>
                    <Styled.Logo>
                        <img src={church?.logo?.formats?.thumbnail?.url} alt=""/>
                    </Styled.Logo>
                    <Styled.Title>
                        { church?.[getLocalizedKey('name')] }
                    </Styled.Title>
                    <Styled.Subtitle>
                        { church?.addressLine } - { church?.emirate?.[getLocalizedKey('name')] } - UAE
                    </Styled.Subtitle>
                </Styled.Content>
                <Styled.Bg bodyBg={church?.bannerCover?.url} />
            </Styled.Header>
            <DashboardList/>
        </Styled.Block>
    );
};

export default Dashboard;