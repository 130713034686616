import React from 'react';

import MemberFormsPersonal from './Member.Forms.Personal';
import MemberFormsContact from './Member.Forms.Contact';
import MemberFormsAdditional from "./Member.Forms.Additional";
import MemberFormsAddress from "./Member.Forms.Address";
import Styled from './styled/StyledMember.Form';
import {FORMS_NAMES} from "constants/constants";


const MemberForms = ({ member }) => {

    return (
        <>
            <Styled.Row>
                <MemberFormsPersonal
                    member={member}
                    formName={FORMS_NAMES.MemberPersonalForm}
                    initialValues={{
                        firstName: member?.firstName,
                        secondName: member?.secondName,
                        lastName: member?.lastName,
                        gender: member?.gender,
                        dateOfBirth: member?.dateOfBirth

                    }}
                />
                <MemberFormsContact
                    member={member}
                    formName={FORMS_NAMES.MemberContactForm}
                    initialValues={{
                        email: member?.email,
                        mobile: member?.mobile,
                        whatsapp: member?.whatsapp,
                    }}
                />
            </Styled.Row>

            <Styled.Row>
                <MemberFormsAdditional
                    member={member}
                    formName={FORMS_NAMES.MemberAdditionalForm}
                    initialValues={{
                        status: member?.status,
                        workingField: member?.workingField?.id,
                        fatherOfConfession: member?.fatherOfConfession?.id,
                        churchService: member?.churchService,
                        deacon: member?.deacon
                    }}
                />
                <MemberFormsAddress
                    member={member}
                    formName={FORMS_NAMES.MemberAddressForm}
                    initialValues={{
                        emirate: {
                            id : member?.familyID?.emirate
                        },
                        address: {
                            ...member?.familyID?.address,
                            area: parseInt(member?.familyID?.address?.area),
                        }
                    }}
                />
            </Styled.Row>
        </>
    );
};

export default MemberForms
