import styled from "styled-components";

const AppTable = styled.div`
    margin-top: 24px;

    .ant-table-thead > tr > th {
        color: rgba(0, 0, 0, 0.85);
        font-family: var(--fontProximaBold);
        font-size: 11px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #929bb9;
        text-align: left;
        background: #dfe4ed;
        border-bottom: 1px solid #dfe4ed;
        -webkit-transition: background 0.3s ease;
        transition: background 0.3s ease;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 10px;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 10px;
    }

    .ant-table table {
        border-radius: 10px 10px 0 0;
        background: #f8f9fb;
        border: 1px dashed #d6e1e9;
        box-sizing: border-box;
        border-radius: 10px;
    }

    //

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #e9edf0;
        transition: background 0.3s;
        background: #f8f9fb;
        align-content: center;
        font-family: var(--fontProximaRegular);
        font-size: 16px;
        line-height: 24px;
        position: relative;
        cursor: pointer;
        color: #27282a;

        a {
            font-family: var(--fontProximaRegular);
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #587cf4;
        }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: #f0f3f8;
    }

    .ant-table-tbody > tr.ant-table-row:last-of-type td:first-child {
        border-radius: 10px;
    }

    .ant-table-tbody > tr.ant-table-row:last-of-type td:last-child {
        border-radius: 10px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td:first-child {
        &:after {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            left: -1px;
            top: 0;
            background: #25b1ff;
        }
    }
`;

export default AppTable;
