import React from 'react';
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {Spin} from "antd";

import MarriageNocDetailsPersonalInfo from "containers/MarriageNOCDetails/MarriageNOCDetails.PersonalInfo";
import MarriageNocDetailsPreviousEngagement from "containers/MarriageNOCDetails/MarriageNOCDetails.PreviousEngagement";
import MarriageNocDetailsPreviousMarriage from "containers/MarriageNOCDetails/MarriageNOCDetails.PreviousMarriage";
import MarriageNOCDetailsDivorcement from "containers/MarriageNOCDetails/MarriageNOCDetails.Divorcement";
import MarriageNOCDetailsWitnesses from "containers/MarriageNOCDetails/MarriageNOCDetails.Witnesses";
import {updateMarriageNocAction} from "services/marriageNoc/marriageNoc.actions";
import Button from "components/Button/Button";
import {useSelector} from "react-redux";
import {getLoaderByName} from "services/loader/loader.selector";
import MarriageNocDetailsDocuments from "containers/MarriageNOCDetails/MarriageNOCDetails.Documents";
import {FORMS_NAMES, LOADERS} from "constants/constants";
import {isNOCEditable} from "utils/utils";

const MarriageNocDetailsForm = ({handleSubmit, marriageNOC}) => {
    const isLoading = useSelector(state => getLoaderByName(state, LOADERS.MARRIAGE_NOC))
    const isEditable = isNOCEditable(marriageNOC);

    return (
        <Spin spinning={!!isLoading}>
            <form onSubmit={handleSubmit}>
                <MarriageNocDetailsPersonalInfo disabled={!isEditable}/>
                <MarriageNocDetailsPreviousEngagement disabled={!isEditable}/>
                <MarriageNocDetailsPreviousMarriage disabled={!isEditable}/>
                <MarriageNOCDetailsDivorcement disabled={!isEditable}/>
                <MarriageNOCDetailsWitnesses disabled={!isEditable}/>
                <MarriageNocDetailsDocuments disabled={!isEditable}/>
                <Button htmlType={'submit'}>
                    <FormattedMessage id={"MarriageNOCDetails.UpdateInformation"}/>
                </Button>
            </form>
        </Spin>
    );
};

export default reduxForm({
    form: FORMS_NAMES.MARRIAGE_NOC,
    enableReinitialize: true,
    destroyOnUnmount: true,
    onSubmit: (value, dispatch) => dispatch(updateMarriageNocAction(value))
})(MarriageNocDetailsForm);
