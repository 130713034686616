import {isUrlMatch} from "services/router/router.utils";
import {history, pathBuilder, urlLocations} from "routes/urlLocations";
import {call, put, takeEvery, select} from "redux-saga/effects";
import get from "lodash/get";
import * as marriageNocActions from "services/marriageNoc/marriageNoc.actions";
import {createMatchSelector, LOCATION_CHANGE} from "connected-react-router";
import {
  getMarriageNocsRequest,
  searchMarriageNocsRequest,
  updateMarriageNocStatusRequest,
  updateMarriageNocRequest, findRelatedMarriageNocRequest, getMarriageNocDetailsRequest, creatMarriageNocsRequest
} from "services/marriageNoc/marriageNoc.api";
import openNotification from "utils/notifications";
import {SET_LOADER_STATUS} from "services/loader/loader.action";
import {FORMS_NAMES, LOADERS} from "constants/constants";
import {prepareFilesDataForSave} from "services/file/file.utils";
import {CREATE_MARRIAGE_NOC} from "services/marriageNoc/marriageNoc.actions";


function* getMarriageNOCOnLocationChange({payload}) {
  try {
    if (isUrlMatch(payload, urlLocations.marriageNOC)) {
      yield put({type: marriageNocActions.GET_MARRIAGE_NOC});
    }
  } catch (error) {
    console.log(error);
  }
}

function* getMarriageNOCDetailsOnLocationChange({payload}) {
  try {
    if (isUrlMatch(payload, urlLocations.marriageNOCDetails)) {
      const getMatch = createMatchSelector(urlLocations.marriageNOCDetails);
      const state = yield select(state => state);
      const uuid = get(getMatch(state), "params.uuid");
      console.log(uuid)
      yield !!uuid && put({ type: marriageNocActions.GET_MARRIAGE_NOC_DETAILS, payload: {uuid} });
    }
  } catch (error) {
    console.log(error);
  }
}

function* creatMarriageNOC() {
  try {

    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.MARRIAGE_NOC,
        isLoading: true
      }
    });

    const {response} = yield call(creatMarriageNocsRequest);

    if (response.status < 300) {
      yield put({
        type: marriageNocActions.CREATE_MARRIAGE_NOC_SUCCESS,
        payload: response.data
      });

      yield call(openNotification, {
        message: "Notification.Success",
        description: "MarriageNOCDetails.CreatSuccess",
        type: "success"
      });

      history.push(pathBuilder.marriageNOCDetails(response.data.uuid));
    } else {
      yield put({type: marriageNocActions.CREATE_MARRIAGE_NOC_FAILURE});

      yield call(openNotification, {
        message: "Notification.Error",
        description: "MarriageNOCDetails.CreatFailed",
        type: "error"
      });
    }
  } catch (error) {
    yield put({type: marriageNocActions.CREATE_MARRIAGE_NOC_FAILURE});

    yield call(openNotification, {
      message: "Notification.Error",
      description: "MarriageNOCDetails.CreatFailed",
      type: "error"
    });

    console.log(error);
  } finally {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.MARRIAGE_NOC,
        isLoading: false
      }
    });
  }
}
function* fetchMarriageNOC() {
  try {
    const {response} = yield call(getMarriageNocsRequest);

    yield put({
      type: marriageNocActions.GET_MARRIAGE_NOC_SUCCESS,
      payload: response.data
    });

  } catch (error) {
    yield put({type: marriageNocActions.GET_MARRIAGE_NOC_FAILURE});
    console.log(error);
  }
}

function* relatedMarriageNoc({payload}) {

  try {
    const {response} = yield call(findRelatedMarriageNocRequest, payload);

    yield put({
      type: marriageNocActions.RELATED_MARRIAGE_NOC_SUCCESS,
      payload: {uuid: payload.uuid, related: response.data}
    });

  } catch (error) {
    yield put({type: marriageNocActions.RELATED_MARRIAGE_NOC_FAILURE});
    console.log(error);
  }
}

function* getMarriageNOCDetails({payload}) {

  try {
    const {response} = yield call(getMarriageNocDetailsRequest, payload);

    yield put({
      type: marriageNocActions.GET_MARRIAGE_NOC_DETAILS_SUCCESS,
      payload: response.data
    });

  } catch (error) {
    yield put({type: marriageNocActions.GET_MARRIAGE_NOC_DETAILS_FAILURE});
    console.log(error);
  }
}

function* searchMarriageNOC({payload}) {
  try {
    const {response} = yield call(searchMarriageNocsRequest, payload);

    yield put({
      type: marriageNocActions.SEARCH_MARRIAGE_NOC_SUCCESS,
      payload: response.data
    });

  } catch (error) {
    yield put({
      type: marriageNocActions.SEARCH_MARRIAGE_NOC_FAILURE
    });
    console.log(error);
  }
}

function* updateMarriageNOCStatus({payload}) {
  try {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.MARRIAGE_NOC_STATUS,
        isLoading: true
      }
    });

    const {response} = yield call(updateMarriageNocStatusRequest, payload);

    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_STATUS_SUCCESS,
      payload: response.data
    });

    yield put({
      type: marriageNocActions.GET_MARRIAGE_NOC,
    });

    yield call(openNotification, {
      message: "MarriageNOCDetails.StatusUpdateSuccess",
      description: "MarriageNOCDetails.StatusUpdateSuccess",
      type: "success"
    });


  } catch (error) {
    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_STATUS_FAILURE
    });
    console.log(error);
  } finally {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.MARRIAGE_NOC_STATUS,
        isLoading: false
      }
    });
  }
}

function* updateMarriageNOC({payload}) {
  try {

    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.MARRIAGE_NOC,
        isLoading: true
      }
    });

    const files = yield select(state => state.files);
    const prepareFiles = prepareFilesDataForSave(files[FORMS_NAMES.MARRIAGE_NOC]);

    const {response} = yield call(updateMarriageNocRequest, {...payload, ...prepareFiles});

    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_SUCCESS,
      payload: response.data
    });

    yield call(openNotification, {
      message: "MarriageNOCDetails.DetailsUpdateSuccess",
      description: "MarriageNOCDetails.DetailsUpdateSuccess",
      type: "success"
    });

    yield put({
      type: marriageNocActions.GET_MARRIAGE_NOC,
    });

  } catch (error) {
    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_FAILURE
    });
    console.log(error);
  } finally {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: LOADERS.MARRIAGE_NOC,
        isLoading: false
      }
    });
  }
}

function* updateMarriageNOCFiles({payload}) {
  try {
    const {response} = yield call(updateMarriageNocRequest, payload);

    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_FILES_SUCCESS,
      payload: response.data
    });


  } catch (error) {
    yield put({
      type: marriageNocActions.UPDATE_MARRIAGE_NOC_FILES_FAILURE
    });
    console.log(error);
  }
}

export default function* marriageNocSaga() {
  yield takeEvery(LOCATION_CHANGE, getMarriageNOCOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getMarriageNOCDetailsOnLocationChange);
  yield takeEvery(marriageNocActions.CREATE_MARRIAGE_NOC, creatMarriageNOC);
  yield takeEvery(marriageNocActions.GET_MARRIAGE_NOC, fetchMarriageNOC);
  yield takeEvery(marriageNocActions.GET_MARRIAGE_NOC_DETAILS, getMarriageNOCDetails);
  yield takeEvery(marriageNocActions.SEARCH_MARRIAGE_NOC, searchMarriageNOC);
  yield takeEvery(marriageNocActions.UPDATE_MARRIAGE_NOC_STATUS, updateMarriageNOCStatus);
  yield takeEvery(marriageNocActions.UPDATE_MARRIAGE_NOC, updateMarriageNOC);
  yield takeEvery(marriageNocActions.UPDATE_MARRIAGE_NOC_FILES, updateMarriageNOCFiles);
  yield takeEvery(marriageNocActions.RELATED_MARRIAGE_NOC, relatedMarriageNoc);
}
