import {
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_COUNT_SUCCESS,
  GET_MEMBER_SUCCESS
} from "./members.action";
import initialState from "store/initialState";

export default (state = initialState.members, action) => {
  switch (action.type) {
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case GET_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.payload
      };
    case GET_MEMBERS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};
