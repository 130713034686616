import React from 'react';
import { reduxForm, FieldArray } from "redux-form";
import { FormattedMessage } from "react-intl";
import MembersFiltersFields from './Members.Filters.Fields';
import { filtersValidation, prepareFilters } from './Members.Filters.utils';
import Styled from './styled/StyledMember.Filters'
import Apply from "components/Button/styled/StyledApply";


const MembersFiltersList = ({ handleSubmit, filters }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Styled.MembersFiltersList>
                <FieldArray
                    filters={filters}
                    component={MembersFiltersFields}
                    name="filter"
                />
                <Apply htmlType="submit" >
                    <FormattedMessage id="Filters.Apply" />
                </Apply>
            </Styled.MembersFiltersList>
        </form>
    );
};

export default reduxForm({
    form: "membersFilters",
    validate: filtersValidation,
    enableReinitialize: true,
    onSubmit: ({ filter }, _, { setFilters, filters }) => {
        setFilters({
        ...( filters?._q && { q: filters._q }),
        ...prepareFilters(filter)
        });
    }
})(MembersFiltersList);