import styled from "styled-components";

const StyledPageWrapSubTitle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 38px;
  margin-bottom: 5px;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 14px;
    width: 100%;
    height: 1px;
    background: #d6e1e9;
  }
`;
export default StyledPageWrapSubTitle;
