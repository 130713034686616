import React from "react";
import styled, { css } from "styled-components";
import {Link} from "react-router-dom";

const StyledCancelLink = styled(Link)`
  && {
    display: inline-flex;
    justify-content: center;
    align-items: center;
     height:${({height}) => height ? height : "40px"};
     width: ${({width}) => width ? width : "130px"};
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
    background: var(--white);
    font-family: var(--fontProximaRegular);
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    transition: all 0.3s ease-in;
    border: 1px solid var(--hrefColorSecondary);
    color: var(--hrefColorSecondary);

    &:hover {
      box-shadow: none;
      color: #4265da;
    }

    &:disabled {
    }
  }
`;
export default StyledCancelLink;
