import Input from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';

const StyledInputPhoneField = styled(Input)`

  &:disabled {
    border: 1px solid var(--lightGrey);
    background-color: var(--disableBg);
  }
`
export default StyledInputPhoneField;
