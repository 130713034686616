import instance from "../root.api";

export const getMarriageNocsRequest = () =>
    instance
        .get("/apis-admin/marriage-nocs",)
        .then(response => ({response}))
export const creatMarriageNocsRequest = () =>
    instance
        .post("/apis-admin/marriage-nocs",)
        .then(response => ({response}))

export const searchMarriageNocsRequest = (params) =>
    instance
        .get(`/apis-admin/marriage-nocs${params ? `/search?_q=${params}` : ''}`,)
        .then(response => ({response}))

export const updateMarriageNocStatusRequest = ({id, newStatus}) =>
    instance
        .put(`/apis-admin/marriage-nocs/${id}/status`, {newStatus})
        .then(response => ({response}))


export const updateMarriageNocRequest = ({id, ...data}) =>
    instance
        .put(`/apis-admin/marriage-nocs/${id}`, data)
        .then(response => ({response}))

export const findRelatedMarriageNocRequest = ({uuid}) =>
    instance
        .get(`/apis-admin/marriage-nocs/${uuid}/related`)
        .then(response => ({response}))

export const getMarriageNocDetailsRequest = ({uuid}) =>
    instance
        .get(`/apis-admin/marriage-nocs/${uuid}`)
        .then(response => ({response}))
