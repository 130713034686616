import styled from "styled-components";
import { DatePicker } from "antd";
import { DEVICE } from "../../../constants/media";

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #d6e1e9;
  background-color: #ffffff;

  @media ${DEVICE.tablet} {
    max-width: 208px;
  }

  .ant-picker-input > input {
    color: var(--dark);
    font-size: 13px;
    font-family: var(--fontProximaSemi);
  }
  .ant-picker-input {
    font-family: var(--fontProximaSemi);
  }

  &.ant-picker-focused {
    background-color: #ffffff;
    box-shadow: none;
    border: solid 1px #9a9fb0;
  }
  &:hover {
    border: solid 1px #9a9fb0;
    background-color: #ffffff;
    box-shadow: none;
  }
  &.ant-picker-disabled {
    .ant-picker-input > input {
    }
  }
  &:disabled {
    border: 1px solid var(--lightGrey);
    background-color: var(--disableBg);
  }
  &.ant-picker-disabled {
    border: 1px solid var(--lightGrey);
    background-color: var(--disableBg);
    &:hover {
      border: 1px solid var(--lightGrey);
      background-color: var(--disableBg);
    }
  }

  .ant-picker-clear {
    background: transparent;
    &:hover {
      color: rgba(0, 0, 0, 0.75);
      //right: -10px;
      //top: 13px;
    }
  }

  &.ant-picker-small {
    height: 32px;
    padding: 0 15px;
  }
`;
export default StyledDatePicker;
