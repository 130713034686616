import styled, {css} from 'styled-components';
import {Upload} from 'antd';
import {FileOutlined, DeleteOutlined} from '@ant-design/icons'


const ModalContent = styled.div`
  padding: 24px 8px;
`;

const ModalTitle = styled.div`
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 16px;
`;

const ModalForm = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 32px;
`;

const ModalField = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 16px);
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

export default {
    ModalContent,
    ModalTitle,
    ModalForm,
    ModalField,
    ButtonWrapper,
}