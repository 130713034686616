import React, {useEffect, useState} from 'react';
import { Field } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import ControlWrapper from "../ControlWrapper/ControlWrapper";
import StyledSelect from "components/SelectField/styled/StyledSelect.js";

const CustomSelect = ({
    input: { value, onBlur, name, ...restInput },
    label,
    onSelect,
    children,
    defaultValue,
    formatMessage,
    customTouched,
    placeholder,
    setTouched,
    disabled,
    ...meta
}) => {
    return <ControlWrapper
        label={label}
        name={name}
        customTouched={customTouched}
        error={meta.meta.error}
        touched={meta.meta.touched}
    >
        <StyledSelect
            defaultValue={defaultValue}
            {...meta}
            {...restInput}
            disabled={disabled}
            onFocus={setTouched.bind(null, true)}
            onSelect={onSelect}
            placeholder={placeholder && formatMessage({ id: placeholder })}
            { ...(value && { value })  }

        >
            {
                children
            }
        </StyledSelect>

    </ControlWrapper>
};

const SelectField = ({
     name,
     children,
     errors,
     intl: { formatMessage },
     placeholder,
     label,
     defaultValue,
     disabled,
     onSelect,
    ...props
}) => {
    const [ customTouched, setTouched ] = useState(false);
    useEffect(() => {
        setTouched(false)
    }, [errors]);
    return (
        <Field
            name={name}
            component={CustomSelect}
            customTouched={customTouched}
            setTouched={setTouched}
            formatMessage={formatMessage}
            label={label}
            defaultValue={defaultValue}
            onSelect={onSelect}
            placeholder={placeholder}
            children={children}
            disabled={disabled}
            { ...props }
        />
    )};

export default compose(
    connect(({ errors }) => ({ errors }),
        {} ),
    injectIntl,
)(SelectField);
