import {
  GET_LITURGIES_SUCCESS,
  GET_LITURGY_SUCCESS,
  GET_LITURGY_BOOKINGS_SUCCESS,
  GET_LITURGIES_COUNT_SUCCESS
} from "./liturgy.action";
import initialState from "../../store/initialState";

export default (state = initialState.liturgies, action) => {
  switch (action.type) {
    case GET_LITURGIES_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case GET_LITURGIES_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload
      };
    case GET_LITURGY_SUCCESS:
      return {
        ...state,
        liturgy: action.payload
      };
    case GET_LITURGY_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload
      };
    default:
      return state;
  }
};
