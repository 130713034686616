import styled from "styled-components";
import { Checkbox } from "antd";
import Arrow from "assets/img/check.svg";
// import ArrowDisabled from '../../../assets/icons/checkboxDisabled.svg';

export default styled(Checkbox)`
  &&& {
    font-family: var(--fontProximaBold);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--dark);
    padding-left: 2px;
    display: flex;
    border-radius: 2px;

    &.ant-checkbox-wrapper-checked {
      color: var(--dark);
    }

    input[type="checkbox"] {
      width: 22px;
      height: 22px;
    }

    &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
      color: var(--lightGrey);
    }

    &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
      color: var(--dark);
    }

    &:hover {
      border-color: var(--blue2);
      .ant-checkbox-inner {
        border-color: var(--blue2);
        box-shadow: 0 1px 3px 0 rgba(76, 111, 230, 0.3);
      }
    }

    .ant-checkbox {
      width: 24px;
      height: 24px;
      margin-right: 5px;
        

      &.ant-checkbox-checked .ant-checkbox-inner::after {
        opacity: 1;
        width: 14px;
        height: 11px;
        left: 4px;
        top: 5px;
        transform: none;
        border: none;
        background: url(${Arrow}) center center no-repeat;
        background-size: contain;
      }

      &.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after {
        //background: url() center center no-repeat;
      }

      &.ant-checkbox-checked .ant-checkbox-inner {
        border: 1px solid var(--blue2);
        color: var(--dark);
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 rgba(76, 111, 230, 0.3);
      }

      & + span {
        width: 100%;
        
      }
    }

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      background: transparent;
      border: 1px solid var(--blue2);
      position: relative;
      border-radius: 2px;
    }
    .ant-checkbox-checked.ant-checkbox-inner {
      border: 1px solid var(--blue2);
    }
    .ant-checkbox-checked.ant-checkbox-disabled {
    }
  }
`;
