import { notification } from "antd";
import { createIntl } from "react-intl";
import arDictionary from "../locales/ar";
import enDictionary from "../locales/en";

const lang = localStorage.getItem("lang");

const APPDictionary = {
  ar: arDictionary,
  en: enDictionary
};

const intl = createIntl({ locale: lang, messages: APPDictionary[lang] });

const openNotification = ({
  message,
  description,
  type = "info",
  duration
}) => {
  notification[type]({
    duration: duration ? duration : type === "error" ? 9999999999 : 4.5,
    message: intl.formatMessage({ id: message }),
    description: intl.formatMessage({ id: description })
  });
};

export default openNotification;
