import styled from 'styled-components'

const StyledFileUploadSection = styled.div`
    background-color: green;
  display: flex;
`

export default StyledFileUploadSection;


