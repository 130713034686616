import styled from 'styled-components';
import {Upload} from "antd";
import {DeleteOutlined, FileOutlined} from "@ant-design/icons";

const StyledUpload = styled(Upload)`
  width: 100%;

  &&& .ant-upload {
    display: block;
  }
`

const UploadButton = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 16px;
  background: #F8F9FB;
  border: 1px dashed #D6E1E9;
  border-radius: 10px;
  color: #4164DA;
  font-weight: bold;
  margin: 16px 0;
`;

const RemoveIcon = styled(DeleteOutlined)`
  &:hover {
    color: #fc6161;
  }
`

const FilesList = styled.div`
  margin-top: 24px;
`;

const FileIcon = styled(FileOutlined)`
  margin-right: 16px;
`;

const File = styled.a`
  display: flex;
  margin-bottom: 8px;
  border: 1px solid #D6E1E9;
  border-radius: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  color: #27282A;
`;

export default {
    Upload: StyledUpload,
    UploadButton,
    RemoveIcon,
    FilesList,
    FileIcon,
    File
}