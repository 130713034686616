import styled from "styled-components";

const StyledPageSubTitle = styled.p`
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  position: relative;
  background: var(--bgLight);
  display: inline-block;
  color: var(--gray);
  z-index: 2;
  padding-right: 25px;
  text-transform: uppercase;
  margin: 0;
`;

export default StyledPageSubTitle;
