import styled from "styled-components";
import { Menu } from "antd";
import Copy from '../../../assets/img/Icon/copy.svg';
import Delete from '../../../assets/img/Icon/Remove.svg';

const ViewFamilyLink = styled.div`
  a {
    color: #587cf4;
    border-bottom: 1px dashed #587cf4;
  }
`;
const CopyIcon = styled.div`
  display: inline-block;
  width: 24px;
  height: 22px;
  background: url(${Copy}) center center no-repeat;
  background-size: contain;
  cursor: pointer; 
`;

const DeleteIcon = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(${Delete}) center center no-repeat;
  background-size: contain;
  cursor: pointer; 
  margin-left: 16px;
`;

const ImgCol = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;

  .ant-avatar.ant-avatar-icon {
    border-radius: 50%;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const TitleCol = styled.div`
  font-family: var(--fontProximaRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #587cf4;
`;

const MenuItem = styled(Menu.Item)`
  &&& {
    font-size: 14px;
    a {
      font-size: 14px;
    }
  }
`;

export default {
  ViewFamilyLink,
  MenuItem,
  ImgCol,
  DeleteIcon,
  TitleCol,
  CopyIcon
};
