import isEmpty from "lodash/isEmpty";

export const prepareFamily = ({
  mainMember,
  spouse,
  children = [],
  grandParents = []
}) => {
  return [
    ...(!!mainMember
      ? [
          {
            ...mainMember,
            role:
              mainMember?.gender === "male" ? "Family.Husband" : "Family.Wife"
          }
        ]
      : []),
    ...(!isEmpty(spouse)
      ? [
          {
            ...spouse,
            role: spouse?.gender === "male" ? "Family.Husband" : "Family.Wife"
          }
        ]
      : []),
    ...children?.map(child => ({
      ...child,
      role: child?.gender === "male" ? "Family.Son" : "Family.Daughter"
    })),
    ...grandParents?.map(grandParent => ({
      ...grandParent,
      role:
        grandParent?.gender === "male"
          ? "Family.GrandFather"
          : "Family.GrandMother"
    }))
  ];
};
