import React from 'react';
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { familySelector } from "services/family/family.selector";
import { urlLocations } from "routes/urlLocations";
import { prepareFamily } from "services/family/family.utils";
import FamilySection from "./Family.Section";
import FamilyAddress from './Family.Address/Family.Address';
import GoBack from "containers/styled/StyledGoBack";
import Styled from './styled/StyledFamily';
import StyledContainer from "components/styled/StyledContainer";

const Family = () => {
    const family = useSelector(familySelector);
    const preparedMembers = prepareFamily(family);
    const names = preparedMembers.map(member => member.firstName);
    return (
        <Styled.Page>
            <StyledContainer>
                <GoBack.Block>
                    <GoBack.Button to={urlLocations.membersTable} >
                        <FormattedMessage id="Members.BackButton" />
                    </GoBack.Button>
                </GoBack.Block>

                <Styled.Header>
                    <Styled.Subtitle>
                        <FormattedMessage id="Family" />{" "}
                        { family?.mainMember?.lastName }
                    </Styled.Subtitle>
                    <Styled.Title>
                        { names.join(' | ') }
                    </Styled.Title>
                </Styled.Header>

                <Styled.FamilyRow>
                    <Styled.FamilyInfo>
                        <FamilySection
                            members={prepareFamily({ mainMember: family?.mainMember, spouse: family?.spouse })}
                            title="Family.MainMembers"
                            addText="Family.AddMember"
                        />
                        <FamilySection
                            members={prepareFamily({ children: family?.children })}
                            title="Family.Kids"
                            addText="Family.AddChild"
                        />
                        <FamilySection
                            members={prepareFamily({ grandParents: family?.grandParents })}
                            title="Family.GrandParents"
                            addText="Family.AddParent"
                        />
                    </Styled.FamilyInfo>
                    <Styled.AddressCol>
                        <FamilyAddress
                            id={family?.id}
                            geoLocation={family?.geoLocation}
                            address={family?.address}
                            emirate={family?.emirate}
                        />
                    </Styled.AddressCol>
                </Styled.FamilyRow>
            </StyledContainer>
        </Styled.Page>
    );
};

export default Family;