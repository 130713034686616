import React from 'react';
import { FormattedMessage } from "react-intl";


import FamilyMember from './Family.Member';
import {urlLocations} from "routes/urlLocations";
import StyledPageSubTitle from "containers/styled/StyledPageSubTitle.js";
import StyledPageWrapSubTitle from "containers/styled/StyledPageWrapSubTitle.js";
import Styled from './styled/StyledFamily';

const FamilySection = ({ members, title, addText }) => {
    return (
        <>
            <StyledPageWrapSubTitle>
                <StyledPageSubTitle>
                    <FormattedMessage id={title} />
                </StyledPageSubTitle>
            </StyledPageWrapSubTitle>
            <Styled.FamilyList>
                { !!members.length && <>

                { members?.map(member => (
                    member &&  <FamilyMember key={member?.id} member={member} />
                )) }
                </> }

                {/*<Styled.AddMember disabled to={urlLocations.member} >*/}
                {/*    <Styled.AddMemberIcon></Styled.AddMemberIcon>*/}
                {/*    <Styled.AddMemberTxt>*/}
                {/*        { addText && <FormattedMessage id={addText}/> }*/}
                {/*    </Styled.AddMemberTxt>*/}
                {/*</Styled.AddMember>*/}
            </Styled.FamilyList>

        </>
    );
};

export default FamilySection;