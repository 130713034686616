import {createBrowserHistory} from "history";

export const history = createBrowserHistory();

export const urlLocations = {
    login: "/",
    dashboard: "/dashboard",
    members: "/members",
    member: "/member",
    memberInfo: "/member/:id?/:familyID?",
    membersPath: "/members/:view?",
    membersTable: "/members/table",
    membersMap: "/members/map",
    family: "/family",
    familyInfo: `/family/:familyID?`,
    liturgies: "/liturgies",
    liturgy: "/liturgy",
    liturgyInfo: "/liturgy/:id?/:action?",
    services: "/services",
    marriageNOC: "/marriage-nocs",
    marriageNOCDetails: "/marriage-noc/:uuid",
    contributions: "/contributions"
};


export const pathBuilder = {
    marriageNOCDetails: uuid => urlLocations.marriageNOCDetails.replace(':uuid', uuid),
}
