import React, {useEffect, useState} from 'react';
import {change, reduxForm, untouch} from "redux-form";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Select } from "antd";
import { FormOutlined } from '@ant-design/icons'
import { useDispatch } from "react-redux";

import { appInfoEmiratesSelector } from "services/appInfo/appInfo.selector";
import { getAreasAction } from 'services/area/area.action';
import SelectField from "components/SelectField/SelectField";
import InputField from "components/InputField/InputField";
import {getLocalizedKey} from "utils/utils";
import Styled from "containers/Member/Member.Forms/styled/StyledMember.Form";
import {areasSelector} from "services/area/area.selector";
import {updateFamilyAction} from "services/family/family.action";
import Cancel from "components/Button/styled/StyledCancel";
import Save from "components/Button/styled/StyledSave";
import sortBy from 'lodash/sortBy';
import Loader from "components/Loader/Loader";

const { Option } = Select;

const MemberFormsAddress = ({ handleSubmit, member, formName, initialValues:  { address, emirate } = {} }) => {
    const [ selectedEmirate, setEmirate ] = useState(null);
    const [ disabled, setDisableStatus ] = useState(true);
    const emirates = useSelector(appInfoEmiratesSelector);
    const areas = useSelector(areasSelector);
    const dispatch  = useDispatch();

    useEffect(() => {
        !!selectedEmirate && dispatch(getAreasAction(selectedEmirate))
    }, [selectedEmirate]);

    useEffect(() => {
        !!emirate?.id && setEmirate(emirate?.id)
    }, [emirate?.id]);

    const toggleDisable = () => {
        setDisableStatus(!disabled)
    };

    const handleSelectEmirate = (id) => {
        setEmirate(id);
        dispatch(change('addressForm', 'address.area', ''));
        dispatch(untouch('addressForm', 'address.area'));
    };

    return (
        <Styled.ColRight>
            <Styled.FormBlock>
                <Loader name={formName} >

                <form onSubmit={handleSubmit} >

                <Styled.FormHeader>
                    <Styled.HeaderTitle>
                        <FormattedMessage id="Member.Form.AddressInfo" />
                    </Styled.HeaderTitle>
                    <Styled.EditBtn>
                        <FormOutlined onClick={toggleDisable} />
                    </Styled.EditBtn>
                </Styled.FormHeader>

                <Styled.FormContent>
                    <Styled.Field>
                        <Styled.Col33>
                            <SelectField
                                disabled={disabled}
                                name="emirate.id"
                                label="Member.Form.Emirate"
                                placeholder="Member.Form.Emirate"
                                onSelect={handleSelectEmirate}
                            >
                                {
                                    emirates?.map(field => (
                                        <Option key={field.id} value={field.id} >
                                            { field[getLocalizedKey('name')] }
                                        </Option>
                                    ))
                                }
                            </SelectField>
                        </Styled.Col33>
                    </Styled.Field>

                    <Styled.Field>
                        <Styled.Col60>
                            <InputField
                                disabled={disabled}
                                name="address.addressLine"
                                label="Member.Form.Address"
                                placeholder="Member.Form.Address"
                            />
                        </Styled.Col60>
                        <Styled.Col40>
                            <SelectField
                                disabled={disabled}
                                name="address.area"
                                label="Member.Form.Area"
                                placeholder="Member.Form.Area"
                                filterOption={(input, option) => {
                                    return option.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }}
                                showSearch
                            >
                                {
                                    sortBy(areas, area => area[getLocalizedKey('name')])?.map(field => (
                                        <Option key={field.id} value={field.id} >
                                            { field[getLocalizedKey('name')] }
                                        </Option>
                                    ))
                                }
                            </SelectField>
                        </Styled.Col40>
                    </Styled.Field>

                    <Styled.Field>
                        <Styled.LocationBtn
                            disabled={!member?.familyID?.geoLocation?.latitude}
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${member?.familyID?.geoLocation?.latitude},${member?.familyID?.geoLocation?.longitude}`}                        >
                            { member?.familyID?.geoLocation?.latitude ?
                                <FormattedMessage id="Member.Form.MapLocation" /> :
                                <FormattedMessage id="Member.Form.LocationNotAvailable" />
                            }
                        </Styled.LocationBtn>
                    </Styled.Field>

                { !disabled && <Styled.ButtonsRow>
                    <Save  htmlType="submit" >
                        <FormattedMessage id="Member.Form.Save" />
                    </Save>
                    <Cancel  onClick={setDisableStatus.bind(null, true)} >
                        <FormattedMessage id="Member.Form.Cancel" />
                    </Cancel>
                </Styled.ButtonsRow> }
                </Styled.FormContent>
            </form>
                </Loader>
            </Styled.FormBlock>
        </Styled.ColRight>
    );
};

export default reduxForm({
    form: "addressForm",
    enableReinitialize: true,
    onSubmit: (value, dispatch, { member, formName }) => {
        dispatch(
            updateFamilyAction({
                ...value, formName, id: member?.familyID?.id
            })
        )
    }
})(MemberFormsAddress);