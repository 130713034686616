import React, { useState, useEffect } from 'react';
import { Select } from "antd"
import { FormOutlined } from '@ant-design/icons'
import { reduxForm } from "redux-form";
import {FormattedMessage} from "react-intl";


import InputField from "components/InputField/InputField";
import SelectField from "components/SelectField/SelectField";
import DatePickerField from "components/DatePickerField/DatePickerField";
import Styled from './styled/StyledMember.Form';
import { updateMembersAction } from "services/members/members.action";
import Loader from 'components/Loader/Loader';
import Cancel from "components/Button/styled/StyledCancel";
import Save from "components/Button/styled/StyledSave";

const { Option } = Select;

const MemberFormsPersonal = ({ handleSubmit, formName, member }) => {
    const [ disabled, setDisableStatus ] = useState(true);

    const toggleDisable = () => {
        setDisableStatus(!disabled)
    };

     useEffect(() => {
         setDisableStatus(true)
     }, [member]);

    return (
        <Styled.ColLeft>
            <Styled.FormBlock>
                <Loader name={formName} >
                    <form onSubmit={handleSubmit} >
                        <Styled.FormHeader>
                            <Styled.HeaderTitle>
                                <FormattedMessage id="Member.Form.Personal" />
                            </Styled.HeaderTitle>
                            <Styled.EditBtn>
                                <FormOutlined onClick={toggleDisable} />
                            </Styled.EditBtn>
                        </Styled.FormHeader>

                        <Styled.FormContent>
                            <Styled.Field>
                                <Styled.Col33>
                                    <InputField
                                        disabled={disabled}
                                        name="firstName"
                                        label="Member.Form.FirstName"
                                        placeholder="Member.Form.FirstName"
                                    />
                                </Styled.Col33>
                                <Styled.Col33>
                                    <InputField
                                        disabled={disabled}
                                        name="secondName"
                                        label="Member.Form.SecondName"
                                        placeholder="Member.Form.SecondName"
                                    />
                                </Styled.Col33>
                                <Styled.Col33>
                                    <InputField
                                        disabled={disabled}
                                        name="lastName"
                                        label="Member.Form.LastName"
                                        placeholder="Member.Form.LastName"
                                    />
                                </Styled.Col33>
                            </Styled.Field>

                            <Styled.Field>
                                <Styled.Col33>
                                    <SelectField
                                        disabled={disabled}
                                        name="gender"
                                        label="Member.Form.Gender"
                                        placeholder="Member.Form.Gender"
                                    >
                                        <Option key="male" value="male" >
                                            <FormattedMessage id="General.Male" />
                                        </Option>
                                        <Option key="female" value="female" >
                                            <FormattedMessage id="General.Female" />
                                        </Option>
                                    </SelectField>
                                </Styled.Col33>
                                <Styled.Col33>
                                    <DatePickerField
                                        disabled={disabled}
                                        name="dateOfBirth"
                                        label="Member.Form.DateOfBirth"
                                        placeholder="Member.Form.DateOfBirth"
                                    />
                                </Styled.Col33>
                                <Styled.Col33>
                                </Styled.Col33>
                            </Styled.Field>

                            { !disabled && <Styled.ButtonsRow>
                                <Cancel onClick={setDisableStatus.bind(null, true)} >
                                    <FormattedMessage id="Member.Form.Cancel" />
                                </Cancel>
                                <Save htmlType="submit" >
                                    <FormattedMessage id="Member.Form.Save" />
                                </Save>
                            </Styled.ButtonsRow> }
                        </Styled.FormContent>
                    </form>
                </Loader>
            </Styled.FormBlock>
        </Styled.ColLeft>
    );
};

export default reduxForm({
    form: "personalInfo",
    enableReinitialize: true,
    onSubmit: (value, dispatch, { member, formName }) => {
        dispatch(
            updateMembersAction({
                ...value, formName, id: member?.id
            })
        )
    }
})(MemberFormsPersonal);