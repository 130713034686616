import styled from "styled-components";
import { Radio } from "antd";
import {DEVICE} from "constants/media";
const { Group } = Radio;

const StyledRadioGroup = styled(Group)`
  padding-top: 11px;

    @media ${DEVICE.mobileDevices} {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

  label {
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: var(--dark);
    margin-right: 22px;
  }
  
  .ant-radio-checked::after {
    border: 1px solid #4265da;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #4265da;
    color: var(--dark);
  }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #4265da;
    background-image: linear-gradient(to top, #4164da, #587cf4);
  }
`;

export default StyledRadioGroup;
