import styled, { css } from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1168px;
  }

  ${({ type }) => {
    if (type && type === "header") {
      return css`
        padding-right: 30px;
        padding-left: 30px;
          align-items: center;
          justify-content: center;

        @media (min-width: 1200px) and (max-width: 1439px) {
          max-width: 100%;
          padding-right: 30px;
          padding-left: 30px;
        }
        @media (min-width: 1200px) {
          max-width: 100%;
        }
        @media (min-width: 1440px) {
          max-width: 100%;
        }

        @media (min-width: 1800px) {
          max-width: 1650px;
          padding-right: 80px;
          padding-left: 80px;
        }
      `;
    }
  }}};
`;

export default StyledContainer;
