import {put, takeEvery, call} from "redux-saga/effects";

import includes from "lodash/includes";

import {LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS} from "./login.action";
import {MODALS_ID, STORAGE_TYPE} from "../../constants/constants";
import instance from "../root.api";
import {loginRequest} from "./login.api";
import {history, urlLocations} from "../../routes/urlLocations";
import openNotification from "../../utils/notifications";
import {SET_ERRORS} from "../errors/errors.action";
import {SET_MODAL_STATUS} from "../modals/modals.action";

function* login({payload: {identifier, password, rememberMe = false}}) {
  const lowerCaseIdentifier = identifier?.trim()?.toLowerCase();
  try {
    const {response} = yield call(loginRequest, {
      identifier: lowerCaseIdentifier,
      password
    });

    if (response.status === 200) {
      localStorage.setItem(
        "storageTyp",
        rememberMe ? STORAGE_TYPE.LOCAL_STORAGE : STORAGE_TYPE.SESSION_STORAGE
      );
      const storage = localStorage.getItem("storageTyp");
      window[storage].setItem("jwt", response.data.jwt);
      instance.defaults.headers.common["Authorization"] = `Bearer ${
        response.data.jwt
      }`;
      yield history.push(urlLocations.dashboard);
      yield put({
        type: LOGIN_SUCCESS
      });
      yield put({
        type: SET_MODAL_STATUS
      });
      yield call(openNotification,{
        type: "success",
        message: "Login.Notification.Success",
        description: "Login.Notification.SuccessMessage"
      });
    } else {
      yield put({
        type: LOGIN_FAILURE
      });
      if (includes(response.data.data.error, "Auth.form.error.confirmed")) {
        yield put({
          type: SET_MODAL_STATUS,
          payload: MODALS_ID.RESEND_EMAIL_POPUP
        });
      } else {
        yield call(openNotification,{
          type: "error",
          message: "Login.Notification.Error",
          description: response.data.data[0]?.messages[0]?.id
        });
      }

      yield put({
        type: SET_ERRORS,
        payload: response.data.data
      });
    }
  } catch (error) {
    yield put({type: LOGIN_FAILURE});
    console.log(error);
  }
}

export default function* loginSaga() {
  yield takeEvery(LOGIN, login);
}
