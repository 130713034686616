import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import Members from './Members/Members';
import Family from "./Family/Family";
import Member from './Member/Member'
import Liturgies from './Liturgies/Liturgies';
import Liturgy from './Liturgy/Liturgy';
import Services from './Services/Services';
import MarriageNoc from './MarriageNOC/MarriageNOC';
import Contributions from "./Contributions/Contributions";
import MarriageNOCDetails from "./MarriageNOCDetails/MarriageNOCDetails";

export default {
    Login,
    Dashboard,
    Members,
    Family,
    Member,
    Liturgies,
    Liturgy,
    Services,
    MarriageNoc,
    Contributions,
    MarriageNOCDetails
};
