import styled from "styled-components";
import { Link } from "react-router-dom";
import CloseImg from "assets/img/Icon/Close.svg";

const MemberPage = styled.div`
  padding-top: 24px;
  padding-bottom: 50px;
`;

const Search = styled.div``;

const MembersFiltersFields = styled.div`
  align-items: center;
  position: relative;
  border-left: 4px solid #25b1ff;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 0;
  margin-top: 24px;
  max-width: 760px;

  &:before {
    //content: '';
    //width: 4px;
    //height: 72px;
    //background: #25B1FF;
    //border-radius: 10px;
  }
`;

const MembersFiltersList = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MembersFiltersItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 14px;
`;

const MembersFiltersView = styled.div`
  border-top: 1px solid #e9edf0;
  padding: 30px 0 0px 0;
  margin-top: 32px;
`;

const FilterCol = styled.div`
  width: 208px;
  margin-right: 8px;
  position: relative;
`;

const Filters = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const SearchCol = styled.div`
  width: 448px;
  display: inline-block;
`;

const FilterView = styled.div`
  display: inline-flex;
  padding: 4px 9px 4px 15px;
  color: #fff;
  font-size: 13px;
  font-family: var(--fontProximaSemi);
  background: #8f96ae;
  border-radius: 5px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 6px;
`;

const CloseView = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url(${CloseImg}) center center no-repeat;
  background-size: contain;
  margin-left: 12px;
`;

export default {
  MemberPage,
  Filters,
  SearchCol,
  FilterView,
  Search,
  MembersFiltersList,
  MembersFiltersFields,
  MembersFiltersView,
  FilterCol,
  CloseView,
  MembersFiltersItem
};
