import { createAction } from "redux-actions";

export const GET_FAMILY = "GET_FAMILY";
export const GET_FAMILY_SUCCESS = "GET_FAMILY_SUCCESS";
export const GET_FAMILY_FAILURE = "GET_FAMILY_FAILURE";

export const UPDATE_FAMILY = "UPDATE_FAMILY";
export const UPDATE_FAMILY_SUCCESS = "UPDATE_FAMILY_SUCCESS";
export const UPDATE_FAMILY_FAILURE = "UPDATE_FAMILY_FAILURE";

export const updateFamilyAction = createAction(UPDATE_FAMILY);
