import {
    GET_CONTRIBUTIONS_SUCCESS,
    SEARCH_CONTRIBUTIONS_SUCCESS
} from "./contributions.actions";
import initialState from "../../store/initialState";

export default (state = initialState.contributions, action) => {
    switch (action.type) {
        case GET_CONTRIBUTIONS_SUCCESS:
        case SEARCH_CONTRIBUTIONS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};
