import styled from "styled-components";
import { Link } from "react-router-dom";
import GoBackImg from "assets/img/Icon/Arrowback.svg";

const MemberPage = styled.div`
  padding-top: 24px;
  padding-bottom: 50px;
`;

const GoBackBlock = styled.div`
  width: 100%;
`;
const GoBack = styled(Link)`
  width: 162px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  border: 1px solid #d6e1e9;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: var(--fontProximaRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #27282a;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    left: -5px;
    width: 24px;
    height: 24px;
    background: url(${GoBackImg}) center center no-repeat;
    background-size: contain;
  }
  &:hover {
    color: #27282a;
    outline: none;
    border: none;
  }
  &:visited,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    outline: none;
    border: none;
  }
`;

const MemberBlock = styled.div`
  margin: 32px 0;
  width: 100%;
  display: flex;
`;

const MemberTitle = styled.div`
  width: calc(100% - 120px);
`;

const MemberName = styled.h1`
  font-family: var(--fontProximaBold);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #27282a;
  padding: 0;
  margin: 0 0 8px 0;
`;

const MemberYearsOld = styled.div`
  font-family: var(--fontProximaRegular);
  font-size: 16px;
  line-height: 24px;
  color: #a4abc4;
`;

const MemberPicture = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 32px;
  overflow: hidden;

  img {
    width: 99%;
    height: 99%;
    border-radius: 10px;
    object-fit: cover;
    display: block;
  }
  .ant-avatar.ant-avatar-icon {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

export default {
  MemberPage,
  GoBack,
  GoBackBlock,
  MemberBlock,
  MemberYearsOld,
  MemberPicture,
  MemberName,
  MemberTitle
};
