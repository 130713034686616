import React from 'react';
import ModalWrapper from "hoc/withModal";
import {MODALS_ID} from "constants/constants";
import OtherNOCsContent from "containers/MarriageNOCDetails/MarriageNocDetails.Others/OtherNOCsContent";
import {Modal} from "antd";
import Button from "components/Button/Button";
import {FormattedMessage} from "react-intl";
import {openNOCtoPrint} from "utils/utils";
import {setModalStatusAction} from "services/modals/modals.action";
import {useDispatch} from "react-redux";

const NocWarningModal = ({marriageNOC}) => {

  const dispatch = useDispatch();

  const onCheckClicked = () => {
    dispatch(setModalStatusAction(MODALS_ID.OTHER_NOCS));
  }
  const onPrintClick = () => {
    openNOCtoPrint(marriageNOC)
    dispatch(setModalStatusAction(null))
  }

  const renderContent = () => {
    return <div>
      <h1><FormattedMessage id={"MarriageNOCDetails.Warning.Title"}/></h1>
      <div>
        <FormattedMessage id={"MarriageNOCDetails.Warning.Content"}/>
      </div>
      <div className={"flex flex-row gap-4 justify-end mt-8"}>
        <Button uitype={'secondary'} onClick={onPrintClick}>
          <FormattedMessage id={"MarriageNOCDetails.Warning.Print"}/>
        </Button>
        <Button onClick={onCheckClicked}>
          <FormattedMessage id={"MarriageNOCDetails.Warning.CheckOthers"}/>
        </Button>
      </div>
    </div>
  }
  return (
    <ModalWrapper
      modalId={MODALS_ID.NOC_WARNING}
      component={renderContent}
      marriageNOC={marriageNOC}
    />
  );
};

export default NocWarningModal;
