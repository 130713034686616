import React from 'react';
import {FormattedMessage} from "react-intl";
import {Link, useLocation} from "react-router-dom";

import Styled from './styled/StyledHeader';
import StyledContainer from "components/styled/StyledContainer";
import {urlLocations} from "routes/urlLocations";
import UserBlock from "layouts/AuthorizeLayouts/UserBlock/UserBlock";
import {NAVIGATION_ITEMS} from "constants/constants";
import {useSelector} from "react-redux";
import {getCurrentChurchSelector} from "services/churches/churches.selector";
import {getLocalizedKey} from "utils/utils";
import {profileSelector} from "services/profile/profile.selectors";

const Header = () => {
  const {pathname} = useLocation();
  const church = useSelector(getCurrentChurchSelector);
  const profile = useSelector(profileSelector);

  return (
    <Styled.Header>
      <StyledContainer type='header'>
        <Styled.HeaderContent>
          <Link to={urlLocations.dashboard}>
            <Styled.Logo>
              <Styled.Img>
                <img src={church?.logo?.formats?.thumbnail?.url} alt=""/>
              </Styled.Img>
              <Styled.LogoTxt>
                <Styled.LogoTitle>
                  {church?.[getLocalizedKey('name')]}
                </Styled.LogoTitle>
                <Styled.LogoSubTitle>
                  {church?.addressLine} - {church?.emirate?.[getLocalizedKey('name')]} - UAE
                </Styled.LogoSubTitle>
              </Styled.LogoTxt>
            </Styled.Logo>
          </Link>

          <Styled.Nav>
            {
              NAVIGATION_ITEMS.map(item => (
                !item.to || profile?.access?.[item?.accessKey] || item.access ?
                  <Styled.NavItem
                    key={item.dataKey}
                    to={item.to}
                    datakey={item.dataKey}
                    isActive={pathname === item.to}
                    disabled={
                      (!item.to ||
                        !!profile?.access &&
                        !profile?.access?.[item?.accessKey]) &&
                      !item.access
                    }
                    access={item.access}>
                    <FormattedMessage id={`Dashboard.${item.dataKey}.Title`}/>
                  </Styled.NavItem>
                  : <></>
              ))
            }
          </Styled.Nav>

          <Styled.User>
            <UserBlock/>
          </Styled.User>
        </Styled.HeaderContent>
      </StyledContainer>
    </Styled.Header>
  );
};

export default Header;
