import {createAction} from "redux-actions";

export const GET_CONTRIBUTIONS = "GET_CONTRIBUTIONS";
export const GET_CONTRIBUTIONS_SUCCESS = "GET_CONTRIBUTIONS_SUCCESS";
export const GET_CONTRIBUTIONS_FAILURE = "GET_CONTRIBUTIONS_FAILURE";

export const SEARCH_CONTRIBUTIONS = "SEARCH_CONTRIBUTIONS";
export const SEARCH_CONTRIBUTIONS_SUCCESS = "SEARCH_CONTRIBUTIONS_SUCCESS";
export const SEARCH_CONTRIBUTIONS_FAILURE = "SEARCH_CONTRIBUTIONS_FAILURE";

export const CREATE_CONTRIBUTION = "CREATE_CONTRIBUTION";
export const CREATE_CONTRIBUTION_SUCCESS = "CREATE_CONTRIBUTION_SUCCESS";
export const CREATE_CONTRIBUTION_FAILURE = "CREATE_CONTRIBUTION_FAILURE";

export const EDIT_CONTRIBUTION = "EDIT_CONTRIBUTION";
export const EDIT_CONTRIBUTION_SUCCESS = "EDIT_CONTRIBUTION_SUCCESS";
export const EDIT_CONTRIBUTION_FAILURE = "EDIT_CONTRIBUTION_FAILURE";

export const searchContributionsAction = createAction(SEARCH_CONTRIBUTIONS);
export const createContributionsAction = createAction(CREATE_CONTRIBUTION);
export const editContributionsAction = createAction(EDIT_CONTRIBUTION);