import React, {useEffect, useState} from 'react';
import { reduxForm, change, untouch } from "redux-form";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import { FormOutlined } from '@ant-design/icons'

import SelectField from "components/SelectField/SelectField";
import {getLocalizedKey} from "utils/utils";
import InputField from "components/InputField/InputField";
import Button from "components/Button/Button";
import {appInfoEmiratesSelector} from "services/appInfo/appInfo.selector";
import {Select} from "antd";
import Section from 'containers/styled/StyledBlock';
import Styled from "containers/styled/StyledGeneralForm";
import Cancel from "components/Button/styled/StyledCancel";
import Save from "components/Button/styled/StyledSave";
import {areasSelector} from "services/area/area.selector";
import {getAreasAction} from "services/area/area.action";
import {updateFamilyAction} from "services/family/family.action";
import Loader from "components/Loader/Loader";
import sortBy from 'lodash/sortBy';

const { Option } = Select;

const FAMILY_ADDRESS_FORM = "FAMILY_ADDRESS_FORM";

const FamilyAddressForm = ({ handleSubmit, geoLocation, initialValues:  { address, emirate } = {}  }) => {
    const [ disabled, setDisableStatus ] = useState(true);
    const [ selectedEmirate, setEmirate ] = useState(null);

    const emirates = useSelector(appInfoEmiratesSelector);
    const areas = useSelector(areasSelector);
    const dispatch  = useDispatch();

    useEffect(() => {
        !!selectedEmirate && dispatch(getAreasAction(selectedEmirate))
    }, [selectedEmirate]);

    useEffect(() => {
        !!emirate?.id && setEmirate(emirate?.id)
    }, [emirate?.id]);

    const toggleDisable = () => {
        setDisableStatus(!disabled)
    };

    const handleSelectEmirate = (id) => {
        setEmirate(id);
        dispatch(change('familyAddressForm', 'address.area', ''));
        dispatch(untouch('familyAddressForm', 'address.area'));
    };


    return (

        <Section.Block>
            <Loader name={FAMILY_ADDRESS_FORM} >
            <form onSubmit={(event) => {
                event.preventDefault();
                setDisableStatus(true);
                handleSubmit(event);
            }} >
                <Section.BlockHeader>
                    <Section.BlockTitle>
                        <FormattedMessage id="Member.Form.AddressInfo" />
                    </Section.BlockTitle>
                    <Section.EditBtn>
                        <FormOutlined onClick={toggleDisable} />
                    </Section.EditBtn>
                </Section.BlockHeader>

                <Section.BlockContent>

                    <Styled.Field>
                        <Styled.Col40>
                            <SelectField
                                disabled={disabled}
                                name="emirate.id"
                                label="Member.Form.Emirate"
                                placeholder="Member.Form.Emirate"
                                onSelect={handleSelectEmirate}
                            >
                                {
                                    emirates?.map(field => (
                                        <Option key={field.id} value={field.id} >
                                            { field[getLocalizedKey('name')] }
                                        </Option>
                                    ))
                                }
                            </SelectField>
                        </Styled.Col40>

                        <Styled.Col40>
                            <InputField
                                disabled={disabled}
                                name="address.addressLine"
                                label="Member.Form.Address"
                                placeholder="Member.Form.Address"
                            />
                        </Styled.Col40>

                    </Styled.Field>

                    <Styled.Field>
                        <Styled.Col100>

                            <SelectField
                                disabled={disabled}
                                name="address.area"
                                label="Member.Form.Area"
                                placeholder="Member.Form.Area"
                                filterOption={(input, option) => {
                                    return option.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                }}
                                showSearch
                            >
                                {
                                    sortBy(areas, area => area[getLocalizedKey('name')])?.map(field => (
                                        <Option key={field.id} value={field.id} >
                                            { field[getLocalizedKey('name')] }
                                        </Option>
                                    ))
                                }
                            </SelectField>
                        </Styled.Col100>
                    </Styled.Field>

                    <Styled.Field>
                            <Styled.LocationBtn target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${geoLocation?.latitude},${geoLocation?.longitude}`}>
                                <FormattedMessage id="Member.Form.MapLocation" />
                            </Styled.LocationBtn>
                    </Styled.Field>


                    { !disabled && <Styled.ButtonsRow>
                        <Save  htmlType="submit" >
                            <FormattedMessage id="Member.Form.Save" />
                        </Save>
                        <Cancel onClick={setDisableStatus.bind(null, true)} >
                            <FormattedMessage id="Member.Form.Cancel" />
                        </Cancel>
                    </Styled.ButtonsRow> }
                </Section.BlockContent>
            </form>
            </Loader>
        </Section.Block>
    );
};

export default reduxForm({
    form: "familyAddressForm",
    enableReinitialize: true,
    onSubmit: (value, dispatch, { id }) => {
        dispatch(
            updateFamilyAction({
                ...value,
                formName: FAMILY_ADDRESS_FORM,
                id
            })
        )
    }
})(FamilyAddressForm);