import { call, put, takeEvery } from "redux-saga/effects";

import { GET_AREAS, GET_AREAS_FAILURE, GET_AREAS_SUCCESS } from "./area.action";
import { areasRequest } from "./area.api";

function* getAreas({ payload }) {
  try {
    const { response } = yield call(areasRequest, payload);

    if (response.status === 200) {
      yield put({
        type: GET_AREAS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: GET_AREAS_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_AREAS_FAILURE });
    console.log(error);
  }
}

export default function* areaSaga() {
  yield takeEvery(GET_AREAS, getAreas);
}
