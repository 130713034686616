import React from "react";
import styled, { css } from "styled-components";
import { Button } from "antd";

const StyledSave = styled(({ ...props }) => <Button {...props} />)`
  && {
    display: block;
    min-width: 92px;
    height: 32px;
    padding: 4px 15px;
    background: var(--loginGradient);
    font-family: var(--fontProximaSemi), sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
    box-shadow: 0px 10px 20px rgba(66, 101, 218, 0.2);
    border-radius: 8px;

    &:hover {
      box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.25);
    }

    &:disabled {
      opacity: 0.9;
    }
  }
`;
export default StyledSave;
