import styled from 'styled-components';

const Page = styled.div`
  padding: 80px 0;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  color: var(--dark);
  padding: 0 40px 0 0;
`;

const SearchField = styled.div`
  margin: 24px 0 32px;
  max-width: 450px;
`;

const TableTitleRow = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: #4164DA;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #27282A;
`;

export default {
    Page,
    Title,
    SearchField,
    TableTitleRow,
    ActionRow,
    Subtitle
}