import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';

import Button from "components/Button/Button";
import BorderedButton from "components/BorderedButton/Button";
import Filter from './Liturgies.Filter';
import Table from './Liturgies.Table';
import {urlLocations} from "routes/urlLocations";
import StyledContainer from "components/styled/StyledContainer";
import Styled from './styled/StyledLiturgies';

const Liturgies = () => {
    return (
        <Styled.LiturgiesPage>
            <StyledContainer>
                <div className={"flex flex-wrap flex-row gap-4"}>
                    <Styled.TitlePage>
                        <FormattedMessage id="Liturgies.Title" />
                    </Styled.TitlePage>
                    <Filter/>
                    <Styled.AddNewPrayer to={urlLocations.liturgy} >
                        <Styled.AddIcon/>
                        <FormattedMessage id="Liturgies.AddNewPrayer" />
                    </Styled.AddNewPrayer>
                </div>
                <div>
                    <Table/>
                </div>
            </StyledContainer>
        </Styled.LiturgiesPage>
    );
};

export default Liturgies;
