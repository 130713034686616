import React from 'react';
import Styled from "containers/MarriageNOCDetails/styled";
import {FormattedMessage} from "react-intl";

const MarriageNocDetailsSection = ({title, children}) => {
    return (
        <Styled.Section>
            <Styled.Label>
                    <span>
                        <FormattedMessage id={title}/>
                    </span>
                <span/>
            </Styled.Label>
            {children}
        </Styled.Section>
    );
};

export default MarriageNocDetailsSection;