import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {CalendarOutlined} from '@ant-design/icons';
import moment from "moment";
import {Alert, Select as AntSelect, Spin} from 'antd';
import {useDispatch, useSelector} from "react-redux";

import Styled from './styled';
import {ALLOWED_STATUS, STATUS, STATUS_LOCALIZATION} from "constants/status";
import Select from "components/SelectField/styled/StyledSelect";
import ControlWrapper from "components/ControlWrapper/ControlWrapper";
import Button from "components/Button/Button";
import {
  updateMarriageNocFilesAction,
  updateMarriageNocStatusAction
} from "services/marriageNoc/marriageNoc.actions";
import FileUploader from "components/FileUploader/FileUploader";
import MarriageNocDetailsSection from "containers/MarriageNOCDetails/MarriageNOCDetails.Section";
import {selectFile} from "services/file/file.selector";
import {getLoaderByName, loaderSelector} from "services/loader/loader.selector";
import StatusTag from "components/StatusTag/StatusTag";
import {getRelatedNOCSelector} from "services/marriageNoc/marriageNoc.selector";
import {API_PATH, FILES_TYPE, FORMS_NAMES, LOADERS, MODALS_ID} from "constants/constants";
import PhotoSection from "components/PhotoSection/PhotoSection";
import {isNOCEditable, openNOCtoPrint} from "utils/utils";
import {setModalStatusAction} from "services/modals/modals.action";

const {Option} = AntSelect;

const STATUS_TO_DATA = {
  HANDED_OVER: {
    title: "MarriageNOCDetails.HardCopy",
    name: FILES_TYPE.MARRIAGE_NOC_HARD_COPY
  },
  CANCELED: {
    title: "MarriageNOCDetails.CancellationProof",
    name: FILES_TYPE.MARRIAGE_NOC_CANCELLATION_PROOF
  }
};

const MarriageNocDetailsStatus = ({marriageNOC}) => {
  const [status, setStatus] = useState(marriageNOC?.status);

  const dispatch = useDispatch();
  const attachments = useSelector(state =>
    selectFile(state, STATUS_TO_DATA[status]?.name, FORMS_NAMES.MARRIAGE_NOC)
  );
  const isLoading = useSelector(state => getLoaderByName(state, LOADERS.MARRIAGE_NOC_STATUS))
  /** @type {[IMarriageNOC]} **/
  const related = useSelector(state => getRelatedNOCSelector(state, marriageNOC?.uuid))

  useEffect(() => {
    setStatus(marriageNOC?.status);
  }, [marriageNOC?.status]);

  const isButtonDisabled = STATUS_TO_DATA[status] && !attachments?.length

  const handlePrint = () => {
    if(related.filter(noc => noc.status === "PENDING" || noc.status === "HANDED_OVER" || noc.status === "READY").length > 0) {
      dispatch(setModalStatusAction(MODALS_ID.NOC_WARNING))
    } else {
      openNOCtoPrint(marriageNOC)
    }
  }
  const handleUpdate = () => {
    STATUS_TO_DATA[status] && dispatch(updateMarriageNocFilesAction({
      ...marriageNOC,
      [STATUS_TO_DATA[status].name]: attachments

    }))
    dispatch(updateMarriageNocStatusAction({
      newStatus: status,
      id: marriageNOC?.id
    }));
  }
  return (
    <Spin spinning={!!isLoading}>
      <Styled.StatusSection>
        <div>
          <Styled.StatusRow>
            <StatusTag status={marriageNOC?.status}/>
            <Styled.Date>
              <CalendarOutlined/>
              {moment(marriageNOC?.handedover_at || marriageNOC?.submitted_at || marriageNOC?.created_at).format("DD MMM YYYY")}
            </Styled.Date>
            <Styled.Id>
              {marriageNOC?.uuid}
            </Styled.Id>
            {related?.length > 0 &&
              <Styled.RelatedNOCs onClick={() => dispatch(setModalStatusAction(MODALS_ID.OTHER_NOCS))} to="#">
                <FormattedMessage id={"MarriageNOCDetails.OldNOCs"} values={{count: related?.length}}/>
              </Styled.RelatedNOCs>
            }
          </Styled.StatusRow>
          <Styled.StatusBottomRow>
            <Styled.StatusSelect>
              <ControlWrapper label={"MarriageNOCDetails.Status"}>
                <Select
                  defaultValue={status}
                  value={status}
                  onSelect={v => setStatus(v)}
                  disabled={marriageNOC?.status === STATUS.CANCELED}
                >
                  {/*{Object.values(omit(STATUS, STATUS.DRAFT)).map(s => (*/}
                  {ALLOWED_STATUS[marriageNOC?.status].map(s => (
                    <Option
                      children={
                        <FormattedMessage id={STATUS_LOCALIZATION[s]}/>
                      }
                      key={s}
                      value={s}/>
                  ))}
                </Select>
              </ControlWrapper>
            </Styled.StatusSelect>
            {marriageNOC?.status !== STATUS.CANCELED && <Button aligntype={"default"} disabled={isButtonDisabled} onClick={handleUpdate}>
              <FormattedMessage id={"MarriageNOCDetails.UpdateStatus"}/>
            </Button>}
            {(marriageNOC?.status === STATUS.PENDING || marriageNOC?.status === STATUS.NEED_MORE_INFO || marriageNOC?.status === STATUS.READY) &&
              <Button uitype={'secondary'} onClick={handlePrint}>
                <FormattedMessage id={"MarriageNOCDetails.Print"}/>
              </Button>
            }
          </Styled.StatusBottomRow>
        </div>
        {/*<Styled.Avatar>*/}
        {/*  <img src={marriageNOC?.photo?.url} alt=""/>*/}
        {/*</Styled.Avatar>*/}
        <PhotoSection
          formName={FORMS_NAMES.MARRIAGE_NOC}
          name={FILES_TYPE.MARRIAGE_NOC_PHOTO}
          isEditable={isNOCEditable(marriageNOC)}
        />
      </Styled.StatusSection>
      {(status === STATUS.HANDED_OVER || status === STATUS.CANCELED) && <MarriageNocDetailsSection title={"MarriageNOCDetails.HardCopy"}>
        {/*Disable if the status is already handed over*/}
        <FileUploader formName={FORMS_NAMES.MARRIAGE_NOC} name={FILES_TYPE.MARRIAGE_NOC_HARD_COPY} isFormDisabled={marriageNOC?.status === STATUS.HANDED_OVER || marriageNOC?.status === STATUS.CANCELED}/>
      </MarriageNocDetailsSection>}
      {status === STATUS.CANCELED && <MarriageNocDetailsSection title={"MarriageNOCDetails.CancellationProof"}>
        {/*Disable if the status is already Canceled*/}
        <FileUploader formName={FORMS_NAMES.MARRIAGE_NOC} name={FILES_TYPE.MARRIAGE_NOC_CANCELLATION_PROOF} isFormDisabled={marriageNOC?.status === STATUS.CANCELED}/>
      </MarriageNocDetailsSection>}
    </Spin>
  );
};

export default MarriageNocDetailsStatus;
