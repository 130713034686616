import React, {useState} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {liturgiesSelector, liturgiesCountSelector} from "services/liturgy/liturgy.selector";
import Table from 'components/Table/Table';
import getLiturgiesColumns from './Liturgies.Columns';
import {history, urlLocations} from "routes/urlLocations";
import {deleteLiturgyAction} from "services/liturgy/liturgy.action";
import Styled from './styled/StyledLiturgies.Table';
import Modal from './Liturgies.Modal';
import {setModalStatusAction} from "services/modals/modals.action";
import {MODALS_ID} from "constants/constants";
import Pagination from "components/Pagination/Pagination";
import {getLiturgiesAction as getLiturgies} from "services/liturgy/liturgy.action";
import {isMobile} from "react-device-detect";
import {Card} from "antd";
import {DeleteOutlined, EditOutlined, CopyOutlined} from '@ant-design/icons';
import moment from "moment/moment";

const LiturgiesTable = () => {
  /** @type {IEvent[]} **/
  const liturgies = useSelector(liturgiesSelector) || [];
  const liturgiesCount = useSelector(liturgiesCountSelector);
  const dispatch = useDispatch();
  const [settedId, setDeletedId] = useState(null);

  const handelEdit = (id, copy) => {
    history.push(copy ? `${urlLocations.liturgy}/${id}/copy` : `${urlLocations.liturgy}/${id}`)
  }

  const handelDelete = (id) => {
    dispatch(setModalStatusAction(MODALS_ID.LITURGY_DELETE_MODAL))
    setDeletedId(id)
  }

  const renderTable = () => {
    return (
      <>
        <Styled.Table>
          <Table
            dataSource={liturgies}
            columns={getLiturgiesColumns({
              onDeleteClicked: id => handelDelete(id),
            })}
            onRow={({id}) => ({
              onClick: () => handelEdit(id, false)
            })}
          />
        </Styled.Table>
        <Pagination request={(params) => dispatch(getLiturgies(params))} name="liturgies" total={liturgiesCount}/>
      </>
    )
  }

  const renderCards = () => {

    return (
      <div className={"flex flex-col gap-6 mt-8"}>
        {liturgies.map(liturgy => (
          <Card
            className={'rounded-xl overflow-clip drop-shadow-md'}
            onClick={() => handelEdit(liturgy.id, false)}
            key={liturgy.id}
            hoverable
            actions={[
              <EditOutlined key="edit" onClick={(event) => {
                event.stopPropagation()
                handelEdit(liturgy.id, false)
              }}/>,
              <DeleteOutlined key='delete' onClick={(event) => {
                event.stopPropagation();
                handelDelete(liturgy.id)
              }}/>,
              <CopyOutlined key="copy" onClick={(event) => {
                event.stopPropagation()
                handelEdit(liturgy.id, true)
              }}/>
            ]}>
            <div className={'flex flex-col gap-1'}>
              <div className={"font-['Proxima_Nova_Bold'] text-lg mb-2"}>{liturgy.title}</div>
              <div className={'flex flex-row justify-between'}>
                <div>{moment(liturgy.date).format('ddd DD MMM - hh:mm A')}</div>
                <div className={'font-bold'}>{liturgy.bookedCount}/{liturgy.maxCapacity}</div>
              </div>

            </div>
          </Card>
        ))}
      </div>
    )
  }

  return (
    <>
      {isMobile ? renderCards() : renderTable()}
      <Modal
        onConfirm={() => {
          dispatch(deleteLiturgyAction(settedId));
          setDeletedId(null);
        }}
      />
    </>
  );
};

export default LiturgiesTable;
