import styled from 'styled-components';
import {Link} from "react-router-dom";
import cardBG from 'assets/img/cardBG.png'


const Page = styled.div`
  padding: 80px 0;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  color: var(--dark);
  padding: 0 40px 0 0;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 16px;
  margin-top: 30px;
`;

const Card = styled(Link)`
  width: 192px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  box-shadow: 0px 0px 10px rgba(139, 155, 163, 0.1);
  border-radius: 10px;
  background-image: url(${cardBG});
  background-repeat: no-repeat;
  background-position: top;
  transition: box-shadow .3s;

  &:hover {
    box-shadow: 0px 0px 15px rgba(139, 155, 163, 0.3);
  }
`;

const CardImage = styled.div`
  width: 80px;
  border-radius: 10px;
  height: 80px;
  margin: 0 auto 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardName = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  color: #27282B;
`;
const CardChurch = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  flex: 1;
  color: rgb(166, 166, 166);
`;


const CardStatusWrapper = styled.div`
  text-align: center;
  transform: translateY(10px);
`;

export default {
  Title,
  List,
  Card,
  CardImage,
  CardName,
  CardChurch,
  CardStatusWrapper,
}
