import React from 'react';
import {formValueSelector} from "redux-form";
import {useSelector} from "react-redux";
import {Select} from "antd";
import {FormattedMessage} from "react-intl";

import Styled from "containers/MarriageNOCDetails/styled";
import RadioField from "components/RadioField/RadioField";
import {FORMS_NAMES, IS_DIVORCED_RADIO} from "constants/constants";
import InputField from "components/InputField/InputField";
import DatePickerField from "components/DatePickerField/DatePickerField";
import SelectField from "components/SelectField/SelectField";
import MarriageNocDetailsSection from "containers/MarriageNOCDetails/MarriageNOCDetails.Section";

const {Option} = Select;

const MarriageNocDetailsDivorcement = ({disabled} = {disabled: false}) => {
  const formSelector = formValueSelector(FORMS_NAMES.MARRIAGE_NOC);
  const isDevorsed = useSelector(state => formSelector(state, 'isDevorsed'));
  const childrenCount = useSelector(state => formSelector(state, 'numberOfChildren'));

  return (
    <MarriageNocDetailsSection title={"MarriageNOCDetails.Divorcement"}>

      <Styled.FieldsWrap>
        <Styled.Field>
          <RadioField
            radioSet={IS_DIVORCED_RADIO}
            name={"isDevorsed"}
            label={"MarriageNOCDetails.IsDivorced"}
            disabled={disabled}
          />
        </Styled.Field>
        {isDevorsed && <>
          <Styled.Field>
            <InputField
              name={"divorceCourt"}
              label={"MarriageNOCDetails.Divorcement.Court"}
              placeholder={"MarriageNOCDetails.Divorcement.Court"}
              disabled={disabled}
            />
          </Styled.Field>
          <Styled.Field>
            <InputField
              name={"divorceCaseNumber"}
              label={"MarriageNOCDetails.Divorcement.CaseNumber"}
              placeholder={"MarriageNOCDetails.Divorcement.CaseNumber"}
              disabled={disabled}
            />
          </Styled.Field>
          <Styled.Field>
            <DatePickerField
              name={"divorceDate"}
              label={"MarriageNOCDetails.Divorcement.Date"}
              placeholder={"MarriageNOCDetails.Divorcement.Date"}
              disabled={disabled}
            />
          </Styled.Field>
          <Styled.Field>
            <InputField
              name={"remarriageCertificateIssuer"}
              label={"MarriageNOCDetails.Divorcement.ReMarriageCertificate"}
              placeholder={"MarriageNOCDetails.Divorcement.ReMarriageCertificate"}
              disabled={disabled}
            />
          </Styled.Field>

          <Styled.Field>
            <InputField
              type={'number'}
              name={"numberOfChildren"}
              label={"MarriageNOCDetails.Divorcement.NumberOfChildren"}
              placeholder={"MarriageNOCDetails.Divorcement.NumberOfChildren"}
              disabled={disabled}
            />
          </Styled.Field>
          {childrenCount > 0 &&
            <>
              {/*<Styled.Field>*/}
              {/*  <InputField*/}
              {/*    name={"childrenNamesAges"}*/}
              {/*    label={"MarriageNOCDetails.Divorcement.NameAgeEtc"}*/}
              {/*    placeholder={"MarriageNOCDetails.Divorcement.NameAgeEtc"}*/}
              {/*  />*/}
              {/*</Styled.Field>*/}
              <Styled.Field>
                <InputField
                  name={"relevantKindToChildren"}
                  label={"MarriageNOCDetails.Divorcement.RelevantKindToChildren"}
                  placeholder={"MarriageNOCDetails.Divorcement.RelevantKindToChildren"}
                  disabled={disabled}
                />
              </Styled.Field>
            </>
          }
        </>}
      </Styled.FieldsWrap>
    </MarriageNocDetailsSection>
  );
};

export default MarriageNocDetailsDivorcement;
