import React from 'react';

import MarriageNocDetailsSection from './MarriageNOCDetails.Section';
import FileUploader from "components/FileUploader/FileUploader";
import {FILES_TYPE, FORMS_NAMES} from "constants/constants";

const MarriageNocDetailsDocuments = ({disabled} = {disabled: false}) => {
    return (
        <MarriageNocDetailsSection title={"MarriageNOCDetails.Documents"}>
            <FileUploader formName={FORMS_NAMES.MARRIAGE_NOC} name={FILES_TYPE.MARRIAGE_NOC_DOCUMENTS} isFormDisabled={disabled}/>
        </MarriageNocDetailsSection>
    );
};

export default MarriageNocDetailsDocuments;
