import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";

import {
  GET_CURRENT_CHURCH,
  GET_CURRENT_CHURCH_FAILURE,
  GET_CURRENT_CHURCH_SUCCESS
} from "./churches.actions";

import { getCurrentChurchRequest } from "./churches.api";
import { getCurrentChurchSelector } from "./churches.selector";

function* getCurrentChurchOnLocationChangeData() {
  try {
    const church = yield select(getCurrentChurchSelector);
    if (isEmpty(church)) yield put({ type: GET_CURRENT_CHURCH });
  } catch (error) {
    console.log(error);
  }
}

function* getCurrentChurch() {
  try {
    const { response } = yield call(getCurrentChurchRequest);

    if (response.status === 200) {
      yield put({
        type: GET_CURRENT_CHURCH_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: GET_CURRENT_CHURCH_FAILURE
      });
    }
  } catch (error) {
    yield put({ type: GET_CURRENT_CHURCH_FAILURE });
    console.log(error);
  }
}

export default function* churchesSaga() {
  yield takeEvery(LOCATION_CHANGE, getCurrentChurchOnLocationChangeData);
  yield takeEvery(GET_CURRENT_CHURCH, getCurrentChurch);
}
