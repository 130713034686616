import styled from "styled-components";

const MapWrapper = styled.div`
  height: 400px;
  position: relative;
`;

export default {
  MapWrapper
};
