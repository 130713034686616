import styled from "styled-components";
import { Link } from "react-router-dom";
import GoBackImg from "assets/img/Icon/Arrowback.svg";

const Block = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;
const Button = styled(Link)`
  width: 162px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  border: 1px solid #d6e1e9;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: var(--fontProximaRegular);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #27282a;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    left: -5px;
    width: 24px;
    height: 24px;
    background: url(${GoBackImg}) center center no-repeat;
    background-size: contain;
  }
  &:hover {
    color: #27282a;
    border: 1px solid #d6e1e9;
    outline: none;
  }
  &:visited,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active {
    outline: none;
    border: 1px solid #d6e1e9;
  }
`;

export default {
  Button,
  Block
};
