import styled from "styled-components";

const StyledLabel = styled.label`
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--gray);
  padding-left: 15px;
  padding-bottom: 7px;
  display: inline-block;
  width: 100%;
`;
export default StyledLabel;
