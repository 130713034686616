import React from 'react';

import Styled from './styled';
import {pathBuilder} from "routes/urlLocations";
import StatusTag from "components/StatusTag/StatusTag";
import UserIcon from '../../assets/img/emptyavatar.png';

const MarriageNOCCard = ({uuid, fullName, photo, status, church}) => {
    return (
        <Styled.Card to={pathBuilder.marriageNOCDetails(uuid)}>
            <Styled.CardImage>
                <img src={photo?.url || UserIcon} alt=""/>
            </Styled.CardImage>
            <Styled.CardName>
                {fullName || "NA"}
            </Styled.CardName>
            <Styled.CardChurch>
                {church?.name}
            </Styled.CardChurch>
            <Styled.CardStatusWrapper>
                <StatusTag status={status}/>
            </Styled.CardStatusWrapper>
        </Styled.Card>
    );
};

export default MarriageNOCCard;
