import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import Table from 'components/Table/Table';
import { membersCountSelector, membersSelector } from "services/members/members.selector";
import MembersColumns from "./Members.Columns";
import {urlLocations, history} from "routes/urlLocations";
import {COLUMN_ORDER} from "constants/constants";
import { getMembersAction as getMembers } from "services/members/members.action";
import Pagination from 'components/Pagination/Pagination';
import AppTable from 'containers/styled/StyledTable';


const MembersTable = ({ filters }) => {
    const dispatch = useDispatch();
    const members = useSelector(membersSelector);
    const membersCount = useSelector(membersCountSelector);
    return (
        <>
            <AppTable>
                <Table
                    dataSource={members}
                    columns={MembersColumns()}
                    onChange={(_ , e , { field, order }) => {
                        COLUMN_ORDER[order] && dispatch(getMembers({ _sort: `${field}:${COLUMN_ORDER[order]}`, ...filters }))
                    }}
                    onRow={({ id, familyID }) => ({
                        onClick: () => { history.push(`${urlLocations.member}/${id}/${familyID.id}`) }
                    }) }
                />
            </AppTable>
            <Pagination request={(params) => dispatch(getMembers(params))} name="members" total={membersCount} />
        </>
    );
};

export default MembersTable;
