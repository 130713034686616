import React from "react";
import styled, {css} from "styled-components";
import {Button} from "antd";

const StyledButton = styled(({...props}) => <Button {...props} />)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 160px;
    height: 40px;
    background: var(--loginGradient);
    border-radius: 4px;
    font-family: var(--fontProximaSemi), sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;

    box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.2);
    transition: all 0.3s ease-in;

    &:hover {
      box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.25);
    }

    &:disabled {
      color: var(--dark);
      border: 1px solid #d6e1e9;
      background: #9A9FB0;
      pointer-events: none;
    }

    &.ant-btn-sm {
      width: 126px;
      height: 48px;
    }

    ${({uitype}) => {
      if (uitype && uitype === "login") {
        return css`
          width: 100%;
          height: 40px;
          background: var(--loginGradient);
          color: #fff;
          border: 1px solid transparent;
          display: flex;
          justify-content: center;
          padding-left: 20px;
          padding-right: 20px;
          align-items: center;
          box-shadow: 0 10px 20px rgba(66, 101, 218, 0.2);
          border-radius: 8px;

          &:hover {
            box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.25);
          }

          &:disabled {
            color: var(--dark);
            border: 1px solid #d6e1e9;
          }
        `;
      } else if (uitype && uitype === "addFilters") {
        return css`
          background: #fff;
          height: 40px;
          border: 1px solid #d6e1e9;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;
          border-radius: 10px;
          position: relative;
          margin-right: 8px;
          padding: 0 12px;
          margin-top: 0;
          margin-bottom: 14px;
          color: #3c62ec;

          &:focus,
          &:active {
            span {
              position: static;
            }
          }

          span {
            //&:after {
            //  content: "";
            //  width: 13px;
            //  height: 1px;
            //  background: #3c62ec;
            //  position: absolute;
            //  top: 15px;
            //  left: 9px;
            //}
            //
            //&:before {
            //  content: "";
            //  width: 1px;
            //  height: 13px;
            //  background: #3c62ec;
            //  position: absolute;
            //  top: 9px;
            //  left: 15px;
            //}
          }
        `;
      } else if (uitype && uitype === "deleteFilters") {
        return css`
          background: #fff;
          width: 32px;
          height: 32px;
          padding: 0;
          margin-right: 8px;
          margin-top: 0;
          border: 1px solid #d6e1e9;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;
          border-radius: 10px;
          position: relative;

          &:focus,
          &:active {
            span {
              position: static;
            }
          }

          span {
            &:after {
              content: "";
              width: 13px;
              height: 1px;
              background: #3c62ec;
              position: absolute;
              top: 15px;
              left: 9px;
            }
          }
        `;
      } else if (uitype && uitype === "secondary") {
        return css`
          background: #fff;
          height: 40px;
          padding: 0;
          margin-right: 8px;
          margin-top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;
          border-radius: 4px;
          position: relative;
          color: #3c62ec;
          border: 1px solid #3c62ec;

          &:focus,
          &:active {
            span {
              position: static;
            }
          }

          span {
            &:after {
              content: "";
              width: 13px;
              height: 1px;
              background: #3c62ec;
              position: absolute;
              top: 15px;
              left: 9px;
            }
          }
        `;
      }
    }};
  }
`;
export default StyledButton;
