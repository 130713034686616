import React from 'react';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Avatar} from 'antd';
import {UserOutlined} from '@ant-design/icons';

import Styled from './styled/NOCs.Columns';
import StatusTag from "components/StatusTag/StatusTag";
import {isMobile} from "react-device-detect";

const idColumn = {
  dataIndex: 'id',
  title: <FormattedMessage id="Members.Table.ID"/>,
  key: 'id',
  sorter: true,
};

const photoColumn = {
  dataIndex: 'picture',
  title: <FormattedMessage id="Members.Table.Picture"/>,
  key: 'picture',
  render: (_, {photo}) => photo ?
    <Styled.ImgCol>
      <Styled.ImgBlock>
        <Styled.StyledImg><img src={photo?.url} width={50} alt=""/></Styled.StyledImg>
        <Styled.ZoomImg>
          <img src={photo?.url} width={50} alt=""/>
        </Styled.ZoomImg>
      </Styled.ImgBlock>
    </Styled.ImgCol>
    :
    <Styled.ImgCol>
      <Avatar size={40} shape="square" icon={<UserOutlined/>}/>
    </Styled.ImgCol>
};

const nameColumn = {
  dataIndex: 'fullName',
  title: <FormattedMessage id="MarriageNOCDetails.FullName"/>,
  key: 'fullName',
  sorter: true,
};

const dateColumn = {
  dataIndex: 'handedover_at',
  key: 'handedover_at',
  title: <FormattedMessage id="MarriageNOCDetails.Date"/>,
  render: (_, {handedover_at}) => (
    <span>{new Date(handedover_at).toDateString()}</span>
  )
};

const statusColumn = {
  dataIndex: 'status',
  key: 'family',
  title: <FormattedMessage id="MarriageNOCDetails.Status"/>,
  render: (_, {status}) => (
    <StatusTag status={status}/>
  )
};

const NOCsColumns = () =>
  isMobile ? [photoColumn, nameColumn, statusColumn] :
    [idColumn, photoColumn, nameColumn, dateColumn, statusColumn];

export default NOCsColumns;
