import {put, takeEvery, select} from "redux-saga/effects";

import {fileUploadRequest} from "./file.api";
import {
    FILE_REMOVE,
    FILE_REMOVE_FAILURE,
    FILE_REMOVE_SUCCESS,
    FILE_UPLOAD,
    FILE_UPLOAD_FAILURE,
    FILE_UPLOAD_SUCCESS,
    FILES_UPLOAD
} from "./file.action";
import {getFilteredFiles} from "./file.utils";
import {SET_FILE_UPLOADER_STATUS} from "../filesUploader/filesUploader.action";

function* fileUpload({payload}) {
    try {
        yield put({
            type: SET_FILE_UPLOADER_STATUS,
            payload: {
                name: payload.name,
                formName: payload.formName,
                isUploading: true
            }
        });

        const {response} = yield fileUploadRequest(payload.file);
        yield put({
            type: FILE_UPLOAD_SUCCESS,
            payload: {
                name: payload.name,
                formName: payload.formName,
                files: response.data
            }
        });
        yield put({
            type: SET_FILE_UPLOADER_STATUS,
            payload: {
                name: payload.name,
                formName: payload.formName,
                isUploading: false
            }
        });
    } catch (error) {
        yield put({type: FILE_UPLOAD_FAILURE});
        console.log(error);
    }
}

function* removeFile({payload: {id, name, formName}}) {
    const files = yield select(state => state.files);
    const newFiles = getFilteredFiles(id, name, formName, files);
    try {
        yield put({type: FILE_REMOVE_SUCCESS});
        yield put({
            type: FILES_UPLOAD,
            payload: {formName, files: newFiles[formName]}
        });
    } catch (error) {
        yield put({type: FILE_REMOVE_FAILURE});

        console.log(error);
    }
}

export default function* fileUploadSaga() {
    yield takeEvery(FILE_UPLOAD, fileUpload);
    yield takeEvery(FILE_REMOVE, removeFile);
}
