import {
    FILES_UPLOAD,
    FILE_UPLOAD_SUCCESS,
    CLEAR_FILES,
    ADD_FILES,
    FILE_REMOVE, REPLACE_FILES
} from "./file.action";
import initialState from "../../store/initialState";
import {FILES_TYPE} from "../../constants/constants";
import unionBy from 'lodash/unionBy';

export default (state = initialState.files, {type, payload}) => {

    switch (type) {
        case ADD_FILES:
        case FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                [payload.formName]: {
                    ...state[payload.formName],
                    [payload.name]:
                        payload.name === FILES_TYPE.MARRIAGE_NOC_PHOTO
                            ? [...payload.files]
                            : unionBy(state?.[payload.formName]?.[payload.name], payload.files, 'id')
                }
            };
        case REPLACE_FILES:
            return {
                ...state,
                [payload.formName]: {
                    ...state[payload.formName],
                    [payload.name]: payload.files || [],
                }
            }
        case FILE_REMOVE:
            return {
                ...state,
                [payload.formName]: {
                    ...state[payload.formName],
                    [payload.name]:
                        [
                            ...(state?.[payload.formName]?.[payload.name] ?
                                state?.[payload.formName]?.[payload.name].filter(file => file.id.toString() !== payload.fileId.toString())
                                : [])
                        ]
                }
            };

        case FILES_UPLOAD:
            return {
                ...state,
                [payload.formName]: payload.files
            };
        case CLEAR_FILES:
            return {
                ...state,
                [payload.formName]: initialState.files[payload.formName] || []
            };
        default:
            return state;
    }
};
