import React, { useState } from 'react';
import {FormattedMessage} from "react-intl";
import {Select} from "antd";
import {change, untouch } from 'redux-form';
import { useDispatch } from "react-redux";

import SelectField from "components/SelectField/SelectField";
import Button from "components/Button/Button";
import { FILTERS, FILTERS_TYPES } from "constants/filters";
import MembersFiltersValueField from "./Members.Filters.ValueField";
import Styled from './styled/StyledMember.Filters';

const { Option } = Select;

const MembersFiltersField = ({ field, remove, index, filters = {} }) => {
    const [ filterName, setFilterName ] = useState(null);
    const type = FILTERS?.[filterName]?.type;

    const dispatch = useDispatch();

    const handleSelect = (value) => {
        setFilterName(value);
        dispatch(change("membersFilters", `${field}.operator`, null));
        dispatch(change("membersFilters", `${field}.value`, null));
        dispatch(untouch("membersFilters", `${field}.operator`, null));
        dispatch(untouch("membersFilters", `${field}.value`, null));
    };

    return (
        <Styled.MembersFiltersItem>
            <Button uitype='deleteFilters' onClick={() => remove(index)} >-</Button>
            <Styled.FilterCol>
                <SelectField
                    onSelect={handleSelect}
                    name={`${field}.name`}
                    style={{ width: '100%' }}
                    defaultValue={filterName}
                    size="small"
                >{
                    Object.entries(FILTERS).map(filter => (
                        <Option disabled={Object.keys(filters)?.includes(filter[0])} key={filter[0]} value={filter[0]}>
                            <FormattedMessage id={filter[1].name} />
                        </Option>
                    ))
                }</SelectField>
            </Styled.FilterCol>

            <Styled.FilterCol>

                <SelectField size="small" name={`${field}.operator`} style={{ width: '100%' }} >{
                    type && FILTERS_TYPES[type].map(filter => (
                        <Option value={filter.value} key={filter.value} >
                            <FormattedMessage id={filter.name}/>
                        </Option>
                    ))
                }</SelectField>
            </Styled.FilterCol>

            <MembersFiltersValueField filterName={filterName} name={`${field}.value`} />

        </Styled.MembersFiltersItem>
    );
};

export default MembersFiltersField;
