import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {urlLocations} from "routes/urlLocations";
import {Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Styled from './styled/StyledFamily.Member';

const FamilyMember = ({ member: { firstName, role, profilePic, familyID, id } = {}  }) => {
    return (
        <Styled.Item>
            <Styled.ItemContent>
                <Link to={`${urlLocations.member}/${id}/${familyID}`}>
                    <Styled.UserPic>
                        { (profilePic?.url) ?
                            <img src={profilePic?.url} alt=""/> :
                            <Avatar shape="square" size={80} icon={<UserOutlined />} />
                        }
                    </Styled.UserPic>

                    <Styled.MemberName>
                        {firstName}
                    </Styled.MemberName>
                    <Styled.MemberRole>
                        { role && <FormattedMessage id={role}  /> }
                    </Styled.MemberRole>
                </Link>
            </Styled.ItemContent>
        </Styled.Item>
    );
};

export default FamilyMember;