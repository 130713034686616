import { SET_ERRORS, CLEAR_ERRORS } from "./errors.action";
import initialState from "../../store/initialState";

export default (state = initialState.errors, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...action.payload
      };
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
};
