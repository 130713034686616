import styled from "styled-components";
import { Link } from "react-router-dom";
import Add from "assets/img/Icon/Plus.svg";
import {DEVICE} from "constants/media";

const LiturgiesPage = styled.div`
  padding: 80px 0;
    @media ${DEVICE.mobileDevices} {
        padding: 30px 0;
    }
`;

const TopMenu = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
`
const TopMenuLeft = styled.div`
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
`;

const TitlePage = styled.h1`
    font-family: var(--fontProximaBold);
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: var(--dark);
    padding: 0 40px 0 0;
`;

const AddNewPrayer = styled(Link)`
    width: 178px;
    height: 40px;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.1);
    background: var(--white);
    font-family: var(--fontProximaRegular);
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    transition: all 0.3s ease-in;
    border: 1px solid var(--hrefColorSecondary);
    color: var(--hrefColorSecondary);
    cursor: pointer;
    padding-left: 16px;
    margin-left: auto;
    @media ${DEVICE.mobileDevices} {
        margin-left: 0px;
    }
`

const AddIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: url(${Add}) center center no-repeat;
  background-size: contain;
`;

export default {
  LiturgiesPage,
  TitlePage,
  TopMenu,
  AddNewPrayer,
  TopMenuLeft,
  AddIcon
};
