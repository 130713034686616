import React from 'react';
import {FormattedMessage} from "react-intl";

import {useSelector} from "react-redux";

import {urlLocations} from "routes/urlLocations";
import {isUrlMatch} from "services/router/router.utils";
import {membersCountSelector} from "services/members/members.selector";
import Styled from './styled/StyledMembers.Header';
import MembersFiltersSearch from "containers/Members/Members.Filters/Members.Filters.Search";

const MembersHeader = ({ location, filters, setFilters }) => {
    const count = useSelector(membersCountSelector);
    return (
        <Styled.Header>
            <Styled.HeaderTitleCol>
                <Styled.Title>
                    <FormattedMessage id="Members.Title" />
                </Styled.Title>

                <Styled.MembersCount>
                    <FormattedMessage id="Members.MembersCount" values={{ count }} />
                </Styled.MembersCount>
            </Styled.HeaderTitleCol>

            <Styled.HeaderSearchCol>
                <MembersFiltersSearch
                    setFilters={setFilters}
                    filters={filters}
                />
            </Styled.HeaderSearchCol>


            {/*<Styled.ViewType>*/}
            {/*    <Styled.View*/}
            {/*        to={urlLocations.membersTable}*/}
            {/*        isActive={isUrlMatch({ location }, urlLocations.membersTable)}*/}
            {/*    >*/}
            {/*            <i>*/}
            {/*                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g><path d="m5 8h14"/><path d="m5 12h14"/><path d="m5 16h14"/></g></svg>*/}
            {/*            </i>*/}
            {/*            <FormattedMessage id="Members.TableView" />*/}
            {/*    </Styled.View>*/}

            {/*    <Styled.View*/}
            {/*        to={urlLocations.membersMap}*/}
            {/*        isActive={isUrlMatch({ location }, urlLocations.membersMap)}*/}
            {/*    >*/}
            {/*            <i>*/}
            {/*                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 5c-3.86105 0-7 3.13895-7 7 0 3.8611 3.13895 7 7 7 3.8611 0 7-3.1389 7-7 0-3.86105-3.1389-7-7-7zm1.5032 3.64c-.4864.08842-.9874.13263-1.5032.13263s-1.0168-.04421-1.5032-.13263c.3832-1.73895 1.0169-2.77053 1.5032-2.77053s1.12 1.03158 1.5032 2.77053zm-.0148-2.57895c1.0611.26527 2.019.81053 2.7853 1.56211-.56.35368-1.2084.63368-1.9158.82526-.2063-.94316-.5011-1.76842-.8695-2.38737zm-3.3158 5.49685c.0148-.7516.0737-1.4442.1769-2.06316.5452.08842 1.0905.14737 1.6652.14737.56 0 1.12-.05895 1.6653-.14737.0884.61896.1474 1.31156.1768 2.06316zm3.6548.8842c-.0148.7516-.0737 1.4442-.1621 2.0632-.5453-.0885-1.0906-.1474-1.6653-.1474-.56 0-1.12.0589-1.6653.1474-.0884-.619-.1473-1.3116-.1621-2.0632zm-4.18529-3.99368c-.70737-.19158-1.35579-.47158-1.91579-.82526.76631-.75158 1.72421-1.28211 2.78528-1.56211-.3684.61895-.66318 1.44421-.86949 2.38737zm-.14737.86947c-.1179.72211-.17685 1.48841-.19158 2.25471h-3.38948c.08843-1.2231.54527-2.35786 1.25264-3.28628.64842.4421 1.45894.79579 2.32842 1.03157zm-.20632 3.12421c.01474.7663.08842 1.5179.19158 2.2547-.86947.2358-1.66526.5748-2.34316 1.0316-.70737-.9284-1.14947-2.0631-1.23789-3.2863zm.35369 3.1095c.20631.9431.50109 1.7831.86949 2.3873-1.06107-.2652-2.01897-.8105-2.78528-1.5621.56-.3536 1.20842-.6336 1.91579-.8252zm.85469-.1916c.4864-.0884.9874-.1326 1.5032-.1326s1.0168.0442 1.5032.1326c-.3832 1.7389-1.0169 2.7705-1.5032 2.7705s-1.12-1.0316-1.5032-2.7705zm3.8611.1916c.7074.1916 1.3558.4716 1.9158.84-.7663.7516-1.7242 1.2821-2.7853 1.5621.3684-.6337.6632-1.459.8695-2.4021zm.1474-.8695c.1179-.7221.1768-1.4884.1915-2.2547h3.3895c-.0884 1.2231-.5452 2.3579-1.2526 3.2863-.6632-.4421-1.459-.7958-2.3284-1.0316zm.2063-3.1242c-.0148-.7663-.0884-1.5179-.1916-2.25474.8695-.23579 1.6653-.57474 2.3432-1.03158.7073.92842 1.1642 2.04842 1.2526 3.28632z"/></svg>*/}
            {/*            </i>*/}
            {/*            <FormattedMessage id="Members.MapView" />*/}
            {/*    </Styled.View>*/}

            {/*</Styled.ViewType>*/}




        </Styled.Header>
    );
};

export default MembersHeader;