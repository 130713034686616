import styled from "styled-components";
import {Link} from "react-router-dom";
import Add from "assets/img/Icon/Plus.svg";
import {DEVICE} from "constants/media";

const LiturgyForm = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 36px;
    @media ${DEVICE.mobileDevices} {
        width: 100%;
    }
`;

const TopMenu = styled.div`
    display: flex;
    flex-direction: row;
    width: 25%;
    margin-top: 20px;
    @media ${DEVICE.mobileDevices} {
        width: 100%;
    }
`;

const FieldsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: space-between;
    flex-direction: row;
    gap: 32px 0;
    @media ${DEVICE.mobileDevices} {
        width: 100%;
    }
`;

const Field50 = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;

    @media ${DEVICE.mobileDevices} {
        width: 100%;
    }
`;

const Field25 = styled.div`
    display: flex;
    flex-direction: column;
    width: 23%;

    @media ${DEVICE.mobileDevices} {
        width: 48%;
    }
`;


export default {
  TopMenu,
  FieldsWrap,
  Field50,
  Field25,
  LiturgyForm
};
