import styled from "styled-components";

const LoginWrap = styled.div`
  width: 100%;
  display: flex;
`;

const LoginBlock = styled.div`
  width: 328px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--lightGrey);
`;

const LoginTitle = styled.h1`
  font-family: var(--fontProximaLight);
  font-weight: 300;
  font-size: 40px;
  color: var(dark);
  padding-bottom: 45px;
  margin: 0;
  text-align: left;
`;
const LoginSubtitle = styled.h2`
  font-family: var(--fontProximaLight);
  font-size: 11px;
  line-height: 24px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  position: relative;
  top: -12px;
  background: var(--bgLight);
  height: 24px;
  left: 0;
  width: 120px;
`;
const ForgotPassword = styled.div`
  border-top: 1px solid var(--lightGrey);
  padding-top: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ForgotPasswordLink = styled.div`
  font-family: var(--fontProximaSemi);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: var(--blue);
`;

export default {
  LoginBlock,
  LoginWrap,
  LoginSubtitle,
  ForgotPassword,
  ForgotPasswordLink,
  LoginTitle
};
