import { createAction } from "redux-actions";

export const GET_LITURGIES = "GET_LITURGIES";
export const GET_LITURGIES_SUCCESS = "GET_LITURGIES_SUCCESS";
export const GET_LITURGIES_FAILURE = "GET_LITURGIES_FAILURE";

export const GET_LITURGIES_COUNT = "GET_LITURGIES_COUNT";
export const GET_LITURGIES_COUNT_SUCCESS = "GET_LITURGIES_COUNT_SUCCESS";
export const GET_LITURGIES_COUNT_FAILURE = "GET_LITURGIES_COUNT_FAILURE";

export const GET_LITURGY = "GET_LITURGY";
export const GET_LITURGY_SUCCESS = "GET_LITURGY_SUCCESS";
export const GET_LITURGY_FAILURE = "GET_LITURGY_FAILURE";

export const GET_LITURGY_BOOKINGS = "GET_LITURGY_BOOKINGS";
export const GET_LITURGY_BOOKINGS_SUCCESS = "GET_LITURGY_BOOKINGS_SUCCESS";
export const GET_LITURGY_BOOKINGS_FAILURE = "GET_LITURGY_BOOKINGS_FAILURE";

export const CREATE_LITURGY = "CREATE_LITURGY";
export const CREATE_LITURGY_SUCCESS = "CREATE_LITURGY_SUCCESS";
export const CREATE_LITURGY_FAILURE = "CREATE_LITURGY_FAILURE";

export const UPDATE_LITURGY = "UPDATE_LITURGY";
export const UPDATE_LITURGY_SUCCESS = "UPDATE_LITURGY_SUCCESS";
export const UPDATE_LITURGY_FAILURE = "UPDATE_LITURGY_FAILURE";

export const DELETE_LITURGY = "DELETE_LITURGY";
export const DELETE_LITURGY_SUCCESS = "DELETE_LITURGY_SUCCESS";
export const DELETE_LITURGY_FAILURE = "DELETE_LITURGY_FAILURE";

export const getLiturgiesAction = createAction(GET_LITURGIES);
export const createLiturgyAction = createAction(CREATE_LITURGY);
export const updateLiturgyAction = createAction(UPDATE_LITURGY);
export const deleteLiturgyAction = createAction(DELETE_LITURGY);
