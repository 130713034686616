import styled from "styled-components";
import { Link } from "react-router-dom";
import Add from "assets/img/Icon/Plus.svg";

const Page = styled.div`
  width: 100%;
  padding: 24px 0 70px 0;
`;

const Header = styled.div`
  width: 100%;
  padding-bottom: 30px;
`;

const Subtitle = styled.h3`
  font-family: var(--fontProximaSemi);
  font-size: 24px;
  color: #9a9fb0;
  padding: 0;
  margin-bottom: 5px;
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--dark);
  padding: 0;
  margin-bottom: 5px;
`;

const FamilyRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FamilyList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const FamilyInfo = styled.div`
  width: 656px;
  display: flex;
  flex-direction: column;
`;

const AddMember = styled(Link)`
  width: 192px;
  height: 170px;
  background: #f8f9fb;
  border: 1px dashed #d6e1e9;
  box-sizing: border-box;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //cursor: pointer;
  flex-direction: column;
  margin-top: 30px;
  cursor: not-allowed;
  pointer-events: all !important;
`;

const AddMemberTxt = styled.div`
  font-family: var(--fontProximaSemi);
  font-size: 13px;
  line-height: 16px;
  color: var(--blue);
  text-align: center;
`;

const AddMemberIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${Add}) center center no-repeat;
  background-size: contain;
  margin-bottom: 16px;
`;

const AddressCol = styled.div`
  width: 448px;
  padding-top: 45px;
`;

export default {
  Page,
  FamilyInfo,
  AddMemberIcon,
  AddMemberTxt,
  AddressCol,
  FamilyList,
  Header,
  AddMember,
  FamilyRow,
  Subtitle,
  Title
};
