import React from "react";
import styled, {  } from "styled-components";
import { Button } from "antd";

const StyledApply = styled(({ ...props }) => <Button {...props} />)`
  && {
    width: 82px;
    position: relative;
    top: -14px;
    height: 32px;
    background: var(--loginGradient);
    color: #fff;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    margin: 0;
    border-radius: 8px;
    font-family: var(--fontProximaSemi), sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.2);
    transition: all 0.3s ease-in;

    &:hover {
      box-shadow: none;
    }

    &:disabled {
    }
  }
`;
export default StyledApply;
