import React from 'react';
import { Table as AntTable } from 'antd';
import Pagination from "components/Pagination/Pagination";

const Table = ({  request, total, ...props }) => {
    return (
        <>
        <AntTable
            pagination={false}
            {...props}
        />
        {/*<Pagination*/}
        {/*    request={request}*/}
        {/*    total={total}*/}
        {/*/>*/}
        </>
    )
};

export default Table;