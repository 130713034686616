import {createAction} from "redux-actions";

export const GET_MARRIAGE_NOC = "GET_MARRIAGE_NOC";
export const GET_MARRIAGE_NOC_SUCCESS = "GET_MARRIAGE_NOC_SUCCESS";
export const GET_MARRIAGE_NOC_FAILURE = "GET_MARRIAGE_NOC_FAILURE";

export const CREATE_MARRIAGE_NOC = "CREATE_MARRIAGE_NOC";
export const CREATE_MARRIAGE_NOC_SUCCESS = "CREATE_MARRIAGE_NOC_SUCCESS";
export const CREATE_MARRIAGE_NOC_FAILURE = "CREATE_MARRIAGE_NOC_FAILURE";

export const GET_MARRIAGE_NOC_DETAILS = "GET_MARRIAGE_NOC_DETAILS";
export const GET_MARRIAGE_NOC_DETAILS_SUCCESS = "GET_MARRIAGE_NOC_DETAILS_SUCCESS";
export const GET_MARRIAGE_NOC_DETAILS_FAILURE = "GET_MARRIAGE_NOC_DETAILS_FAILURE";

export const SEARCH_MARRIAGE_NOC = "SEARCH_MARRIAGE_NOC";
export const SEARCH_MARRIAGE_NOC_SUCCESS = "SEARCH_MARRIAGE_NOC_SUCCESS";
export const SEARCH_MARRIAGE_NOC_FAILURE = "SEARCH_MARRIAGE_NOC_FAILURE";

export const UPDATE_MARRIAGE_NOC_STATUS = "UPDATE_MARRIAGE_NOC_STATUS";
export const UPDATE_MARRIAGE_NOC_STATUS_SUCCESS = "UPDATE_MARRIAGE_NOC_STATUS_SUCCESS";
export const UPDATE_MARRIAGE_NOC_STATUS_FAILURE = "UPDATE_MARRIAGE_NOC_STATUS_FAILURE";

export const UPDATE_MARRIAGE_NOC = "UPDATE_MARRIAGE_NOC";
export const UPDATE_MARRIAGE_NOC_SUCCESS = "UPDATE_MARRIAGE_NOC_SUCCESS";
export const UPDATE_MARRIAGE_NOC_FAILURE = "UPDATE_MARRIAGE_NOC_FAILURE";

export const UPDATE_MARRIAGE_NOC_FILES = "UPDATE_MARRIAGE_NOC_FILES";
export const UPDATE_MARRIAGE_NOC_FILES_SUCCESS = "UPDATE_MARRIAGE_NOC_FILES_SUCCESS";
export const UPDATE_MARRIAGE_NOC_FILES_FAILURE = "UPDATE_MARRIAGE_NOC_FILES_FAILURE";

export const RELATED_MARRIAGE_NOC = "RELATED_MARRIAGE_NOC";
export const RELATED_MARRIAGE_NOC_SUCCESS = "RELATED_MARRIAGE_NOC_SUCCESS";
export const RELATED_MARRIAGE_NOC_FAILURE = "RELATED_MARRIAGE_NOC_FAILURE";

export const creatNewNOCAction = createAction(CREATE_MARRIAGE_NOC);
export const loadAllNOCsAction = createAction(GET_MARRIAGE_NOC);
export const searchMarriageNocAction = createAction(SEARCH_MARRIAGE_NOC);
export const updateMarriageNocStatusAction = createAction(UPDATE_MARRIAGE_NOC_STATUS);
export const updateMarriageNocAction = createAction(UPDATE_MARRIAGE_NOC);
export const updateMarriageNocFilesAction = createAction(UPDATE_MARRIAGE_NOC_FILES);
export const findRelatedNOC = createAction(RELATED_MARRIAGE_NOC);
