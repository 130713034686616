import React from 'react';
import {FormattedMessage} from "react-intl";
import {Avatar, Dropdown, Menu} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import Styled from './styled/StyledUserBlock';
import DropDownIcon from 'assets/img/Icon/arrow-down.svg'
import {logoutAction} from 'services/logout/logout.action';
import {profileSelector} from "services/profile/profile.selectors";


const DropdownMenu = ({}) => {
  const dispatch = useDispatch();
  return (
    <Menu>
      <Menu.Item key="0" onClick={() => dispatch(logoutAction())}>
        <FormattedMessage id="Header.Logout"/>
      </Menu.Item>
    </Menu>
  )
};


const UserBlock = ({}) => {
  const profile = useSelector(profileSelector)

  return (
    <Styled.UserBlock>
      <Avatar src={''} icon={<UserOutlined/>} shape="circle"/>
      <Dropdown getPopupContainer={trigger => trigger.parentNode} overlayClassName="UserBlock"
                overlay={<DropdownMenu/>}>
	                <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <p>{profile.email}</p>
                        <img src={DropDownIcon} alt=""/>
	                </span>
      </Dropdown>
    </Styled.UserBlock>
  );
};

export default UserBlock;
