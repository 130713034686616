import React from 'react';

import Button from "components/Button/Button";
import MembersFiltersField from './Members.Filters.Field';
import Styled from './styled/StyledMember.Filters';
import {FormattedMessage} from "react-intl";

const MembersFiltersFields = ({ fields, index, filters }) => {
    return (
        <Styled.MembersFiltersFields>
            <Button uitype='addFilters' onClick={() => {
                fields.push({})
            }} >
                <FormattedMessage id="Members.AddFilter" />
            </Button>

            {
                fields.map((field, index) => (
                    <MembersFiltersField
                        remove={fields.remove}
                        index={index}
                        field={field}
                        filters={filters}
                        key={field}
                    />
                ))
            }

        </Styled.MembersFiltersFields>
    );
};

export default MembersFiltersFields;
