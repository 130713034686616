import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getRelatedNOCSelector} from "services/marriageNoc/marriageNoc.selector";
import {history, pathBuilder} from "routes/urlLocations";
import {setModalStatusAction} from "services/modals/modals.action";
import Styled from "containers/MarriageNOCDetails/MarriageNocDetails.Others/styled";
import StatusTag from "components/StatusTag/StatusTag";

const OtherNOCsContent = ({marriageNOC}) => {
  const related = useSelector(state => getRelatedNOCSelector(state, marriageNOC?.uuid))
  const dispatch = useDispatch();

  const onClick = (uuid) => {
    dispatch(setModalStatusAction(null))
    history.push(pathBuilder.marriageNOCDetails(uuid))
  }

  const renderNOCLine = ({uuid, fullName, church, status, photo}) => (
    <Styled.Card key={uuid} onClick={() => onClick(uuid)} to="#">
      <Styled.CardImage>
        <img src={photo?.url} alt=""/>
      </Styled.CardImage>
      <Styled.CardName>
        {fullName}
      </Styled.CardName>
      <Styled.CardChurch>
        {church?.name}
      </Styled.CardChurch>
      <Styled.CardStatusWrapper>
        <StatusTag status={status}/>
      </Styled.CardStatusWrapper>
    </Styled.Card>
  );

  return (
    <div>
      {related && related.length > 0 &&
        <>
          <Styled.Title>Other NOCs ({related.length})</Styled.Title>
          <Styled.List>
            {related.map((marriageNoc) => (
              renderNOCLine(marriageNoc)
            ))}
          </Styled.List>
        </>
      }
    </div>
  )
}

export default OtherNOCsContent
