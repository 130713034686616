import styled from "styled-components";

const MembersPage = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const MembersContent = styled.div`
  padding-top: 80px;
`;
export default {
  MembersPage,
  MembersContent
};
