import React from 'react';
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";

import InputField from "components/InputField/InputField";
import CheckboxField from "components/CheckboxField/CheckboxField";
import {loginAction} from "services/login/login.action";
import Button from "components/Button/Button";
import Styled from './styled/StyledLoginForm'
import StyledFormRow from "components/styled/StyledFormRow.js";
import {Image} from "antd";
import LoginFormLogo from "assets/img/logoLightBG.svg"

const LoginForm = ({handleSubmit}) => {
  return (
    <Styled.LoginBlock>
      <form onSubmit={handleSubmit}>
        <StyledFormRow>
          <InputField type="email" name="identifier" label="Login.Email"/>
        </StyledFormRow>
        <StyledFormRow>
          <InputField type="password" name="password" label="Login.Password"/>
        </StyledFormRow>

        <Styled.Remember>
          <CheckboxField name="rememberMe">
            <FormattedMessage id="Login.Remember"/>
          </CheckboxField>

        </Styled.Remember>

        <Button htmlType="submit" uitype='login'>
          <FormattedMessage id="Login.Button"/>
        </Button>
      </form>
    </Styled.LoginBlock>
  );
};

export default reduxForm({
  form: "loginForm",
  onSubmit: (value, dispatch) => dispatch(loginAction(value))
})(LoginForm);
