export const STATUS = {
    PENDING: "PENDING",
    DRAFT: "DRAFT",
    NEED_MORE_INFO: "NEED_MORE_INFO",
    REJECTED: "REJECTED",
    READY: "READY",
    HANDED_OVER: "HANDED_OVER",
    CANCELED: "CANCELED"
}

export const ALLOWED_STATUS = {
    PENDING: ["PENDING", "NEED_MORE_INFO", "READY", "REJECTED"],
    NEED_MORE_INFO: ["NEED_MORE_INFO","PENDING", "READY", "REJECTED"],
    REJECTED: ["REJECTED", "PENDING"],
    READY: ["READY", "PENDING", "HANDED_OVER", "REJECTED"],
    HANDED_OVER: ["HANDED_OVER", "CANCELED"],
    CANCELED: ["CANCELED"],
}

export const STATUS_LOCALIZATION = {
    [STATUS.PENDING]: "Status.Pending",
    [STATUS.DRAFT]: "Status.Draft",
    [STATUS.NEED_MORE_INFO]: "Status.NeedMoreInfo",
    [STATUS.REJECTED]: "Status.Rejected",
    [STATUS.READY]: "Status.Ready",
    [STATUS.HANDED_OVER]: "Status.HeadedOver",
    [STATUS.CANCELED]: "Status.Canceled",
}
