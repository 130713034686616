import React from 'react';
import ModalWrapper from "hoc/withModal";
import {MODALS_ID} from "constants/constants";
import OtherNOCsContent from "containers/MarriageNOCDetails/MarriageNocDetails.Others/OtherNOCsContent";

const OtherNOCsModal = ({marriageNOC}) => {
  return (
    <ModalWrapper
      modalId={MODALS_ID.OTHER_NOCS}
      component={OtherNOCsContent}
      marriageNOC={marriageNOC}
    />
  );
};

export default OtherNOCsModal;
