import React, { useState, useEffect } from 'react';
import { Field } from "redux-form";
import {  injectIntl } from "react-intl";
import ControlWrapper from "../ControlWrapper/ControlWrapper";
import {compose} from "redux";
import {connect} from "react-redux";
import StyledInputPhoneField from './styled/StyledInputPhonePicker';

const CustomPhoneInput = ({
    input: { name },
    label,
    input,
    country,
    formatMessage,
    placeholder,
    customTouched,
    setTouched,
    ...meta
}) => {
    return (<ControlWrapper
        label={label}
        name={name}
        customTouched={customTouched}
    >
        <StyledInputPhoneField
            { ...input }
            inputClass='ant-input'
            { ...meta }
            onFocus={setTouched.bind(null, true)}
            placeholder={
                placeholder && formatMessage({ id: placeholder })
            }
            country={country}
        />
    </ControlWrapper>)
};

const InputPhoneField = ({
    name,
    errors,
    intl: { formatMessage },
    placeholder,
    type,
    label,
    country,
    ...props
}) => {
    const [ customTouched, setTouched ] = useState(false);
    useEffect(() => {
        setTouched(false)
    }, [errors]);
    return (
        <Field
            component={CustomPhoneInput}
            name={name}
            placeholder={placeholder}
            type={type}
            label={label}
            country={country}
            formatMessage={formatMessage}
            customTouched={customTouched}
            setTouched={setTouched}
            {...props}
        />
    )};

export default compose(
    connect(
        ({ errors }) => ({ errors }),
        {}),
    injectIntl
)(InputPhoneField);
