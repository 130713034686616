import React, {useEffect, useState} from 'react';
import { FormOutlined } from '@ant-design/icons'
import {FormattedMessage} from "react-intl";
import { reduxForm } from "redux-form";

import InputField from "components/InputField/InputField";
import InputPhoneField from "components/InputPhoneField/InputPhoneField";
import Styled from './styled/StyledMember.Form';
import {updateMembersAction} from "services/members/members.action";
import Loader from "components/Loader/Loader";
import Cancel from "components/Button/styled/StyledCancel";
import Save from "components/Button/styled/StyledSave";


const MemberFormsContact = ({ handleSubmit, formName, member }) => {
    const [ disabled, setDisableStatus ] = useState(true);

    const toggleDisable = () => {
        setDisableStatus(!disabled)
    };


    useEffect(() => {
        setDisableStatus(true);
    }, [member]);

    return (
        <Styled.ColRight>
            <Styled.FormBlock>
                <Loader name={formName} >
                    <form onSubmit={handleSubmit}>
                        <Styled.FormHeader>
                            <Styled.HeaderTitle>
                                <FormattedMessage id="Member.Form.Contact" />
                            </Styled.HeaderTitle>
                            <Styled.EditBtn>
                                <FormOutlined onClick={toggleDisable} />
                            </Styled.EditBtn>
                        </Styled.FormHeader>

                        <Styled.FormContent>
                            <Styled.Field>
                                <Styled.Col45>
                                    <InputField
                                        type="email"
                                        disabled={disabled}
                                        name="email"
                                        label="Member.Form.Email"
                                        placeholder="Member.Form.Email"
                                    />
                                </Styled.Col45>
                                <Styled.Col45>
                                    <InputPhoneField
                                        disabled={disabled}
                                        name="mobile"
                                        label="Member.Form.Phone"
                                        placeholder="Member.Form.Phone"
                                    />
                                </Styled.Col45>
                            </Styled.Field>

                        <Styled.Field>
                            <Styled.Col45>
                                <InputPhoneField disabled={disabled} name="whatsapp" label="Member.Form.Whatsapp" placeholder="Member.Form.Whatsapp" />
                            </Styled.Col45>
                        </Styled.Field>

                            { !disabled && <Styled.ButtonsRow>
                                <Cancel onClick={setDisableStatus.bind(null, true)} >
                                    <FormattedMessage id="Member.Form.Cancel" />
                                </Cancel>
                                <Save  htmlType="submit" >
                                    <FormattedMessage id="Member.Form.Save" />
                                </Save>
                            </Styled.ButtonsRow>}
                        </Styled.FormContent>
                    </form>
                </Loader>
            </Styled.FormBlock>
        </Styled.ColRight>
    );
};

export default reduxForm({
    form: "contactsForm",
    enableReinitialize: true,
    onSubmit: (value, dispatch, { member, formName }) => {
        dispatch(
            updateMembersAction({
                ...value, formName, id: member?.id
            })
        )
    }})(MemberFormsContact);