import NOCImg from 'assets/img/marriagNOC.jpg'
import ContributionsImg from 'assets/img/contributions.jpg';
import {urlLocations} from "routes/urlLocations";

export const SERVICES = [
    {
        key: "Services.MarriageNOC",
        path: urlLocations.marriageNOC,
        img: NOCImg,
        dataKey: 'nocs'
    },
    {
        key: "Services.Contributions",
        path: urlLocations.contributions,
        img: ContributionsImg,
        dataKey: 'contributions'
    }
]
