import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import moment from 'moment';
import isEmpty from "lodash/isEmpty";

import DatePicker from "components/DatePicker/DatePicker";
import Styled from './styled/StyledLiturgies.Filter';
import {getLiturgiesAction} from 'services/liturgy/liturgy.action';

const LiturgiesFilter = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] =
    useState({});

  useEffect(() => {
    !isEmpty(filters) &&
    dispatch(getLiturgiesAction(filters))
  }, [filters]);

  return (
    <Styled.Filter>
      <DatePicker
        onChange={(value) => {
          setFilters({
            date_gte: moment(value).startOf('day').toISOString(),
            date_lte: moment(value).startOf('day').add(1, 'day').toISOString()
          })
        }}
      />
    </Styled.Filter>
  );
};

export default LiturgiesFilter;
