import React from 'react';
import { Switch, Route } from "react-router";

import Table from './Members.Table/Members.Table';
import Map from './Members.Map/Members.Map';
import {urlLocations} from "routes/urlLocations";

const MembersContent = ({ filters, setFilters }) => {
    return (
        <Switch>
            <Route component={Table} path={urlLocations.membersTable} />
            <Route filters={filters} component={Map} path={urlLocations.membersMap} />
        </Switch>
    );
};

export default MembersContent;