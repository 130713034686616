import React from 'react';
import debounce from 'lodash/debounce';
import Input from 'components/Input/Input';
import { injectIntl } from "react-intl";
import Styled from './styled/StyledMember.Filters';
import {
    SearchOutlined
} from '@ant-design/icons';

const MembersFiltersSearch = ({ setFilters, filters, intl }) => {
    const { _q, ...res } = filters;
    const debouncedHandleChange = debounce(
        (value) => setFilters({ ...res, ...(!!value && { _q: value }) }), 500
    );

    const handleSearch = (event) => {
        const { value } = event.target;
        debouncedHandleChange(value)
    };
    return (
        <Styled.Search>
            <Input uitype='search'
                   placeholder={intl.formatMessage({id: 'Filters.SearchPlaceholder'})}
                   onChange={handleSearch}
                   prefix={<SearchOutlined />} />
        </Styled.Search>
    );
};

export default injectIntl(MembersFiltersSearch);
