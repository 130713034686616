import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import Table from 'components/Table/Table'
import {getContributionsSelector} from "services/contributions/contributions.selectors";
import {getLocalizedKey} from "utils/utils";
import Styled from './styled';
import {setModalStatusAction} from "services/modals/modals.action";
import {MODALS_ID} from "constants/constants";

const COLUMNS = [
    {
        title: <FormattedMessage id={"Contributions.Church"}/>,
        dataIndex: 'church',
        key: 'church',
        render: field => <Styled.TableTitleRow>
            {field?.[getLocalizedKey('name')]}
        </Styled.TableTitleRow>,
        sorter: (a, b) => a?.church?.[getLocalizedKey('name')]?.localeCompare(b?.church?.[getLocalizedKey('name')]),
    },
    {
        title: <FormattedMessage id={"Contributions.Father"}/>,
        dataIndex: 'father',
        key: 'father',
        render: field => field?.[getLocalizedKey('name')],
        sorter: (a, b) => a?.father?.[getLocalizedKey('name')]?.localeCompare(b?.father?.[getLocalizedKey('name')]),
    },
    {
        title: <FormattedMessage id={"Contributions.Reason"}/>,
        dataIndex: 'reason',
        key: 'reason',
        sorter: (a, b) => a?.reason?.localeCompare(b?.reason),
    },
    {
        title: <FormattedMessage id={"Contributions.Amount"}/>,
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a?.amount - b?.amount,
    }
]

const ContributionsTable = ({setContributionIDCallback}) => {
    const contributions = useSelector(getContributionsSelector) || [];
    const dispatch = useDispatch();

    return (
        <Table
            onRow={(record) => ({
                onClick: () => {
                    setContributionIDCallback(record?.id)
                    dispatch(setModalStatusAction(MODALS_ID.CONTRIBUTION_MODAL))
                }
            })}
            dataSource={contributions}
            columns={COLUMNS}
        />
    );
};

export default ContributionsTable;