import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

import Styled from './styled';
import {getMarriageNOCSelector} from "services/marriageNoc/marriageNoc.selector";
import StyledContainer from "components/styled/StyledContainer";
import MarriageNocDetailsStatus from "./MarriageNocDetails.Status/MarriageNocDetails.Status";
import MarriageNocDetailsForm from "containers/MarriageNOCDetails/MarriageNOCDetails.Form";
import {addFilesAction, replaceFilesAction} from "services/file/file.action";
import {findRelatedNOC} from "services/marriageNoc/marriageNoc.actions";
import {selectFile} from "services/file/file.selector";
import {FILES_TYPE, FORMS_NAMES} from "constants/constants";
import isArray from "redux-actions/lib/utils/isArray";
import OtherNOCsModal from "containers/MarriageNOCDetails/MarriageNocDetails.Others/MarriageNocDetails.Others";
import {Modal} from "antd";
import NocWarningModal from "containers/MarriageNOCDetails/MarriageNocDetails.Warning";


const MarriageNocDetails = () => {
    const {uuid} = useParams()
    const marriageNOC = useSelector(state => getMarriageNOCSelector(state, uuid));
    const dispatch = useDispatch();

    const setFiles = (fieldName) => {
        const files = isArray(marriageNOC?.[fieldName]) ? marriageNOC?.[fieldName] : [marriageNOC?.[fieldName]]
        dispatch(replaceFilesAction({
            files: files || [],
            formName: FORMS_NAMES.MARRIAGE_NOC,
            name: fieldName
        }));
    }

    useEffect(() => {
        if (marriageNOC?.id) {
            setFiles(FILES_TYPE.MARRIAGE_NOC_PHOTO);
            setFiles(FILES_TYPE.MARRIAGE_NOC_HARD_COPY);
            setFiles(FILES_TYPE.MARRIAGE_NOC_CANCELLATION_PROOF);
            setFiles(FILES_TYPE.MARRIAGE_NOC_DOCUMENTS);
        }
    }, [marriageNOC?.id])

    useEffect(() => {
        dispatch(findRelatedNOC({uuid}))
    }, [marriageNOC?.id])


    return (
        <Styled.Page>
            <StyledContainer>
                <Styled.Title>
                    <FormattedMessage id={"MarriageNOCDetails.Title"}/>
                </Styled.Title>
                {marriageNOC &&
                    <MarriageNocDetailsStatus marriageNOC={marriageNOC}/>
                }
                <MarriageNocDetailsForm
                  marriageNOC={marriageNOC}
                  initialValues={{
                    ...marriageNOC,
                    church: marriageNOC?.church?.id,
                }}/>
            </StyledContainer>
            <OtherNOCsModal marriageNOC={marriageNOC}/>
            <NocWarningModal marriageNOC={marriageNOC}/>
        </Styled.Page>
    );
};

export default MarriageNocDetails;
