import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {connectRouter} from "connected-react-router";

import {history} from "routes/urlLocations";
import {LOGOUT} from "./logout/logout.action";
import initialState from "store/initialState";
import errorsReducer from "./errors/errors.reducer";
import filesReducer from "./file/file.reducer";
import modalsReducer from "./modals/modals.reducer";
import fileUploaderReducer from "./filesUploader/filesUploader.reducer";
import loaderReducer from "./loader/loader.reducer";
import appInfoReducer from "./appInfo/appInfo.reducer";
import membersReducer from "./members/members.reducer";
import familyReducer from "./family/family.reducer";
import areasReducer from "./area/area.reducer";
import filtersReducer from "./filters/filters.reducer";
import churchesReducer from "./churches/churches.reducer";
import liturgiesReducer from "./liturgy/liturgy.reducer";
import profileReducer from './profile/profile.reducer'
import marriageNocReducer from "./marriageNoc/marriageNoc.reducer";
import contributionsReducer from "./contributions/contributions.reducer";

const appReducer = combineReducers({
    areas: areasReducer,
    members: membersReducer,
    family: familyReducer,
    errors: errorsReducer,
    files: filesReducer,
    churches: churchesReducer,
    modals: modalsReducer,
    fileUploader: fileUploaderReducer,
    loader: loaderReducer,
    appInfo: appInfoReducer,
    filters: filtersReducer,
    liturgies: liturgiesReducer,
    profile: profileReducer,
    marriageNoc: marriageNocReducer,
    contributions: contributionsReducer,
    form: formReducer,
    router: connectRouter(history)
});

const rootReducers = (state, action) => {
    if (action.type === LOGOUT) {
        state = initialState;
    }
    return appReducer(state, action);
};

export default rootReducers;
