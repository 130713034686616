import React from 'react';
import {useDispatch} from "react-redux";

import ModalWrapper from "hoc/withModal";
import {MODALS_ID} from "constants/constants";
import ContributionsContent from "containers/Contributions/Contributions.Modal/Contributions.Content";
import {clearFilesAction} from "services/file/file.action";

const ContributionsModal = ({contributionId}) => {
    const dispatch = useDispatch();
    return (
        <ModalWrapper
            modalId={MODALS_ID.CONTRIBUTION_MODAL}
            component={ContributionsContent}
            contributionId={contributionId}
            destroyOnClose={true}
            onCancel={() => dispatch(clearFilesAction({
                formName: 'contributions'
            }))}
        />
    );
};

export default ContributionsModal;