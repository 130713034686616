import { call, put, select, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import isEmpty from "lodash/isEmpty";
import * as profileApi from './profile.api';
import * as profileActions from './profile.actions';
import * as profileSelectors from './profile.selectors';

function* getProfileDataOnLocationChangeData() {
    try {
        const profile = yield select(profileSelectors.profileSelector);
        if (isEmpty(profile)) yield put({ type: profileActions.GET_PROFILE_DATA });
    } catch (error) {
        console.log(error);
    }
}

function* fetchProfileData() {
    try {
        const { response } = yield call(profileApi.getProfileData);

        if (response.status === 200) {
            yield put({
                type: profileActions.GET_PROFILE_DATA_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({
                type: profileActions.GET_PROFILE_DATA_FAILURE
            });
        }
    } catch (error) {
        yield put({ type: profileActions.GET_PROFILE_DATA_FAILURE });
        console.log(error);
    }
}

export default function* profileSaga() {
    yield takeEvery(LOCATION_CHANGE, getProfileDataOnLocationChangeData);
    yield takeEvery(profileActions.GET_PROFILE_DATA, fetchProfileData);
}
