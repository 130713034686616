import { put, takeEvery, takeLatest, select, call } from "redux-saga/effects";
import { createMatchSelector, LOCATION_CHANGE } from "connected-react-router";
import get from "lodash/get";

import {
  GET_FAMILY,
  GET_FAMILY_FAILURE,
  GET_FAMILY_SUCCESS,
  UPDATE_FAMILY,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_FAMILY_FAILURE
} from "./family.action";
import { urlLocations } from "routes/urlLocations";
import { isUrlMatch } from "services/router/router.utils";
import { getFamilyRequest, updateFamilyRequest } from "./family.api";
import {
  FINISH_LOADER,
  SET_LOADER_STATUS,
  START_LOADER
} from "services/loader/loader.action";
import { openNotification } from "utils/notifications";

function* getFamilyOnLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.familyInfo)) {
      const getMatch = createMatchSelector(urlLocations.familyInfo);
      const state = yield select(state => state);
      const id = get(getMatch(state), "params.familyID");
      yield !!id && put({ type: GET_FAMILY, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFamilyOnMemberLocationChange({ payload }) {
  try {
    if (isUrlMatch(payload, urlLocations.memberInfo)) {
      const getMatch = createMatchSelector(urlLocations.memberInfo);
      const state = yield select(state => state);
      const id = get(getMatch(state), "params.familyID");
      yield !!id
        ? put({ type: GET_FAMILY, payload: id })
        : yield put({
            type: GET_FAMILY_SUCCESS,
            payload: []
          });
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchFamily({ payload: id }) {
  try {
    yield put({
      type: START_LOADER
    });
    const { data, status } = yield getFamilyRequest(id);
    if (status === 200) {
      yield put({
        type: GET_FAMILY_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    yield put({ type: GET_FAMILY_FAILURE });
    console.log(error);
  } finally {
    yield put({
      type: FINISH_LOADER
    });
  }
}

function* updateFamily({ payload }) {
  const { formName, ...res } = payload;
  try {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: formName,
        isLoading: true
      }
    });
    const { data, status } = yield updateFamilyRequest(res);
    if (status === 200) {
      yield put({
        type: UPDATE_FAMILY_SUCCESS,
        payload: data
      });
    } else {
      yield put({
        type: UPDATE_FAMILY_FAILURE
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_FAMILY_FAILURE
    });
  } finally {
    yield put({
      type: SET_LOADER_STATUS,
      payload: {
        name: formName,
        isLoading: false
      }
    });
  }
}

export default function* familySaga() {
  yield takeEvery(LOCATION_CHANGE, getFamilyOnLocationChange);
  yield takeEvery(LOCATION_CHANGE, getFamilyOnMemberLocationChange);
  yield takeLatest(GET_FAMILY, fetchFamily);
  yield takeLatest(UPDATE_FAMILY, updateFamily);
}
