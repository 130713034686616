import styled from "styled-components";
import { Menu } from "antd";
import Copy from '../../../assets/img/Icon/copy.svg';
import Delete from '../../../assets/img/Icon/Remove.svg';



const Title = styled.h1`
    font-family: var(--fontProximaBold);
    font-size: 28px;
    line-height: 40px;
    margin: 0;
    color: var(--dark);
    padding: 0 0 15px 0;
`;

const Txt = styled.p`
    padding: 0 0 15px 0;
`;

const Buttons = styled.div`
    display: flex;
    width: 100%;  
`;

const Modal = styled.div`
    padding: 0 20px 10px 20px; 
`;




export default {
  Title,
  Modal,
  Txt,
  Buttons
};
