import React from 'react';

import FamilyAddressForm from './Family.Address.Form'

const FamilyAddress = ({ geoLocation, address, id, emirate }) => {
    return (
       <FamilyAddressForm
           geoLocation={geoLocation}
           id={id}
           initialValues={{
               emirate: {
                   id : emirate?.id
               },
               address: {
                   addressLine: address?.addressLine,
                   area: parseInt(address?.area)
               }
           }}
       />
    );
};

export default FamilyAddress;