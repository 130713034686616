import React from 'react';
import StyledInput from "components/Input/styled/StyledInput.js";
const Input = ({input,...props}) => (
	<StyledInput
		{...input}
		{...props} />
);

export default Input;


