import styled, {css} from "styled-components";
import {Input} from "antd";
import SearchIcon from "assets/img/Icon/Search.svg";

const StyledInput = styled(Input)`
  && {
    background-color: var(--white);
    position: relative;
    padding: 12px 16px;
    color: var(--dark);
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--lightGrey);
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    font-family: var(--fontProximaSemi);
    align-items: center;

    &:focus {
      border: 1px solid var(--lightGrey);
    }

    &:hover {
      border: 1px solid var(--lightGrey);
    }

    .ant-input-focused {
      border: 1px solid var(--lightGrey);
    }

    &[disabled] {
      border: 1px solid var(--lightGrey);
      background-color: var(--disableBg);
    }

    .ant-input-prefix {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }

    &.ant-input-sm {
      height: 32px;
      padding: 8px 16px;
    }

    ${({uitype}) => {
      if (uitype && uitype === "search") {
        return css`
          width: 448px;
          height: 56px;
          font-family: var(--fontProximaRegular);
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #a4abc4;

          input.ant-input {
            font-size: 16px;
            color: #a4abc4;
            padding-left: 5px;
          }

          .ant-input-prefix {
            width: 24px;
            height: 24px;
            position: relative;
            top: 2px;
          }

          .anticon {
            width: 24px;
            height: 24px;
            background: url(${SearchIcon}) center center no-repeat;
            background-size: contain;
            position: relative;
            top: 0;
          }

          .anticon svg {
            display: none;
          }

          &:hover {
            border: 1px solid #d6e1e9;
            outline: none;
            box-shadow: none;
          }
        `;
      }

      if (uitype && uitype === "small") {
        return css``;
      }
    }};
  }
`;

export default StyledInput;
