import styled from "styled-components";
import React from "react";

const Block = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 30px 0 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  @media (min-width: 1800px) {
    padding-right: 80px;
    padding-left: 80px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
`;
const Logo = styled.div`
  display: inline-flex;
  align-items: center;
`;
const Img = styled.div`
  display: inline-flex;
  width: 30px;
  height: 30px;
  background: #fbfcfd;
  border-radius: 7px;
  margin-left: 15px;
`;

const Image = styled.div`
  display: inline-flex;
  width: 50px;
  height: 50px;
  background: #fbfcfd;
  border-radius: 50%;
  margin-right: 10px;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LogoTxt = styled.div`
  width: calc(100% - 42px);
`;

const LogoTitle = styled.p`
  font-size: 15px;
  font-family: var(--fontProximaBold);
  color: #fff;
  margin-bottom: 0;
`;

const LogoSubTitle = styled.p`
  font-family: var(--fontProximaRegular);
  font-size: 13px;
  margin-bottom: 0;
  color: #ffffff;
  opacity: 0.5;
`;

const Txt = styled.div`
  font-size: 16px;
  font-family: var(--fontProximaSemi);
  font-weight: 600;
  display: inline-flex;
  margin-left: 13px;
  color: #fff;
`;

export default {
  Block,
  Logo,
  Txt,
  LogoTxt,
  Image,
  LogoTitle,
  LogoSubTitle,
  HeaderContent,
  Img
};
