import React from 'react';
import {useSelector} from "react-redux";

import DashboardItem from './Dashboard.Item';
import {NAVIGATION_ITEMS} from 'constants/constants';
import Styled from './styled/StyledDashboard'
import StyledContainer from "components/styled/StyledContainer";
import {profileSelector} from "services/profile/profile.selectors";

const DashboardList = () => {
    const profile = useSelector(profileSelector);
    return (
        <StyledContainer>
            <Styled.List>
                {
                    NAVIGATION_ITEMS.map((item, index) => (
                        <>
                            {
                                !item.to || profile?.access?.[item?.accessKey] || item.access ?
                                    <DashboardItem
                                        key={item.dataKey}
                                        to={item.to}
                                        dataKey={item.dataKey}
                                        disabled={!item.to || !!profile?.access && !profile?.access?.[item?.accessKey]}
                                        access={item.access}
                                    /> : <></>
                            }
                        </>
                    ))
                }
            </Styled.List>
        </StyledContainer>
    );
};

export default DashboardList;