import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { getMembersAction as getMembers } from "services/members/members.action";

import MembersFiltersSearch from "./Members.Filters.Search";
import MembersFiltersList from './Members.Filters.List';
import MembersFiltersView from "./Members.Filters.View";
import Styled from './styled/StyledMember.Filters';

const MembersFilters = ({ filters, setFilters }) => {

    return (
        <div>
            <Styled.Filters>

                    <MembersFiltersList
                        setFilters={setFilters}
                        filters={filters}
                    />
                    <MembersFiltersView
                        setFilters={setFilters}
                    />

            </Styled.Filters>
        </div>
    );
};

export default MembersFilters;