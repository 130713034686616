import styled from 'styled-components';
import {STATUS} from "constants/status";

const handleColorType = status => {
  switch (status) {
    case STATUS.HANDED_OVER:
      return "#64b978";
    case STATUS.DRAFT:
      return "#9A9FB0";
    case STATUS.REJECTED:
      return "#c05056";
    case STATUS.NEED_MORE_INFO:
      return "#e9a658";
    case STATUS.CANCELED:
      return "#c05056";
    default:
      return "#4164DA";
  }
};

const StatusTag = styled.div`
  background: ${({ status }) => handleColorType(status)};
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffffff;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
    text-align: center;
`;

export default {
  StatusTag
}
