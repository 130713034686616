import React, { useState, useEffect } from 'react';
import { Field } from "redux-form";
import { injectIntl } from "react-intl";
import {connect} from "react-redux";
import {compose} from "redux";
import Input from "../../components/Input/Input";

import ControlWrapper from "../ControlWrapper/ControlWrapper";

const CustomInput = ({
    input: { name },
    input,
    formatMessage,
    setTouched,
    placeholder,
    type,
    errors,
    label,
    customTouched,
  disabled,
    ...meta
}) => (
    <>
        <ControlWrapper
            label={label}
            name={name}
            customTouched={customTouched}
            error={meta.meta.error}
            touched={meta.meta.touched}
        >
            <Input
                { ...input }
                {...meta}
                onFocus={setTouched.bind(true)}
                type={type}
                disabled={disabled}
                placeholder={placeholder && formatMessage({ id: placeholder })}
            />
        </ControlWrapper>
    </>
);

const InputField = ({
    name,
    errors,
    intl: { formatMessage },
    placeholder,
    type,
    label,
  disabled,
    ...props
}) => {
    const [ customTouched, setTouched ] = useState(false);

    useEffect(() => {
        setTouched(false);
    }, [errors]);

    return (
    <Field
        component={CustomInput}
        name={name}
        placeholder={placeholder}
        type={type}
        label={label}
        formatMessage={formatMessage}
        customTouched={customTouched}
        setTouched={setTouched}
        errors={errors}
        disabled={disabled}
        {...props}
    />
)};

export default compose(
    connect(
        ({ errors }) => ({ errors }),
        {}),
    injectIntl
)(InputField);
