import initialState from "../../store/initialState";
import { SET_FILE_UPLOADER_STATUS } from "./filesUploader.action";

export default (state = initialState.fileUploader, action) => {
  switch (action.type) {
    case SET_FILE_UPLOADER_STATUS:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.name]: {
            isUploading: action.payload.isUploading
          }
        }
      };
    default:
      return state;
  }
};
