import React from "react";
import styled, { css } from "styled-components";
import { Button } from "antd";

const StyledButtonSubmit = styled(({ ...props }) => <Button {...props} />)`
  && {
    display: inline-flex;
    justify-content: center;
    align-items: center;
     height:${({height}) => height ? height : "40px"};
     width: ${({width}) => width ? width : "130px"};
     margin-right: ${({marginRight}) => marginRight ? marginRight : "10px"};
    line-height: 3.2;
    background: var(--loginGradient);
    border-radius: 8px;
    font-family: var(--fontProximaSemi), sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
    border: none;
    box-shadow: 0 10px 20px 0 rgba(66, 101, 218, 0.2);
    transition: all 0.3s ease-in;

    &:hover {
      box-shadow: 0 10px 30px 0 rgba(66, 101, 218, 0.25);
    }

    &:disabled {
      color: var(--dark);
      border: 1px solid #d6e1e9;
    }
  }
`;
export default StyledButtonSubmit;
