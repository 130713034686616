import styled from "styled-components";
import LocationImg from "assets/img/Icon/location.svg";

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const Col33 = styled.div`
  width: 33%;
  max-width: 160px;
  display: inline-block;
`;
const Col60 = styled.div`
  width: 60%;
  max-width: 322px;
  display: inline-block;
`;

const Col40 = styled.div`
  width: 60%;
  max-width: 166px;
  display: inline-block;
`;
const Col45 = styled.div`
  width: 45%;
  max-width: 208px;
  display: inline-block;
`;

const Col100 = styled.div`
  width: 100%;
`;

const ColLeft = styled.div`
  width: 49%;
  display: inline-block;
`;

const ButtonsRow = styled.div`
  border-top: 1px solid #e9edf0;
  padding: 14px 0 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ColRight = styled.div`
  width: 49%;
  display: inline-block;
`;

const Field = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

const LocationBtn = styled.a`
  width: 156px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #4265da;
  box-shadow: 0px 10px 20px rgba(66, 101, 218, 0.1);
  border-radius: 8px;
  color: #4265da;
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    left: 0;
    width: 24px;
    height: 24px;
    margin-right: 11px;
    background: url(${LocationImg}) center center no-repeat;
    background-size: contain;
  }
`;

export default {
  Row,
  Field,
  ColRight,
  ColLeft,
  ButtonsRow,
  Col33,
  Col100,
  Col60,
  Col40,
  Col45,
  LocationBtn
};
