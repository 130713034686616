import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {IntlProvider} from "react-intl";

import store from "./store";
import {history} from "routes/urlLocations";
import Routes from "routes/routes";
import arDictionary from "./locales/ar";
import enDictionary from "./locales/en";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "hooks/ScrollToTop";
import {setupAuthorizationInterceptor} from "services/root.api";
import {logoutAction} from "services/logout/logout.action";

const APPDictionary = {
  ar: arDictionary,
  en: enDictionary
};

let lang = localStorage.getItem("lang");
if (!lang) {
  localStorage.setItem("lang", "en");
  lang = localStorage.getItem("lang");
}

const {dispatch} = store;
setupAuthorizationInterceptor(() => {
  dispatch(logoutAction())
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale={lang} messages={APPDictionary[lang]}>
        <ScrollToTop/>
        <Routes/>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
