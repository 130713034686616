import React from 'react';
import {Upload} from 'antd';
import {FormattedMessage} from "react-intl";
import {useDispatch} from "react-redux";

import UploadIcon from '../../assets/img/Upload.svg';

import StyledPhotoSectionText from './styled/StyledPhotoSectionText';
import StyledFileUploadSection from "components/PhotoSection/styled/StyledFileUploadSection";
import StyledFileUploadButton from "components/PhotoSection/styled/StyledFileUploadButton";
import {fileUploadAction} from "services/file/file.action";
import StyledUpload from "components/PhotoSection/styled/StyledUpload";


const PhotoUploader = ({children, name, formName, description}) => {
  const dispatch = useDispatch()

  return (
      <StyledUpload
        id={"upload"}
        // onChange={setTouched.bind(null, false)}
        showUploadList={false}
        action={null}
        customRequest={({file}) => {
          dispatch(fileUploadAction({file, name, formName}));
        }}
      >
          <StyledFileUploadButton icon={<img src={UploadIcon} alt={"upload icon"}/>}>
            {children}
          </StyledFileUploadButton>

          <StyledPhotoSectionText>
            <FormattedMessage id={description || "FamilyPage.Form.DocumentsInfo"}/>
          </StyledPhotoSectionText>
      </StyledUpload>
  );
}

export default PhotoUploader;
