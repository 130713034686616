import styled from "styled-components";
import LoginLogo from "assets/img/login_logo.png"
import {DEVICE} from "constants/media";

const Layout = styled.div`
  position: relative;
  background: var(--bgLight);
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
`;

const Img = styled.div`
  display: inline-flex;
  width: 337px;
  height: 200px;
  background: url(${LoginLogo}) center center no-repeat;
  background-size: contain;
  padding-bottom: 10px;
`;

const TopLogo = styled.div`
  position: absolute;
  top: 34px;
  left: 47px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:before {
    content: "";
    width: 29.89px;
    height: 29.89px;
    left: 47px;
    top: 32px;
    display: inline-block;
    background: #b4c5d8;
    border-radius: 7px;
    margin-right: 13px;
  }
  font-family: var(--fontProximaSemi);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  a {
    font-family: var(--fontProximaSemi);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
`;
const LeftBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradientBlue);
  flex-direction: column;
    @media ${DEVICE.mobileDevices} {
        display: none;
    }
`;

const RightBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

    @media ${DEVICE.mobileDevices} {
        width: 100%;
    }
`;

const Title = styled.h1`
  font-family: var(--fontProximaBold);
  font-size: 20px;
  color: #B4C5D8;
  margin-top: 25px;
  margin-bottom: 50px;
  text-align: center;
`;
const Subtitle = styled.h2`
  font-family: var(--fontProximaRegular);
  font-size: 16px;
  line-height: 24px;
  color: #3161ac;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 42px;
`;
const IntoBlock = styled.div`
  position: relative;
  width: 450px;
  margin: 0 auto;
  padding-top: 47px;

  &:before {
    content: " ";
    position: absolute;
    width: 328px;
    top: 0;
    left: 50%;
    height: 1px;
    margin-left: -165px;
    background: #436fb5;
  }
`;

const IntoTxt = styled.div`
  color: #89a5d0;
  font-family: var(--fontProximaSemi);
  max-width: 450px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`;

export default {
  Layout,
  Title,
  Subtitle,
  RightBlock,
  LeftBlock,
  IntoBlock,
  Img,
  IntoTxt,
  TopLogo
};
